import { UseCompanyBillingSummaryReturn } from './useCompanyBillingSummary.decl';

import { useGraphQuery } from '@dashboard/library';
import { GetCompanyBillingSummaryQuery } from '@generated/GetCompanyBillingSummaryQuery';
import { GET_COMPANY_BILLING_SUMMARY_QUERY } from '@graphql/queries/GetCompanyBillingSummaryQuery';

export function useCompanyBillingSummary(): UseCompanyBillingSummaryReturn {
  const { data, loading, refetch } = useGraphQuery<GetCompanyBillingSummaryQuery>(
    GET_COMPANY_BILLING_SUMMARY_QUERY,
    { throwError: false }
  );

  return {
    loading,
    refetch,
    data,
  };
}
