import { gql } from '@apollo/client';

export const GET_KYC_STATUS = gql`
  query GetKycStatus($companyId: ID!, $userId: ID!) {
    getKycStatus(companyId: $companyId, userId: $userId) {
      data {
        accountVerificationStatus
        automatedOnboardingKycStatus
        sdrVerificationStatus
        enableKyc
        totalAttempts
        kycMaxAttemptsAllowed
        lastError {
          code
          reason
        }
      }
    }
  }
`;
