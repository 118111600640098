import { LEGACY_TIMEZONES } from '@dashboard/library';
import { AnalyticsTimezoneState } from '@pages/looker-v2/Filters/TimezoneFilter';

export const DEFAULT_VALUE: AnalyticsTimezoneState = {
  timezone: 'Etc/UTC',
};

export const timezonesOptions = LEGACY_TIMEZONES.map((tz) => ({
  label: tz.name,
  value: tz.value,
}));

export const isTimezoneSupported = (timezone: string): boolean =>
  !!timezonesOptions.find((tz) => tz.value === timezone);

export const getSafeTimezone = (timezone: string | null): AnalyticsTimezoneState => {
  if (timezone && isTimezoneSupported(timezone)) {
    return { timezone };
  }

  return DEFAULT_VALUE;
};
