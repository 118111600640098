import { Key, useMemo } from 'react';

import {
  InternalNumber,
  LineOption,
  SearchableNumberSelectProps,
} from './SearchableNumberSelect.decl';

import { NumberVerificationStateTag } from '@components/NumberVerificationStateTag';
import { PhoneNumberFlag } from '@components/PhoneNumberFlag';
import { SingleSearchSelect } from '@dashboard/library';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { useSearchLines } from '@hooks/useSearchLines';
import { useTranslation } from 'react-i18next';

export function transformLineToInternalNumber(line: LineOption): InternalNumber {
  const { label, activationState, verificationState, phoneNumber, value } = line;
  return {
    name: label,
    activationState,
    verificationState,
    number: phoneNumber,
    id: value,
  };
}

function mapLine(line: SearchLinesQuery_searchAuthorizedLines_items): LineOption {
  const { ID, name: numberName, phoneNumber, activationState, verificationState } = line;
  return {
    label: numberName,
    value: ID,
    phoneNumber,
    activationState,
    verificationState,
  };
}

function transformInternalNumber({
  number,
  name,
  activationState,
  verificationState,
  id,
}: InternalNumber): LineOption {
  return {
    label: name,
    value: id,
    phoneNumber: number,
    activationState,
    verificationState,
  };
}

export function SearchableNumberSelect({
  onChange,
  selectedNumber,
  idsBlacklist = [],
}: SearchableNumberSelectProps) {
  const selectedNumberOptions = useMemo(
    () => (selectedNumber ? [transformInternalNumber(selectedNumber)] : []),
    [selectedNumber]
  );
  const { t } = useTranslation();
  const { setQuery, lines, loading, resultsCount } = useSearchLines(idsBlacklist);

  const options = useMemo(() => {
    const lineOptions = lines.map(mapLine).filter((line) => line.value !== selectedNumber?.id);
    return [...selectedNumberOptions, ...lineOptions];
  }, [lines, selectedNumber?.id, selectedNumberOptions]);

  const renderItemPrefix = (option: LineOption) => {
    const { phoneNumber } = option;
    return <PhoneNumberFlag number={phoneNumber} />;
  };

  const renderItemSuffix = ({ activationState, verificationState }: LineOption) =>
    activationState === 'inactive' &&
    verificationState && (
      <NumberVerificationStateTag
        verificationState={verificationState.toLowerCase()}
        activationState={activationState.toLowerCase()}
      />
    );

  const handleOnChange = (value: Key[]) => {
    const foundNumber = options.find((line) => line.value === value[0]);
    const formattedNumber = transformLineToInternalNumber(foundNumber!);
    onChange(formattedNumber);
  };

  return (
    <SingleSearchSelect<LineOption>
      texts={{
        result: t('generic.result'),
        results: t('generic.results'),
        placeholder: t('numbers.search.placeholder'),
      }}
      isSearch
      triggerStyle='select'
      resultsCount={resultsCount}
      onSelect={handleOnChange}
      selectedKeys={selectedNumber?.id ? [selectedNumber?.id] : []}
      options={options}
      w='100%'
      onSearch={setQuery}
      renderItemPrefix={renderItemPrefix}
      renderItemSuffix={renderItemSuffix}
      loading={loading}
    />
  );
}
