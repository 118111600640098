import { FlagIcon } from '@aircall/tractor-v2';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';

export function ColumnFieldCountry({
  countryISOCode,
  activationState,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const isActive = isNumberActive(activationState);
  return (
    <FlagIcon
      py='12px'
      countryCode={countryISOCode}
      size={24}
      pr='12px'
      opacity={isActive ? 1 : 0.8}
    />
  );
}
