import { useCallback, useState } from 'react';

import { SelectLinesProps, LineOption } from './SelectLines.decl';

import { Typography } from '@aircall/tractor-v2';
import { DEFAULT_LINES_SORT } from '@constants/generic.constants';
import {
  FlagIcon,
  MultiSearchSelect,
  formatInternationalNumber,
  useGraphQuery,
} from '@dashboard/library';
import { SearchLinesQuery, SearchLinesQueryVariables } from '@generated/SearchLinesQuery';
import { SEARCH_LINES_QUERY } from '@graphql/queries/SearchLinesQuery';
import { getSearchLinesFilter } from '@helpers/search.helpers';
import { useTranslation } from 'react-i18next';

export function SelectLines({ onSelect, idsBlacklist = new Set() }: SelectLinesProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const { data, loading } = useGraphQuery<SearchLinesQuery, SearchLinesQueryVariables>(
    SEARCH_LINES_QUERY,
    {
      variables: {
        filter: getSearchLinesFilter({ searchQuery: query }),
        sort: DEFAULT_LINES_SORT,
      },
    }
  );

  const searchOptions: LineOption[] =
    data?.searchAuthorizedLines?.items
      ?.filter((item) => !idsBlacklist.has(item.ID))
      .map((item) => ({
        value: item.ID,
        label: item.name,
        countryISOCode: item.countryISOCode,
        phoneNumber: item.phoneNumber,
      })) || [];

  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const renderItemPrefix = (option: LineOption) => <FlagIcon countryCode={option.countryISOCode} />;
  const renderItemSuffix = (option: LineOption) => (
    <Typography color='text-placeholder' variant='bodyRegularS'>
      {formatInternationalNumber(option.phoneNumber)}
    </Typography>
  );

  const handleSelect = useCallback(
    (linesIds: string[]) => {
      const lines = data!.searchAuthorizedLines!.items!.filter((item) =>
        linesIds.includes(item.ID)
      );
      onSelect(lines);
    },
    [data, onSelect]
  );

  return (
    <MultiSearchSelect<LineOption>
      options={searchOptions}
      loading={loading}
      isSearch
      onSelect={handleSelect}
      onSearch={handleSearch}
      renderItemPrefix={renderItemPrefix}
      renderItemSuffix={renderItemSuffix}
      texts={{
        placeholder: t('select_lines.placeholder'),
        item: t('generic.line'),
        items: t('generic.lines'),
        result: t('generic.result'),
        results: t('generic.results'),
      }}
      resultsCount={data?.searchAuthorizedLines?.total || 0}
      w='100%'
      triggerStyle='select'
    />
  );
}
