/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import {
  ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS,
  StatsAnalyticsPlusAdItemProps,
} from './StatsAnalyticsPlusAdItem.decl';

import { SidenavDropdownItem, SidenavDropdownItemLabel } from '@aircall/tractor-v2';
import { ADDONS } from '@constants/addons.constants';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { useNavigateToAddonPage } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function StatsAnalyticsPlusAdItem({ dataTest }: Readonly<StatsAnalyticsPlusAdItemProps>) {
  const { t } = useTranslation();
  const { track } = useDashboardExtensionContext();
  const navigate = useNavigateToAddonPage(track);

  function handleOnClick() {
    navigate(ADDONS.ANALYTICS_PLUS, 'discover-analytics-plus');
  }

  useEffect(() => {
    track(ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.BUTTON_SHOWN);
  }, [track]);

  return (
    <SidenavDropdownItem data-test={dataTest} onClick={handleOnClick} as='button' type='button'>
      <SidenavDropdownItemLabel>{t('stats.tabs.discover_analytics_plus')}</SidenavDropdownItemLabel>
    </SidenavDropdownItem>
  );
}
