import { gql } from '@apollo/client';
import { TEAM_FRAGMENT } from '@graphql/fragments/TeamFragment';

export const SEARCH_TEAMS_QUERY = gql`
  query SearchTeamsQuery(
    $from: Int
    $limit: Int
    $filter: SearchableTeamsFilterInput
    $sort: [SearchableTeamsSortInput]
  ) {
    searchAuthorizedTeams(from: $from, limit: $limit, filter: $filter, sort: $sort) {
      items {
        ...TeamFragment
      }
      total
    }
  }
  ${TEAM_FRAGMENT}
`;
