import { useContext, useEffect, useRef, useState } from 'react';

import { LegacyDashboardProps } from './LegacyDashboard.decl';
import { LegacyDashboardContext } from './LegacyDashboardProvider';

import { Flex, Box, Typography } from '@aircall/tractor-v2';
import { OptimisticProgressBar } from '@components/OptimisticProgressBar/OptimisticProgressBar';
import {
  LOGGING_FEATURE_NAMES,
  LOGGING_LEGACY_DASHBOARD_EVENTS,
  LOGGING_STATUS,
} from '@constants/logging.constants';
import { UnknownError } from '@dashboard/library';
import { createLog } from '@helpers/logging.helpers';
import { useParamsMemoized } from '@hooks/useParamsMemoized/useParamsMemoized';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const ERROR_TIMEOUT_IN_SECONDS = 90;

/**
 * Embed the legacy dashboard.
 * It will automatically guess which page of the Legacy Dashboard must be
 */
export function LegacyDashboard({ componentName }: LegacyDashboardProps) {
  const location = useLocation();
  const params = useParamsMemoized(); // We need params to be memoized because it's used in the useEffect
  const ref = useRef<HTMLDivElement>(null);
  const {
    progress,
    isLoaded,
    mount,
    unmount,
    navigate,
    show,
    getPusherConfigError,
    updatePusherConfigError,
  } = useContext(LegacyDashboardContext);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();
  const startProgressTime = useRef<number | null>(null);

  const [progressError, setProgressError] = useState<boolean>(false);

  useEffect(() => {
    if (!startProgressTime.current && progress === 0) {
      startProgressTime.current = Date.now();
    }

    const _isReady = progress >= 100 || isReady;

    if (startProgressTime.current && _isReady) {
      const duration = (Date.now() - startProgressTime.current) / 1000;

      // Log successful embed loading and the duration to datadog
      createLog({
        featureName: LOGGING_FEATURE_NAMES.LEGACY_DASHBOARD,
        event: LOGGING_LEGACY_DASHBOARD_EVENTS.LOADING,
        status: LOGGING_STATUS.SUCCESS,
        properties: {
          duration,
        },
      });

      // Reset progress time so that it does not get logged again
      startProgressTime.current = null;
    }
  }, [isReady, progress]);

  useEffect(() => {
    const _isReady = progress >= 100 || isReady;

    if (_isReady) {
      return noop;
    }

    const timeoutId = window.setTimeout(() => {
      // Log failed embed loading to datadog
      createLog({
        featureName: LOGGING_FEATURE_NAMES.LEGACY_DASHBOARD,
        event: LOGGING_LEGACY_DASHBOARD_EVENTS.LOADING,
        status: LOGGING_STATUS.FAILED,
        properties: {
          progress,
        },
      });

      // It's been some time we did not receive new progress, something is wrong
      setProgressError(true);
    }, ERROR_TIMEOUT_IN_SECONDS * 1000);

    return () => window.clearTimeout(timeoutId);
  }, [isReady, progress]);

  useEffect(() => {
    // If the legacy dashboard is ready
    if (isLoaded) {
      // We mount it
      mount(ref.current!, {
        // When the transition has succeeded
        onTransitionSucceeded: () => {
          // We show it
          setIsReady(true);
          show();
        },
      });
      // We make it navigate to the corresponding page
      navigate(location.pathname, params);

      // When the component gets unmounted
      return () => {
        // We unmount the legacy dashboard
        unmount();
      };
    }

    setIsReady(false);

    return void 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, location.pathname, params]);

  if (progressError) {
    throw new UnknownError('Error loading the embed.', { progress });
  }

  if (getPusherConfigError) {
    throw new UnknownError('Fail to get pusher config.', { getPusherConfigError });
  }

  if (updatePusherConfigError) {
    throw new UnknownError('Fail to update pusher config.', { updatePusherConfigError });
  }

  return (
    <Flex w='100%' h='100%' position='relative' alignItems='center' justifyContent='center'>
      {!isReady && progress < 100 && (
        <Box position='absolute' data-test='legacy-dashboard-loading'>
          <Box maxWidth={718} my='m'>
            <Typography variant='headingBoldXS' mb='xxs'>
              {t('embedded_dashboard.waiting_message.header')}
            </Typography>
            <Typography variant='bodyMediumS' mb='m'>
              {t('embedded_dashboard.waiting_message.content', { component: componentName })}
            </Typography>
            <Box>
              <OptimisticProgressBar
                progress={progress}
                speed={2}
                capping={30}
                maxCapping={95}
                height={4}
                color='primary.base'
              />
            </Box>
          </Box>
        </Box>
      )}
      <div style={{ width: '100%', height: '100%' }} ref={ref} />
    </Flex>
  );
}
