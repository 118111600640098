import { NumberDetailQuery_numberDetail as NumberDetail } from '../../generated/NumberDetailQuery';
import { useToggle } from '../../hooks/useToggle/useToggle';

import { CallbackRequest } from './CallbackRequest/CallbackRequest';
import { CallDistributionOptionsSettings } from './CallDistributionOptionsSettings/CallDistributionOptionsSettings';
import { CallRecordingSettings } from './CallRecordingSettings/CallRecordingSettings';
import { CallTagging } from './MandatoryCallTagging/MandatoryCallTagging';
import { MusicOnHoldSettings } from './MusicOnHoldSettings/MusicOnHoldSettings';
import { NumberDetailSettingsGeneral } from './NumberDetailSettingsGeneral/NumberDetailSettingsGeneral';

import { TrashFilled } from '@aircall/icons';
import { Button, Spacer } from '@aircall/tractor-v2';
import { DeleteNumberConfirmationModal } from '@components/DeleteNumberConfirmationModal';
import { Placeholder } from '@dashboard/extension';
import { useDeleteNumber } from '@hooks/useDeleteNumber';
import { useAdminFeatures } from '@hooks/useFeatures/useAdminFeatures';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTranslation } from 'react-i18next';

export function NumberDetailsSettingsPage({
  iso3166Alpha2,
  id,
  name,
  numberFormatted,
  ivr,
  flowEditorEnabled,
}: NumberDetail) {
  const { t } = useTranslation();
  const [isModalOpen, _, onClick, onCancelClick] = useToggle();
  const { handleDeleteNumber, isDeleteLoading, deleteError } = useDeleteNumber({ id, name });
  const { advancedAiPackage, enableRealtimeTranscription, smartflowsEnabled } = useFeaturesFlags();
  const { isCompanyAccountAllowed, isMandatoryTaggingAllowed, isQueueCallbackAllowed } =
    useAdminFeatures();

  const isSmartflowsNumber = smartflowsEnabled && flowEditorEnabled;

  return (
    <Spacer fluid direction='vertical' space='m' data-test='number-detail-settings-page'>
      <NumberDetailSettingsGeneral />
      {isSmartflowsNumber && <MusicOnHoldSettings />}
      {!ivr &&
        (isSmartflowsNumber ? (
          <Placeholder
            name='flow_editor_call_distribution_options'
            parameters={{ lineId: id }}
            data-test='flow_editor_call_distribution_options'
          />
        ) : (
          <CallDistributionOptionsSettings />
        ))}
      {!ivr &&
        (isCompanyAccountAllowed || isQueueCallbackAllowed) &&
        (isSmartflowsNumber ? (
          <Placeholder
            name='flow_editor_callback_request'
            parameters={{ lineId: id }}
            data-test='flow_editor_callback_request'
          />
        ) : (
          <CallbackRequest disabled={!isQueueCallbackAllowed} />
        ))}
      {isCompanyAccountAllowed || isMandatoryTaggingAllowed ? (
        <CallTagging disabled={!isMandatoryTaggingAllowed} />
      ) : null}
      <CallRecordingSettings />
      <Placeholder
        name='assets_page_transcription_settings'
        data-test='assets_page_transcription_settings'
        parameters={{ lineId: id }}
      />
      {advancedAiPackage && enableRealtimeTranscription && (
        <Placeholder
          name='assets_page_live_assistant_settings'
          data-test='assets_page_live_assistant_settings'
          parameters={{ lineId: id }}
        />
      )}
      <Button onClick={onClick} mode='outline' variant='critical' data-test='delete-number-button'>
        <TrashFilled />
        {t('number_details.settings.delete.button')}
      </Button>
      <DeleteNumberConfirmationModal
        isModalOpen={isModalOpen}
        onCancelClick={onCancelClick}
        onDeleteNumberClick={handleDeleteNumber}
        isDeleteDisabled={isDeleteLoading}
        error={deleteError}
        countryCode={iso3166Alpha2}
        number={numberFormatted}
        name={name}
      />
    </Spacer>
  );
}
