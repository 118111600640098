import { useCallback, useState } from 'react';

import { useAuthenticationState } from '../../hooks/useAuthenticationState';

import { ClickWrap } from './ClickWrap';
import { TermsOfUseModalProps } from './TermsOfUse.decl';

import {
  Button,
  ModalDialog,
  ModalBody,
  ModalFooter,
  noop,
  Spacer,
  Typography,
} from '@aircall/tractor-v2';
import { logger } from '@config/logger.config';
import { IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY } from '@constants/environment.constants';
import { useTranslation } from 'react-i18next';

export function TermsOfUseModal({
  countryCode,
  companyId,
  acceptContractTerms,
  onError,
}: Readonly<TermsOfUseModalProps>) {
  const { t } = useTranslation();

  const {
    actions: { signOut },
  } = useAuthenticationState();
  const [valid, setValid] = useState(false);

  const signAndRefreshPage = useCallback(async () => {
    try {
      await acceptContractTerms();
      window.location.reload();
    } catch (error) {
      const originalError = (error as Error).message;
      logger.error(`Unhandled exception while accepting T&C, ${originalError}`);
      onError();
    }
  }, [acceptContractTerms, onError]);

  const handleValid = useCallback((value: boolean) => () => setValid(value), [setValid]);

  return (
    <ModalDialog data-test='terms-of-use-modal' show onHide={noop}>
      <ModalBody px='m' pt='l' pb='xs'>
        <Spacer direction='vertical' space='s'>
          <Typography>{t('terms_and_conditions_modal_title')}</Typography>
          <ClickWrap
            groupKeys={[IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY]}
            companyId={companyId}
            disableSending
            allowDisagreed
            onValid={handleValid(true)}
            onInvalid={handleValid(false)}
            signupCountry={countryCode}
            clickWrapStyle='combined'
          />
        </Spacer>
      </ModalBody>
      <ModalFooter>
        <Spacer space='xs'>
          <Button mode='link' variant='secondary' onClick={signOut}>
            {t('download_app_screen.login_link')}
          </Button>
          <Button data-test='accept-terms-button' disabled={!valid} onClick={signAndRefreshPage}>
            {t('app.accept')}
          </Button>
        </Spacer>
      </ModalFooter>
    </ModalDialog>
  );
}
