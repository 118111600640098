import { useCallback, useState } from 'react';

import {
  ChevronDownOutlined,
  ChevronRightOutlined,
  InformationOutlined,
  OutboundOutlined,
  RefreshOutlined,
  StopFilled,
  WarningFilled,
} from '@aircall/icons';
import {
  Icon,
  Tag,
  Spacer,
  Typography,
  Box,
  Link,
  Flex,
  Button,
  List,
  ListView,
  StyledListItem,
  ListItem,
} from '@aircall/tractor-v2';
import { DeactivateWhatsAppConfirmationModal } from '@components/DeactivateWhatsAppConfirmationModal/DeactivateWhatsAppConfirmationModal';
import { MetaEmbeddedSignupButton } from '@components/MetaEmbeddedSignupButton';
import { LINE_CAPABILITY, LINE_PROVIDER } from '@constants/numbers.constants';
import { Placeholder } from '@dashboard/extension';
import { Loading, Paper, useGraphMutation, useToggle } from '@dashboard/library';
import {
  DeactivateWhatsAppNumber,
  DeactivateWhatsAppNumberVariables,
} from '@generated/DeactivateWhatsAppNumber';
import { getWhatsappRegistrationStatus_getWhatsappRegistrationStatus } from '@generated/getWhatsappRegistrationStatus';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import {
  RegisterWhatsAppNumberVariables,
  RegisterWhatsAppNumber,
} from '@generated/RegisterWhatsAppNumber';
import { DEACTIVATE_WHATSAPP_NUMBER } from '@graphql/mutations/DeactivateWhatsAppNumberMutation';
import { REGISTER_WHATSAPP_NUMBER } from '@graphql/mutations/RegisterWhatsAppNumberMutation';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useToast } from '@hooks/useToast';
import { Trans, useTranslation } from 'react-i18next';

function StatusFooter({
  status,
  updatedAt,
}: {
  readonly status: 'pending' | 'rejected';
  readonly updatedAt: string | null;
}) {
  const { t } = useTranslation();
  const updatedAtDate = updatedAt ? new Date(updatedAt) : null;
  const formattedUpdatedAt = updatedAtDate ? updatedAtDate.toLocaleString() : '-';
  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }} mt='s'>
      <Box flex='0 0 66.66%'>
        <Typography variant='bodyRegularS'>
          {t(
            `number_details.integrations.whatsapp_integration.status.${status}.status_update_info`
          )}
        </Typography>
      </Box>
      <Box flex='0 0 33.33%' style={{ textAlign: 'right' }}>
        <Typography variant='bodyRegularS'>
          {t(
            `number_details.integrations.whatsapp_integration.status.${status}.last_updated_time`,
            {
              updatedAt: formattedUpdatedAt,
            }
          )}
        </Typography>
      </Box>
    </Box>
  );
}

function StatusMessage({
  whatsappStatusInfo,
  onRefreshWhatsappStatus,
}: {
  readonly onRefreshWhatsappStatus: () => Promise<void>;
  readonly whatsappStatusInfo: getWhatsappRegistrationStatus_getWhatsappRegistrationStatus;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const whatsappRegistrationCreatedAt =
    whatsappStatusInfo.createdAt &&
    (Date.now() - new Date(whatsappStatusInfo.createdAt).getTime()) / (1000 * 60) > 15;

  const toggleContent = () => {
    setIsOpen((prevState) => !prevState);
  };

  const metaInfoList = [
    {
      id: 1,
      key: t('number_details.integrations.whatsapp_integration.meta_info.validation_status'),
      value: (
        <Tag data-test='audio-player-timer' size='small' bg='primary-300'>
          {whatsappStatusInfo.status}
        </Tag>
      ),
    },
    {
      id: 2,
      key: t('number_details.integrations.whatsapp_integration.meta_info.rate_limit'),
      value: whatsappStatusInfo.phoneNumber?.messagingLimitTier?.startsWith('TIER_')
        ? `${whatsappStatusInfo.phoneNumber?.messagingLimitTier.replace(
            'TIER_',
            ''
          )} customers per 24 hours`
        : '-',
    },
    {
      id: 3,
      key: t('number_details.integrations.whatsapp_integration.meta_info.can_send_message'),
      value: whatsappStatusInfo.phoneNumber?.healthStatus?.canSendMessage || '-',
    },
    {
      id: 4,
      key: t(
        'number_details.integrations.whatsapp_integration.meta_info.whatsapp_business_account_id'
      ),
      value: whatsappStatusInfo.wabaId ? (
        <Link
          href={t(
            'number_details.integrations.whatsapp_integration.meta_info.whatsapp_business_account_link',
            {
              wabaId: whatsappStatusInfo.wabaId,
            }
          )}
          target='blank'
        >
          <Spacer fluid direction='horizontal'>
            {whatsappStatusInfo.wabaId}
            <OutboundOutlined style={{ paddingBottom: '5px' }} />
          </Spacer>
        </Link>
      ) : (
        '-'
      ),
    },
  ];

  switch (whatsappStatusInfo.status) {
    case WhatsappRegistrationState.NOT_REGISTERED:
      return (
        <Tag variant='white' size='small'>
          <Icon component={WarningFilled} size={18} color='warning-500' />
          <Typography variant='bodyMediumS' color='text-base' as='p' margin='0'>
            <Trans
              i18nKey='number_details.integrations.whatsapp_integration.status.not_registered.title'
              components={{
                customLink: (
                  <Link
                    color='inherit'
                    fontSize='inherit'
                    target='_blank'
                    href={t(
                      'number_details.integrations.whatsapp_integration.status.not_registered.link'
                    )}
                  />
                ),
              }}
            />
          </Typography>
        </Tag>
      );
    case WhatsappRegistrationState.CREATING:
      return (
        <>
          <Flex
            flexDirection='column'
            border='1px solid #eee'
            borderRadius={20}
            gap='m'
            alignItems='center'
            padding='xxl'
          >
            <Icon component={InformationOutlined} size={45} color='warning-500' />
            <Typography variant='bodySemiboldM'>
              {t('number_details.integrations.whatsapp_integration.status.pending.title')}
            </Typography>
            <Typography variant='bodyMediumS'>
              {t('number_details.integrations.whatsapp_integration.status.pending.subtitle')}
            </Typography>
            {whatsappRegistrationCreatedAt && (
              <Button
                onClick={() => onRefreshWhatsappStatus()}
                data-test='refresh-button'
                variant='secondary'
              >
                <RefreshOutlined />
                {t(
                  'number_details.integrations.whatsapp_integration.status.pending.refresh_button_text'
                )}
              </Button>
            )}
          </Flex>
          <StatusFooter status='pending' updatedAt={whatsappStatusInfo.updatedAt} />
        </>
      );
    case WhatsappRegistrationState.ONLINE:
      return (
        <Typography variant='bodyMediumS' color='text-base' as='p' margin='0'>
          <Spacer
            direction='horizontal'
            space='xxxs'
            pt='m'
            onClick={toggleContent}
            marginBottom='xxs'
            data-test='template-toggle-button'
          >
            <Typography variant='bodySemiboldS' color='text-base' as='p' margin='0'>
              {t(
                'number_details.integrations.whatsapp_integration.status.verified.templated_title'
              )}
            </Typography>

            {isOpen ? (
              <ChevronDownOutlined size={15} style={{ marginTop: '3px' }} />
            ) : (
              <ChevronRightOutlined size={15} style={{ marginTop: '3px' }} />
            )}
          </Spacer>
          {isOpen && (
            <>
              <br />
              <Trans
                i18nKey='number_details.integrations.whatsapp_integration.status.verified.templated_subtitle'
                components={{
                  ol: <ol />,
                  li: <li />,
                  bold: <strong />,
                }}
              />
              <br />
            </>
          )}
          <Typography variant='headingBoldS' color='text-base' as='p' margin='0' paddingBottom='s'>
            {t('number_details.integrations.whatsapp_integration.meta_info.title')}
          </Typography>
          <List fluid>
            <ListView>
              {metaInfoList.map((item) => (
                <ListItem key={item.id} data-test={`meta-info-list-item-${item.id}`}>
                  <StyledListItem padding='10px' backgroundColor='neutral-100'>
                    <Flex alignItems='center'>
                      <Box
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '30%' }}
                        data-test={`meta-info-key-${item.id}`}
                      >
                        {item.key}
                      </Box>
                      <Box data-test={`meta-info-value-${item.id}`}>{item.value}</Box>
                    </Flex>
                  </StyledListItem>
                </ListItem>
              ))}
            </ListView>
          </List>
        </Typography>
      );
    case WhatsappRegistrationState.OFFLINE:
      return (
        <>
          <Flex
            flexDirection='column'
            border='1px solid #eee'
            borderRadius={20}
            gap='m'
            alignItems='center'
            padding='xxl'
          >
            <Icon component={StopFilled} color='critical-500' size={45} />
            <Typography variant='headingBoldM'>
              {t('number_details.integrations.whatsapp_integration.status.rejected.title')}
            </Typography>
            <Typography variant='bodyMediumS'>
              {t('number_details.integrations.whatsapp_integration.status.rejected.subtitle')}
            </Typography>
          </Flex>
          <StatusFooter status='rejected' updatedAt={whatsappStatusInfo.updatedAt} />
        </>
      );
    default:
      return null;
  }
}

interface NumberDetailWhatsAppIntegrationSectionProps {
  whatsappStatusInfo: getWhatsappRegistrationStatus_getWhatsappRegistrationStatus | undefined;
  refreshWhatsappStatus: () => Promise<void>;
  setWhatsappStatusInfo: React.Dispatch<
    React.SetStateAction<getWhatsappRegistrationStatus_getWhatsappRegistrationStatus | undefined>
  >;
  numberUUID: string | null | undefined;
  provider: string | null | undefined;
  capabilities: (string | null)[] | null | undefined;
}

export function NumberDetailWhatsAppIntegrationSection({
  whatsappStatusInfo,
  refreshWhatsappStatus,
  setWhatsappStatusInfo,
  numberUUID,
  provider,
  capabilities,
}: NumberDetailWhatsAppIntegrationSectionProps) {
  const { t } = useTranslation();
  const { enableWhatsappIntegration } = useFeaturesFlags();
  const [isOpen, setIsOpen] = useState(true);
  const [deactivateModalOpen, _, setDeactivateModalOpen, setDeactivateModalClosed] = useToggle();
  const { currentCompany } = useGlobalData();
  const isTwilioSMSCapable = !!(
    provider === LINE_PROVIDER.TWILIO && capabilities?.includes(LINE_CAPABILITY.SMS)
  );
  const toast = useToast();

  const [registerWhatsAppNumberMutation] = useGraphMutation<
    RegisterWhatsAppNumber,
    RegisterWhatsAppNumberVariables
  >(REGISTER_WHATSAPP_NUMBER);

  const [deactivateWhatsAppNumberMutation] = useGraphMutation<
    DeactivateWhatsAppNumber,
    DeactivateWhatsAppNumberVariables
  >(DEACTIVATE_WHATSAPP_NUMBER);

  const onRefreshWhatsappStatus = useCallback(async () => {
    setWhatsappStatusInfo(undefined);
    await refreshWhatsappStatus();
  }, [refreshWhatsappStatus, setWhatsappStatusInfo]);

  const onEmbeddedSignup = useCallback(
    async (phoneNumberId: string, wabaId: string) => {
      setWhatsappStatusInfo(undefined);
      await registerWhatsAppNumberMutation({
        variables: {
          input: {
            companyName: currentCompany.name,
            companyPhoneNumberId: phoneNumberId,
            companyWabaId: wabaId,
            numberUUID: numberUUID as string,
          },
        },
        onError: (error) => {
          toast.showToast({
            message: error.message,
            variant: 'critical',
            dismissIn: 5000,
          });
        },
      });
      await refreshWhatsappStatus();
    },
    [
      currentCompany.name,
      numberUUID,
      registerWhatsAppNumberMutation,
      refreshWhatsappStatus,
      setWhatsappStatusInfo,
      toast,
    ]
  );

  const toggleContent = () => {
    setIsOpen((prevState) => !prevState);
  };

  const deactivateWhatsAppCallback = useCallback(async () => {
    setDeactivateModalClosed();
    setWhatsappStatusInfo(undefined);
    await deactivateWhatsAppNumberMutation({
      variables: { input: { numberUUID: numberUUID as string } },
      onError: (error) => {
        toast.showToast({
          message: error.message,
          variant: 'critical',
          dismissIn: 5000,
        });
      },
    });
    await refreshWhatsappStatus();
  }, [
    numberUUID,
    deactivateWhatsAppNumberMutation,
    refreshWhatsappStatus,
    setDeactivateModalClosed,
    setWhatsappStatusInfo,
    toast,
  ]);

  return enableWhatsappIntegration ? (
    <>
      <Paper
        title={t('number_details.integrations.whatsapp_integration.title')}
        subtitle={<Trans i18nKey='number_details.integrations.whatsapp_integration.subtitle' />}
      >
        <Box minWidth='0' style={{ wordBreak: 'break-word', textAlign: 'start' }}>
          <Typography variant='bodyMediumS' color='text-base' as='span' margin='0'>
            <Spacer
              direction='horizontal'
              space='xxxs'
              pt='m'
              onClick={toggleContent}
              marginBottom='xs'
              data-test='toggle-button'
            >
              <Typography variant='bodySemiboldS' color='text-base' as='span' margin='0'>
                {t('number_details.integrations.whatsapp_integration.how_to_proceed')}
              </Typography>

              {isOpen ? (
                <ChevronDownOutlined size={15} style={{ marginTop: '3px' }} />
              ) : (
                <ChevronRightOutlined size={15} style={{ marginTop: '3px' }} />
              )}
            </Spacer>
            {isOpen && (
              <>
                <br />
                <Trans
                  i18nKey='number_details.integrations.whatsapp_integration.how_to_proceed_subtitle'
                  components={{
                    ol: <ol />,
                    li: <li />,
                    bold: <strong />,
                  }}
                />
                <br />
              </>
            )}
          </Typography>
        </Box>
        {whatsappStatusInfo?.status ? (
          <>
            <Box
              minWidth='0'
              style={{ wordBreak: 'break-word', textAlign: 'start' }}
              data-test='status-message'
            >
              <StatusMessage
                whatsappStatusInfo={whatsappStatusInfo}
                onRefreshWhatsappStatus={onRefreshWhatsappStatus}
              />
            </Box>
            <Spacer fluid direction='vertical' space='m' marginTop='m'>
              {!!whatsappStatusInfo.wabaId && (
                <Box h='450px'>
                  <Placeholder
                    name='whatsapp_list_templates'
                    parameters={{ wabaId: whatsappStatusInfo.wabaId }}
                    data-test='whatsapp_list_templates'
                  />
                </Box>
              )}
            </Spacer>

            <br />
            <Spacer fluid direction='vertical' space='xxxs'>
              <MetaEmbeddedSignupButton
                embeddedSignupCallback={onEmbeddedSignup}
                deactivateWhatsAppCallback={setDeactivateModalOpen}
                status={whatsappStatusInfo.status}
                isTwilioSMSCapable={isTwilioSMSCapable}
              />
            </Spacer>
          </>
        ) : (
          <Box minWidth='0' style={{ wordBreak: 'break-word', textAlign: 'start' }}>
            <Loading data-test='whatsapp-integration-loading' />
          </Box>
        )}
      </Paper>
      <DeactivateWhatsAppConfirmationModal
        data-test='deactivate-whatsapp-confirmation-modal'
        show={deactivateModalOpen}
        onDeactivateClick={deactivateWhatsAppCallback}
        onCancelClick={setDeactivateModalClosed}
      />
    </>
  ) : null;
}
