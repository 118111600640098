import { useGraphQuery } from '@dashboard/library';
import {
  CompanyIntegrationsQuery_companyIntegrations_data,
  CompanyIntegrationsQuery,
} from '@generated/CompanyIntegrationsQuery';
import { COMPANY_INTEGRATIONS_QUERY } from '@graphql/queries/CompanyIntegrationsQuery';

interface UseUnconnectedIntegrationsReturnType {
  bridgeIntegrations: CompanyIntegrationsQuery_companyIntegrations_data[];
  hubIntegrations: CompanyIntegrationsQuery_companyIntegrations_data[];
  loading: boolean;
}

function separateIntegrationList(
  list: CompanyIntegrationsQuery_companyIntegrations_data[] | undefined,
  connectedIntegrations: string[]
) {
  const bridgeIntegrations: CompanyIntegrationsQuery_companyIntegrations_data[] = [];
  const hubIntegrations: CompanyIntegrationsQuery_companyIntegrations_data[] = [];
  list &&
    list.forEach((item) => {
      if (!connectedIntegrations.includes(item.id)) {
        if (item.attributes.service === 'bridge') {
          bridgeIntegrations.push(item);
        } else {
          hubIntegrations.push(item);
        }
      }
    });
  return { bridgeIntegrations, hubIntegrations };
}
export const useUnconnectedIntegrations = (
  connectedIntegrations: string[]
): UseUnconnectedIntegrationsReturnType => {
  const { data, loading } = useGraphQuery<CompanyIntegrationsQuery>(COMPANY_INTEGRATIONS_QUERY);

  const { bridgeIntegrations, hubIntegrations } = separateIntegrationList(
    data && data.companyIntegrations.data,
    connectedIntegrations
  );
  data && data.companyIntegrations.data.filter((item) => !connectedIntegrations.includes(item.id));

  return { bridgeIntegrations, hubIntegrations, loading };
};
