import { useCallback, useMemo } from 'react';

import { FetchResult } from '@apollo/client';
import { useGraphQuery } from '@dashboard/library';
import {
  TextToSpeechOptionsQuery,
  TextToSpeechOptionsQueryVariables,
  TextToSpeechOptionsQuery_textToSpeechOptions_languages,
} from '@generated/TextToSpeechOptionsQuery';
import {
  TextToSpeechPreviewMutation,
  TextToSpeechPreviewMutationVariables,
  TextToSpeechPreviewMutation_textToSpeechPreview_data,
} from '@generated/TextToSpeechPreviewMutation';
import { TEXT_TO_SPEECH_PREVIEW_MUTATION } from '@graphql/mutations/TextToSpeechPreviewMutation';
import { TEXT_TO_SPEECH_OPTIONS_QUERY } from '@graphql/queries/TextToSpeechOptionsQuery';
import { ClientError } from '@helpers/errors.helpers';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphMutation } from '@hooks/useMutation';
import debounce from 'lodash-es/debounce';

export type GeneratePreview = (
  numberId: string,
  ttsId: string,
  language: string,
  voice: string,
  message: string
) => void;

export interface UseTextToSpeechReturnType {
  languages: TextToSpeechOptionsQuery_textToSpeechOptions_languages[] | null;
  loading: boolean;
  error: ClientError | undefined;
  generatePreview: GeneratePreview;
  generateLazyPreview: (
    numberId: string,
    ttsId: string,
    language: string,
    voice: string,
    message: string
  ) => Promise<FetchResult<TextToSpeechPreviewMutation>>;
  generatePreviewDebounced: GeneratePreview;
  preview: TextToSpeechPreviewMutation_textToSpeechPreview_data | null;
  previewUrl: string | null;
  previewLoading: boolean;
  previewError: ClientError | undefined;
}

/**
 * Hook providing text to speech utilities.
 * @returns an object holding text to speech utilities
 */
export function useTextToSpeech(): UseTextToSpeechReturnType {
  const { currentUser } = useGlobalData();

  const { data, loading, error } = useGraphQuery<
    TextToSpeechOptionsQuery,
    TextToSpeechOptionsQueryVariables
  >(TEXT_TO_SPEECH_OPTIONS_QUERY, {
    throwError: false,
    variables: {
      locale: currentUser.language,
    },
  });

  const [mutatePreview, { data: previewData, loading: previewLoading, error: previewError }] =
    useGraphMutation<TextToSpeechPreviewMutation, TextToSpeechPreviewMutationVariables>(
      TEXT_TO_SPEECH_PREVIEW_MUTATION
    );

  const generateLazyPreview = useCallback(
    (numberId: string, ttsId: string, language: string, voice: string, message: string) =>
      mutatePreview({
        variables: {
          numberId,
          ttsId,
          input: {
            data: {
              id: ttsId,
              type: 'tts_message',
              attributes: {
                language,
                voice,
                message,
              },
            },
          },
        },
      }),
    [mutatePreview]
  );

  const generatePreview = useCallback(
    (numberId: string, ttsId: string, language: string, voice: string, message: string) => {
      generateLazyPreview(numberId, ttsId, language, voice, message);
    },
    [generateLazyPreview]
  );

  const generatePreviewDebounced = useMemo(
    () => debounce(generatePreview, 1000),
    [generatePreview]
  );

  const languages = data ? data.textToSpeechOptions.languages : null;
  const preview = previewData ? previewData.textToSpeechPreview.data : null;
  const previewUrl = preview ? preview.attributes.url : null;

  return {
    languages,
    loading,
    error,
    generateLazyPreview,
    generatePreview,
    generatePreviewDebounced,
    preview,
    previewUrl,
    previewLoading,
    previewError,
  };
}
