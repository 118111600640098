/**
 * Takes an ISO date string `YYYY-MM-DDTHH:MM:SSZ` and converts it to an epoch timestamp
 * in millseconds.
 */
export function convertISOtoEpoch(isoDate: string | null): string {
  if (!isoDate) {
    return '';
  }

  const date = new Date(isoDate);
  const time = date.getTime();

  if (isNaN(time)) {
    return '';
  }

  return time.toString();
}

const USER_LANGUAGE_TO_TANDEM_LANGUAGE: Record<string, string> = {
  'en-US': 'en',
  'fr-FR': 'fr',
  'es-ES': 'es',
  'it-IT': 'it',
  'de-DE': 'de',
};

// Convert the user language to Tandem compaitible code
export function getTandemLanguage(language?: string): string {
  return USER_LANGUAGE_TO_TANDEM_LANGUAGE[language ?? 'en-US'] ?? 'en';
}
