import { useCallback, useState } from 'react';

import { Avatar, AvatarImg, Radio, RadioGroup, Spacer, Typography } from '@aircall/tractor-v2';
import { Popup } from '@components/Popup';
import { SelectLines } from '@components/SelectLines';
import { SELECT_ALL_NUMBERS_PARAM_VALUE } from '@constants/integrations.constants';
import { Gap } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { GetAuthorizedApplicationQuery_getAuthorizedApplication } from '@generated/GetAuthorizedApplicationQuery';
import { SearchLinesQuery_searchAuthorizedLines_items as Line } from '@generated/SearchLinesQuery';
import { useTranslation } from 'react-i18next';

interface PopupAuthorizationProps {
  allowAll?: boolean;
  application:
    | ApplicationsListQuery_getApplications
    | GetAuthorizedApplicationQuery_getAuthorizedApplication;
  onSubmit: (lines: Line[] | typeof SELECT_ALL_NUMBERS_PARAM_VALUE) => Promise<void>;
  onSkip: () => void;
}

enum SELECT_OPTIONS {
  SELECT_ALL = 'SELECT_ALL',
  SELECT_NUMBERS = 'SELECT_NUMBERS',
}

export function PopupSelectNumbers({
  allowAll = false,
  application,
  onSubmit,
  onSkip,
}: PopupAuthorizationProps) {
  const { t } = useTranslation();
  const dataTest = 'popup-select-numbers';
  const { logo, name } = application;

  const [selectOption, setSelectAllNumbers] = useState<SELECT_OPTIONS>(
    allowAll ? SELECT_OPTIONS.SELECT_ALL : SELECT_OPTIONS.SELECT_NUMBERS
  );
  const [selectedLines, setSelectedLines] = useState<Line[]>([]);

  const handleSelectChange = useCallback((lines: Line[]) => {
    setSelectedLines(lines);
  }, []);

  const handleSubmit = useCallback(() => {
    if (selectOption === SELECT_OPTIONS.SELECT_ALL) {
      return onSubmit(SELECT_ALL_NUMBERS_PARAM_VALUE);
    }

    return onSubmit(selectedLines);
  }, [onSubmit, selectOption, selectedLines]);

  return (
    <Popup
      submitText={t('integration_flow.select_numbers.add_numbers')}
      onSubmit={handleSubmit}
      cancelText={t('integration_flow.select_numbers.skip')}
      onCancel={onSkip}
      data-test={dataTest}
    >
      <Spacer direction='vertical' alignItems='center' space='m'>
        <Spacer space='xl'>
          <Avatar size='xLarge'>
            <AvatarImg src='https://cdn.aircall.io/aircall-logo-squared.png' />
          </Avatar>

          {logo && (
            <Avatar size='xLarge' data-test={`${dataTest}-app-logo`}>
              <AvatarImg src={logo} />
            </Avatar>
          )}
        </Spacer>
        <Gap flexDirection='column' alignItems='center' gap='m'>
          <Gap flexDirection='column' alignItems='center' gap='xxxs'>
            <Typography variant='bodySemiboldM'>
              {t('integration_flow.authorization.popup.select_numbers.title', {
                applicationName: name,
              })}
            </Typography>
            <Typography variant='bodyMediumS'>
              {t('integration_flow.authorization.popup.select_numbers.subtitle', {
                applicationName: name,
              })}
            </Typography>
          </Gap>

          {allowAll && (
            <RadioGroup
              defaultValue={selectOption}
              direction='horizontal'
              space='l'
              onChange={(value) => setSelectAllNumbers(value)}
            >
              <Radio value={SELECT_OPTIONS.SELECT_ALL} data-test='radio-select-all'>
                {t('integration_flow.select_numbers.select_all_numbers')}
              </Radio>
              <Radio value={SELECT_OPTIONS.SELECT_NUMBERS} data-test='radio-select-specific'>
                {t('integration_flow.select_numbers.select_specific_numbers')}
              </Radio>
            </RadioGroup>
          )}

          {selectOption === SELECT_OPTIONS.SELECT_NUMBERS && (
            <SelectLines onSelect={handleSelectChange} />
          )}
        </Gap>
      </Spacer>
    </Popup>
  );
}
