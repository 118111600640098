import React from 'react';

import { StripeKycVerificationStates, useStripeKycBanner } from './hooks/useKycFloatingLockBanner';
import { CloseIcon, InProgressIcon, LockIcon, WarningIcon } from './icons';
import {
  Banner,
  BannerContent,
  BannerDescription,
  BannerHeading,
  BannerHeadingContainer,
  BannerLink,
  CircularBackground,
  CloseButton,
  FloatingBannerContainer,
} from './KycFloatingLockBanner.styles';

import { KYC_VERIFICATION_ROUTE } from '@constants/routes.constants';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Internal link component
 */
function InternalLink({ children, ...props }: React.ComponentProps<typeof Link>) {
  return <BannerLink to={props.to}>{children}</BannerLink>;
}

/**
 * External link component
 */
function ExternalLink({ children, ...props }: React.ComponentProps<typeof BannerLink>) {
  return (
    <BannerLink as='a' target='_blank' rel='noopener noreferrer' {...props}>
      {children}
    </BannerLink>
  );
}

/**
 * KYC Floating Lock Banner component
 */
export function KycFloatingLockBanner() {
  const { t } = useTranslation();
  const { verificationState, handleClose, handleReopen } = useStripeKycBanner();

  const renderContent = () => {
    if (verificationState === StripeKycVerificationStates.POPUP_CLOSED) {
      return (
        <CircularBackground onClick={handleReopen}>
          <LockIcon />
        </CircularBackground>
      );
    }

    if (
      verificationState === StripeKycVerificationStates.NO_SHOW ||
      verificationState === StripeKycVerificationStates.USER_VERIFIED
    ) {
      return null;
    }

    const bannerConfig = {
      [StripeKycVerificationStates.NO_VERIFY_NO_ATTEMPT]: {
        icon: <LockIcon />,
        dataTest: 'stripe-kyc-banner-no-verify',
        titleKey: 'stripe.kyc.lock_banner.no_verify_no_attempt.title',
        descriptionKey: 'stripe.kyc.lock_banner.no_verify_no_attempt.description',
        actionKey: 'stripe.kyc.lock_banner.no_verify_no_attempt.action',
        linkComponent: <InternalLink key='action' to={KYC_VERIFICATION_ROUTE} />,
      },
      [StripeKycVerificationStates.VERIFICATION_IN_PROCESS]: {
        icon: <InProgressIcon />,
        dataTest: 'stripe-kyc-banner-in-process',
        titleKey: 'stripe.kyc.lock_banner.verification_in_progress.title',
        descriptionKey: 'stripe.kyc.lock_banner.verification_in_progress.description',
        linkComponent: null,
      },
      [StripeKycVerificationStates.VERIFICATION_REJECTED_FINAL]: {
        icon: <WarningIcon />,
        dataTest: 'stripe-kyc-banner-rejected',
        titleKey: 'stripe.kyc.lock_banner.verification_rejected.title',
        descriptionKey: 'stripe.kyc.lock_banner.verification_rejected.description',
        actionKey: 'stripe.kyc.lock_banner.verification_rejected.action',
        linkComponent: (
          <ExternalLink
            key='action'
            href={t('stripe.kyc.lock_banner.verification_rejected.link')}
          />
        ),
      },
      [StripeKycVerificationStates.VERIFICATION_UNSUCCESSFUL]: {
        icon: <WarningIcon />,
        dataTest: 'stripe-kyc-banner-rejected',
        titleKey: 'stripe.kyc.lock_banner.verification_unsuccessful.title',
        descriptionKey: 'stripe.kyc.lock_banner.verification_unsuccessful.description',
        actionKey: 'stripe.kyc.lock_banner.verification_unsuccessful.action',
        linkComponent: <InternalLink key='action' to={KYC_VERIFICATION_ROUTE} />,
      },
    };

    const config = bannerConfig[verificationState];

    return (
      <FloatingBannerContainer>
        <Banner data-test={config.dataTest}>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <BannerContent>
            <BannerHeadingContainer>
              {config.icon}
              <BannerHeading>{t(config.titleKey)}</BannerHeading>
            </BannerHeadingContainer>
            <BannerDescription>
              {config.linkComponent ? (
                <Trans
                  components={[config.linkComponent]}
                  i18nKey={config.descriptionKey}
                  values={{
                    action: config.actionKey && t(config.actionKey),
                  }}
                />
              ) : (
                t(config.descriptionKey)
              )}
            </BannerDescription>
          </BannerContent>
        </Banner>
      </FloatingBannerContainer>
    );
  };

  return renderContent();
}
