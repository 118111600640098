import { useEffect, useMemo, PropsWithChildren } from 'react';

import { AudioPlayerProvider } from './components/AudioPlayer';
import { createClient } from './config/graphql/apolloClient.config';
import { Head } from './Head';
import { useAuthenticationState } from './hooks/useAuthenticationState';
import { AppRoutes } from './routes/App.routes';
import { AuthenticationProvider } from './state/app/authentication/AuthenticationProvider';
import { GlobalStyle } from './styles/global.styles';

import * as Icons from '@aircall/icons';
import { Tractor, TractorProps } from '@aircall/tractor-v2';
import { ApolloProvider } from '@apollo/client';
import { BrowserBlocker } from '@components/BrowserBlocker';
import { GOOGLE_CLIENT_ID, GTM_APP_ID, INTERCOM_APP_ID } from '@constants/environment.constants';
import { NavigationBlockerDialog, NavigationBlockerProvider } from '@dashboard/library';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { removeOldCookie } from '@helpers/authentication.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as ReactRouterV6 } from 'react-router-dom';
import { BrowserRouter as ReactRouterV7 } from 'react-router-v7';
import { IntercomProvider } from 'react-use-intercom';

function Apollo({ children }: PropsWithChildren<unknown>) {
  const {
    authState: { status: authStatus },
    actions: { onSessionExpired, refreshToken },
  } = useAuthenticationState();

  // Memoize apollo client so that ApolloProvider does not re-init when <Main /> is re-rendered (ex: when token is refreshed)
  const apolloClient = useMemo(
    () => createClient(onSessionExpired, refreshToken),
    [refreshToken, onSessionExpired]
  );

  useEffect(() => {
    if (authStatus !== AUTHENTICATION_STATUS.AUTHENTICATED) {
      apolloClient.clearStore();
    }
  }, [apolloClient, authStatus]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

// This is a temporary solution to so that we are able to migrate from v6 to v7.
// After the migration is done, we can remove this code, and probably consider wrapping Apollo with the Router just like before.
// We need Apollo to wrap the Router, so that we are able to fetch Feature Flags here.
// https://reactrouter.com/upgrading/v6
function PickRouter({ children }: PropsWithChildren<unknown>) {
  const { dashboardReactRouterV7MigrationEnabled } = useFeaturesFlags();

  if (dashboardReactRouterV7MigrationEnabled) {
    return <ReactRouterV7>{children}</ReactRouterV7>;
  }

  return <ReactRouterV6>{children}</ReactRouterV6>;
}

function RouterContent() {
  const { t } = useTranslation();

  return (
    <BrowserBlocker>
      <NavigationBlockerProvider>
        <AppRoutes />
        <NavigationBlockerDialog
          descriptionText={t('form_flow.navigation_blocker.description')}
          cancelText={t('form_flow.navigation_blocker.cancel')}
          discardText={t('form_flow.navigation_blocker.discard')}
        />
      </NavigationBlockerProvider>
    </BrowserBlocker>
  );
}

export function Main() {
  return (
    <AudioPlayerProvider>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <GTMProvider state={{ id: GTM_APP_ID }}>
          <Apollo>
            <PickRouter>
              <RouterContent />
            </PickRouter>
          </Apollo>
        </GTMProvider>
      </IntercomProvider>
    </AudioPlayerProvider>
  );
}

export default function App() {
  useEffect(() => {
    removeOldCookie();
  }, []);

  return (
    <Tractor icons={Icons as TractorProps['icons']}>
      <Head />
      <GlobalStyle />
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <AuthenticationProvider>
          <Main />
        </AuthenticationProvider>
      </GoogleOAuthProvider>
    </Tractor>
  );
}
