import { useCallback, useEffect, useState } from 'react';

import { STRIPE_PUBLISHABLE_KEY } from '@constants/environment.constants';
import { ONBOARDING_ROUTE } from '@constants/routes.constants';
import { useGraphMutation } from '@dashboard/library';
import {
  CreateKycVerificationSessionMutation,
  CreateKycVerificationSessionMutationVariables,
} from '@generated/CreateKycVerificationSessionMutation';
import { CREATE_KYC_VERIFICATION_SESSION_MUTATION } from '@graphql/mutations/CreateKycVerificationSession';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useToast } from '@hooks/useToast';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type HandleButtonLinkType = () => Promise<void>;

type UseHandleButtonLinkReturnType = {
  handleButtonLink: HandleButtonLinkType;
  loading: boolean;
  stripe: Stripe | null;
};

export const useHandleButtonLink = (): UseHandleButtonLinkReturnType => {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [createKycVerificationSession, { loading }] = useGraphMutation<
    CreateKycVerificationSessionMutation,
    CreateKycVerificationSessionMutationVariables
  >(CREATE_KYC_VERIFICATION_SESSION_MUTATION);

  const {
    currentCompany: { id: companyId },
    currentUser: { ID: userId },
  } = useGlobalData();

  const showToastError = useCallback(() => {
    toast.showToast({
      variant: 'critical',
      message: t('generic_errors.other.unknown_error'),
    });
  }, [toast, t]);

  useEffect(() => {
    loadStripe(STRIPE_PUBLISHABLE_KEY)
      .then((stripeObj) => {
        setStripe(stripeObj);
      })
      .catch(() => {
        showToastError();
      });
  }, [showToastError]);

  if (!stripe) {
    return { handleButtonLink: () => Promise.resolve(), loading: true, stripe: null };
  }

  const handleButtonLink: HandleButtonLinkType = async () => {
    try {
      const { data } = await createKycVerificationSession({
        variables: {
          input: {
            userId,
            companyId: companyId.toString(),
          },
        },
      });
      if (!data?.createKycVerificationSession?.data?.clientSecret) {
        showToastError();
        return;
      }

      const { error: verifyError } = await stripe.verifyIdentity(
        data.createKycVerificationSession.data.clientSecret
      );

      // Don't show an error if the user closes the modal
      if (verifyError && verifyError.code !== 'session_cancelled') {
        showToastError();
      }

      if (!verifyError) {
        navigate(ONBOARDING_ROUTE, { replace: true });
      }
    } catch (error) {
      showToastError();
    }
  };

  return { handleButtonLink, loading, stripe };
};
