import { FunctionComponent } from 'react';

import { FeatureCheck, SidenavItemConfig } from './Sidenav.config';

import { RESOURCE } from '@constants/permissions.constants';
import { MenuItem } from '@dashboard/extension';
import { css } from '@xstyled/styled-components';

/**
 * Necessary inline-end offset to prevent outline from being clipped by scrollbar.
 */
export const INLINE_END_OUTLINE_OFFSET = 6;

/**
 * Used to prevent outline from being clipped by scrollbar.
 */
export const paddingInlineEndForOutlineOffset = css`
  @supports (scrollbar-gutter: stable) {
    padding-inline-end: ${INLINE_END_OUTLINE_OFFSET}px;
  }
`;

export function formatToSidebarItem({
  enabled = true,
  icon,
  label,
  to,
  permissionResource,
  position,
  children,
  hasSeparator,
}: MenuItem & { hasSeparator?: FeatureCheck }): SidenavItemConfig {
  return {
    key: label,
    to,
    dataTest: `nav-item-${label}`,
    icon: icon as FunctionComponent,
    title: label,
    position,
    resources: permissionResource as RESOURCE[] | undefined,
    enabled: () => enabled,
    children: children?.map((child) => formatToSidebarItem(child)),
    hasSeparator,
  };
}

type Item = {
  position?: number;
};
type ItemWithPos = Item & Required<Pick<Item, 'position'>>;
type ItemWithoutPos = Omit<Item, 'position'>;

export function sortByPosition<T extends Item>(items: T[]): T[] {
  const totalLength = items.length;
  const merged = new Array(totalLength);

  const seenPos = new Set<number>();

  const itemsWithPos = (items.filter((item) => item.position !== undefined) as ItemWithPos[])
    .sort((a, b) => a.position - b.position)
    // Fix duplicate positions
    .map((item) => {
      let newPosition = item.position;

      // Keep incrementing position until it's unique
      while (seenPos.has(newPosition)) {
        // If position already exists shift position by 1
        // eslint-disable-next-line no-param-reassign
        newPosition += 1;
      }

      seenPos.add(newPosition);
      return { ...item, position: newPosition };
    });

  const itemsWithoutPos = items.filter((item) => !item.position) as ItemWithoutPos[];

  // Place items with positions
  itemsWithPos.forEach((item) => {
    const i = item.position - 1;
    merged[i] = item;
  });

  // Place items without positions in the gaps and at the end
  let j = 0;
  for (let i = 0; i < totalLength; i += 1) {
    if (merged[i] === undefined) {
      merged[i] = itemsWithoutPos[j];
      j += 1;
    }
  }

  // We remove the undefined items in case items were pushed at the end
  return merged.filter((item) => item !== undefined);
}
