/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';

import { MetaEmbeddedSignupButtonProps } from './MetaEmbeddedSignupButton.decl';

import { Button, Link } from '@aircall/tractor-v2';
import {
  getEmbeddedSignupListener,
  launchEmbeddedSignup,
  loadFacebookSDKScript,
} from '@components/MetaEmbeddedSignupButton/helpers/metaEmbeddedSignup.helpers';
import { logger } from '@config/logger.config';
import { Loading } from '@dashboard/library';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { useTranslation } from 'react-i18next';

export function MetaEmbeddedSignupButton({
  embeddedSignupCallback,
  deactivateWhatsAppCallback,
  status,
  isTwilioSMSCapable,
}: Readonly<MetaEmbeddedSignupButtonProps>) {
  const { t } = useTranslation();
  const [enableFacebookButton, setEnableFacebookButton] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const embeddedSignupListener = useCallback(
    async (event: MessageEvent) => {
      if (!event.origin.endsWith('facebook.com')) {
        return;
      }

      let data;
      try {
        data = JSON.parse(event.data);
        if (data.type !== 'WA_EMBEDDED_SIGNUP') {
          return;
        }

        setIsProcessing(true);
        await getEmbeddedSignupListener(embeddedSignupCallback)(event);
      } catch (error) {
        logger.error('Error processing Facebook embedded signup:', { error });
      } finally {
        setIsProcessing(false);
      }
    },
    [embeddedSignupCallback]
  );

  const deactivateWhatsApp = useCallback(async () => {
    deactivateWhatsAppCallback();
  }, [deactivateWhatsAppCallback]);

  const whatsAppButtonClicked = useCallback(() => {
    if (status === WhatsappRegistrationState.ONLINE) {
      deactivateWhatsApp();
    } else {
      launchEmbeddedSignup(isTwilioSMSCapable);
    }
  }, [deactivateWhatsApp, isTwilioSMSCapable, status]);

  useEffect(() => {
    loadFacebookSDKScript()
      .then(() => {
        setEnableFacebookButton(status !== WhatsappRegistrationState.CREATING);
      })
      .catch(() => {
        setEnableFacebookButton(false);
      });
  }, [status]);

  useEffect(() => {
    window.addEventListener('message', embeddedSignupListener);
    return () => {
      window.removeEventListener('message', embeddedSignupListener);
    };
  }, [embeddedSignupListener]);

  return (
    <>
      <Button
        disabled={!enableFacebookButton || isProcessing}
        onClick={whatsAppButtonClicked}
        data-test='facebook-button'
        variant={status === WhatsappRegistrationState.ONLINE ? 'critical' : 'primary'}
      >
        {status === WhatsappRegistrationState.ONLINE
          ? t('number_details.integrations.whatsapp_integration.deactivate_button')
          : t('number_details.integrations.whatsapp_integration.facebook_button')}
        {isProcessing && <Loading size={24} data-test='loading-spinner' />}
      </Button>
      <Button
        as={Link}
        data-test='learn-more-button'
        href={t('number_details.integrations.whatsapp_integration.learn_more_link')}
        target='_blank'
        variant='secondary'
        mode='link'
      >
        {t('number_details.integrations.whatsapp_integration.learn_more_button')}
      </Button>
    </>
  );
}
