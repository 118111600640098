import { useCallback, useEffect, useState } from 'react';

import { LineTypeFilterProps } from './LineTypeFilter.decl';

import { Typography } from '@aircall/tractor-v2';
import {
  capitalizeFirstLetter,
  LINE_TYPE_VALUES,
  MultiSelect,
  MultiSelectOption,
} from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function LineTypeFilter({ onSelect, initialTypes }: LineTypeFilterProps) {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<LINE_TYPE_VALUES[]>([]);
  const options = [
    { label: t('numbers.filters.type.ivr'), value: LINE_TYPE_VALUES.IVR },
    { label: t('numbers.filters.type.classic'), value: LINE_TYPE_VALUES.CLASSIC },
    { label: t('numbers.filters.type.smartflow'), value: LINE_TYPE_VALUES.SMARTFLOW },
    { label: t('numbers.filters.type.sms'), value: LINE_TYPE_VALUES.SMS },
    { label: t('numbers.filters.type.mms'), value: LINE_TYPE_VALUES.MMS },
    { label: t('numbers.filters.type.whatsapp'), value: LINE_TYPE_VALUES.WA_MSG },
  ];

  function handleSelect(keys: LINE_TYPE_VALUES[]) {
    setSelectedKeys(keys);
    onSelect(keys);
  }

  useEffect(() => {
    setSelectedKeys(initialTypes ?? []);
  }, [initialTypes]);

  const renderItemSuffix = useCallback(
    (option: MultiSelectOption<LINE_TYPE_VALUES>) => {
      if ([LINE_TYPE_VALUES.IVR, LINE_TYPE_VALUES.CLASSIC].includes(option.value)) {
        return (
          <Typography variant='bodyRegularS' fontStyle='italic' color='neutral-300'>
            {t('numbers.filters.type.legacy')}
          </Typography>
        );
      }
      return null;
    },
    [t]
  );

  return (
    <MultiSelect<MultiSelectOption<LINE_TYPE_VALUES>>
      data-test='line-type-filter'
      selectedKeys={selectedKeys}
      defaultSelectedKeys={initialTypes || undefined}
      minWidth='160px'
      options={options}
      onSelect={handleSelect}
      renderItemSuffix={renderItemSuffix}
      texts={{
        placeholder: capitalizeFirstLetter(t('generic.type')),
        item: t('generic.type'),
        items: t('generic.types'),
      }}
    />
  );
}
