import { Spacer, Tag, Typography } from '@aircall/tractor-v2';
import { LineCapabilitiesIcon } from '@components/LineCapabilitiesIcon/LineCapabilitiesIcon';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';
import { formatInternational } from '@helpers/phoneNumber.helpers';
import { useTranslation } from 'react-i18next';

export function ColumnFieldNumbers({
  activationState,
  capabilities,
  isIVR,
  phoneNumber,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const { t } = useTranslation();
  const isActive = isNumberActive(activationState);

  return (
    <Spacer direction='horizontal' space='xxs' alignItems='center'>
      <Typography variant='bodyRegularS' color={isActive ? 'neutral-600' : 'neutral-300'}>
        {formatInternational(phoneNumber)}
      </Typography>
      <LineCapabilitiesIcon capabilities={capabilities} />
      {isIVR && (
        <Tag size='small' variant='white' mode='outline'>
          {t('numbers.table.number.ivr_tag')}
        </Tag>
      )}
    </Spacer>
  );
}
