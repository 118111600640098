import { useEffect } from 'react';

import { Button, Flex, ThemeProvider, Typography } from '@aircall/tractor-v2';
import aircall_text_logo from '@assets/icons/aircall_text_logo.svg';
import kyc_verification_logo from '@assets/images/kyc_verification_logo.svg';
import { useHandleButtonLink } from '@components/KycBanner';
import { ONBOARDING_ROUTE } from '@constants/routes.constants';
import { Loading, LoadingButton } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled(Flex)`
  width: 479px;
  height: 510px;
`;

const IconContainer = styled(Flex)`
  width: auto;
  height: 40px;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
`;

const GradientLayout = styled(Flex)`
  background: radial-gradient(126.12% 126.12% at 50.91% 173.88%, #97f1d5 0%, #fff 100%);
  overflow-y: auto;
`;

function KycVerificationPage() {
  const { dashboardEnableStripeKyc } = useFeaturesFlags();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleButtonLink, loading, stripe } = useHandleButtonLink();

  const handleInitiateLater = () => {
    navigate(ONBOARDING_ROUTE, { replace: true });
  };

  useEffect(() => {
    if (!dashboardEnableStripeKyc) {
      navigate(ONBOARDING_ROUTE, { replace: true });
    }
  }, [navigate, dashboardEnableStripeKyc]);

  if (!dashboardEnableStripeKyc) {
    return <Loading data-test='generic-page-loader' />;
  }

  return (
    <ThemeProvider>
      <GradientLayout
        w='100%'
        h='100%'
        p='l'
        data-test='kyc-verification-page-wrapper'
        flexDirection='column'
        gap='xxl'
      >
        <IconContainer>
          <img src={aircall_text_logo} alt='aircall_text_logo' />
        </IconContainer>
        <CardContainer
          position='relative'
          m='auto'
          data-test='content-wrapper'
          flexDirection='column'
        >
          <Flex flexDirection='column' alignItems='center' gap='xxs' pb='m'>
            <img src={kyc_verification_logo} alt='keys_and_message' />
            <Typography variant='headingBoldS' w='500px' textAlign='center'>
              {t('stripe.kyc.verification_page.title')}
            </Typography>
          </Flex>
          <Flex flexDirection='column' alignItems='center' gap='m'>
            <Typography variant='bodyRegularM' color='text-secondary' textAlign='center'>
              {t('stripe.kyc.verification_page.description')}
            </Typography>
            <Flex gap='m'>
              <Button
                variant='primary'
                size='large'
                mode='outline'
                shape='circle'
                data-test='initiate-later-button'
                onClick={handleInitiateLater}
              >
                {t('stripe.kyc.verification_page.initiate_later')}
              </Button>
              <LoadingButton
                variant='primary'
                size='large'
                mode='fill'
                shape='circle'
                disabled={!stripe || loading}
                data-test='initiate-kyc-button'
                onClick={handleButtonLink}
                loading={loading}
              >
                {t('stripe.kyc.verification_page.initiate_kyc')}
              </LoadingButton>
            </Flex>
          </Flex>
        </CardContainer>
      </GradientLayout>
    </ThemeProvider>
  );
}

export default KycVerificationPage;
