import {
  Flex,
  ModalBody,
  Button,
  ModalClose,
  ModalDialog,
  ModalFooter,
  Typography,
  Spacer,
} from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export interface DeactivateWhatsAppConfirmationModalProps {
  show: boolean;
  onDeactivateClick: () => void;
  onCancelClick: () => void;
}

export function DeactivateWhatsAppConfirmationModal({
  show,
  onDeactivateClick,
  onCancelClick,
}: Readonly<DeactivateWhatsAppConfirmationModalProps>) {
  const { t } = useTranslation();

  return (
    <ModalDialog show={show} onHide={onCancelClick} size='small'>
      <ModalClose />
      <ModalBody px='m' pb={24}>
        <Flex flexDirection='column' alignItems='center'>
          <Typography variant='headingBoldXS' textAlign='center'>
            {t('number_details.integrations.whatsapp_integration.deactivate_modal.title')}
          </Typography>
          <Typography pt='s'>
            {t(
              'number_details.integrations.whatsapp_integration.deactivate_modal.confirmation_text'
            )}
          </Typography>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Spacer space='xxs' alignItems='center'>
          <Button
            mode='ghost'
            variant='secondary'
            onClick={onCancelClick}
            data-test='modal-cancel-deactivate-button'
          >
            {t('number_details.integrations.whatsapp_integration.deactivate_modal.cancel')}
          </Button>
          <Button onClick={onDeactivateClick} data-test='modal-confirm-deactivate-button'>
            {t('number_details.integrations.whatsapp_integration.deactivate_modal.deactivate')}
          </Button>
        </Spacer>
      </ModalFooter>
    </ModalDialog>
  );
}
