import { useMemo, useState } from 'react';

import { logger } from '@config/logger.config';
import { ClientError } from '@helpers/errors.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { SDR_VERIFICATION_STATES, STRIPE_KYC_STATES, useKycStatus } from '@hooks/useKycStatus';

export enum KYC_VERIFICATION_BANNER_STATES {
  NO_SHOW = 'NO_SHOW',
  NO_VERIFY_NO_ATTEMPT = 'NO_VERIFY_NO_ATTEMPT',
  VERIFICATION_IN_PROCESS = 'VERIFICATION_IN_PROCESS',
  VERIFICATION_UNSUCCESSFUL = 'VERIFICATION_UNSUCCESSFUL',
  VERIFICATION_REJECTED_FINAL = 'VERIFICATION_REJECTED_FINAL',
  USER_VERIFIED = 'USER_VERIFIED',
  POPUP_CLOSED = 'POPUP_CLOSED',
}

// For backward compatibility
export const StripeKycVerificationStates = KYC_VERIFICATION_BANNER_STATES;

/*
This hook contains the main logic to determine the state of the KYC verification banner. It is complex as the process itself.

To better understand the logic, here are a couple links to the relevant documentation:
https://aircall-product.atlassian.net/wiki/spaces/FR/pages/3457581071/UX+UI+Requirements+-+March+2025
https://aircall-product.atlassian.net/wiki/spaces/FR/pages/3407347776/KYC+Sequence+Diagrams?force_transition=a8e0e217-b324-429b-b6e5-fa523377d869

Here's some useful things to know:
- automatedOnboardingKycStatus is the state of STRIPE's verification process
- sdrVerificationStatus is the state of Aircall's verification process executed by SDRs
- totalAttempts is the number of attempts made by the user to complete the Stripe verification
- kycMaxAttemptsAllowed is the maximum number of attempts allowed for the user to complete the Stripe verification
*/
export const useStripeKycBanner = (): {
  verificationState: KYC_VERIFICATION_BANNER_STATES;
  handleClose: () => void;
  handleReopen: () => void;
  loading: boolean;
  error: ClientError | undefined;
} => {
  const { dashboardEnableStripeKyc } = useFeaturesFlags();
  const { currentUser } = useGlobalData();
  const [bannerIsClosed, setBannerIsClosed] = useState(false);

  const { data, loading, error } = useKycStatus(currentUser.companyID, Number(currentUser.ID));

  const verificationState = useMemo<KYC_VERIFICATION_BANNER_STATES>(() => {
    // Turn off KYC lock banner if feature flag is off or there is an error
    if (!dashboardEnableStripeKyc || loading || error) {
      if (error) {
        logger.error('Error fetching KYC status', { error });
      }
      return KYC_VERIFICATION_BANNER_STATES.NO_SHOW;
    }

    // If there is no data, it means the user verification must not be shown
    if (!data) {
      logger.info('No KYC status data found, skipping banner', {
        companyId: currentUser.companyID,
        userId: currentUser.ID,
      });
      return KYC_VERIFICATION_BANNER_STATES.NO_SHOW;
    }

    if (bannerIsClosed) {
      return KYC_VERIFICATION_BANNER_STATES.POPUP_CLOSED;
    }
    const {
      automatedOnboardingKycStatus,
      sdrVerificationStatus,
      totalAttempts,
      kycMaxAttemptsAllowed,
    } = data;

    // Early return for Stripe processing
    if (automatedOnboardingKycStatus === STRIPE_KYC_STATES.PROCESSING) {
      return KYC_VERIFICATION_BANNER_STATES.VERIFICATION_IN_PROCESS;
    }

    // Helper function to determine state based on Stripe status and attempts
    const getStripeBasedState = () => {
      if (totalAttempts === 0) {
        return KYC_VERIFICATION_BANNER_STATES.NO_VERIFY_NO_ATTEMPT;
      }

      if (totalAttempts >= kycMaxAttemptsAllowed) {
        return KYC_VERIFICATION_BANNER_STATES.VERIFICATION_REJECTED_FINAL;
      }

      return KYC_VERIFICATION_BANNER_STATES.VERIFICATION_UNSUCCESSFUL;
    };

    // Define state mappings for different SDR verification states
    const stateMap: Record<SDR_VERIFICATION_STATES, () => KYC_VERIFICATION_BANNER_STATES> = {
      [SDR_VERIFICATION_STATES.PENDING]: () => {
        if (automatedOnboardingKycStatus === STRIPE_KYC_STATES.VERIFIED) {
          return KYC_VERIFICATION_BANNER_STATES.VERIFICATION_IN_PROCESS;
        }
        return getStripeBasedState();
      },
      [SDR_VERIFICATION_STATES.VERIFIED]: () => {
        if (automatedOnboardingKycStatus === STRIPE_KYC_STATES.VERIFIED) {
          return KYC_VERIFICATION_BANNER_STATES.USER_VERIFIED;
        }
        return totalAttempts === 0
          ? KYC_VERIFICATION_BANNER_STATES.NO_VERIFY_NO_ATTEMPT
          : KYC_VERIFICATION_BANNER_STATES.USER_VERIFIED;
      },
      [SDR_VERIFICATION_STATES.REJECTED]: () => {
        if (totalAttempts === 0) {
          return KYC_VERIFICATION_BANNER_STATES.NO_VERIFY_NO_ATTEMPT;
        }
        return KYC_VERIFICATION_BANNER_STATES.VERIFICATION_REJECTED_FINAL;
      },
    };

    return stateMap[sdrVerificationStatus as SDR_VERIFICATION_STATES]();
  }, [
    bannerIsClosed,
    dashboardEnableStripeKyc,
    data,
    loading,
    error,
    currentUser.ID,
    currentUser.companyID,
  ]);

  const handleClose = () => {
    setBannerIsClosed(true);
  };

  const handleReopen = () => {
    setBannerIsClosed(false);
  };

  return {
    verificationState,
    handleClose,
    handleReopen,
    loading,
    error,
  };
};
