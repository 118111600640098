import { useEffect } from 'react';

import { Loading, useGraphQuery } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { GetApplicationInstallUriQuery } from '@generated/GetApplicationInstallUriQuery';
import { GET_APPLICATION_INSTALL_URI_QUERY } from '@graphql/queries/GetApplicationInstallUriQuery';
import { convertSnakeToTitle } from '@helpers/integrations.helpers';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useParams } from 'react-router-dom';

export interface PusherHubIntegrationEvent {
  id: number;
  logo: string;
  name: string;
  active: boolean;
  status: string;
  application_id: number; // eslint-disable-line
  company_id: number; // eslint-disable-line
  created_at: string; // eslint-disable-line
  custom_name: string | null; // eslint-disable-line
  deleted_at: string | null; // eslint-disable-line
  number_ids: []; // eslint-disable-line
  numbers_count: number; // eslint-disable-line
  updated_at: string; // eslint-disable-line
}

interface IntegrationFlowInstallPartnerProps {
  application: ApplicationsListQuery_getApplications;
}

export function IntegrationFlowInstallPartner({ application }: IntegrationFlowInstallPartnerProps) {
  const { applicationName, applicationType, applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const { track } = useTracker();
  const { data } = useGraphQuery<GetApplicationInstallUriQuery>(GET_APPLICATION_INSTALL_URI_QUERY, {
    variables: {
      applicationID: application.id,
    },
  });

  useEffect(() => {
    if (data?.getApplicationInstallUri?.installUri) {
      track({
        event: 'integration_installation_funnel_started',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        payload: { application_name: convertSnakeToTitle(applicationName) },
      });

      window.location.href = data?.getApplicationInstallUri?.installUri;
    }
  }, [data, navigate, applicationId, applicationName, applicationType, track]);

  return <Loading data-test='loading-partner-flow-2' />;
}
