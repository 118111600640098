import { PeopleFilled } from '@aircall/icons';
import { Icon, Tag, Tooltip, Typography } from '@aircall/tractor-v2';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';
import { useTranslation } from 'react-i18next';

export function ColumnFieldUsers({
  agentsCounter,
  isIVR,
  activationState,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const { t } = useTranslation();
  const isActive = isNumberActive(activationState);

  return isIVR || agentsCounter === 0 ? (
    <Tooltip title={t('numbers.table.users.no_users_tooltip')}>
      <Tag variant='red' size='small'>
        <Icon component={PeopleFilled} size={16} /> 0
      </Tag>
    </Tooltip>
  ) : (
    <Typography data-test='agent-count-data' color={isActive ? 'neutral-600' : 'neutral-300'}>
      {agentsCounter}
    </Typography>
  );
}
