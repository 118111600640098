import { Fragment, useMemo } from 'react';

import { IntegrationSidenavBadge } from '../IntegrationSidenavBadge/IntegrationSidenavBadge';
import { StatsAnalyticsPlusAdItem } from '../StatsAnalyticsPlusAdItem/StatsAnalyticsPlusAdItem';

import { SIDENAV_ITEMS_ADDONS_CONFIG } from './SidenavMain.constants';
import { MainRoutesProps, SidenavMainProps } from './SidenavMain.decl';
import { SidenavDropdownContainer, SidenavItemLi } from './SidenavMain.styles';
import {
  getItemLabelAndPosition,
  isAddonRouteRestricted,
  isParentRouteActive,
  isRouteRestricted,
} from './SidenavMain.utils';

import {
  Flex,
  SidenavDropdownItem,
  SidenavDropdownItemLabel,
  SidenavDropdownSeparator,
  SidenavItem,
  SidenavItemIndicator,
  SidenavItemProps,
  SidenavItemTitle,
} from '@aircall/tractor-v2';
import { ReactComponent as LockCircledIcon } from '@assets/icons/lock_circled_icon.svg';
import { ReactComponent as ActivityFeedIcon } from '@assets/icons/sidenav/activity_feed.svg';
import { ReactComponent as AnalyticsIcon } from '@assets/icons/sidenav/analytics.svg';
import { ReactComponent as CallSettingsIcon } from '@assets/icons/sidenav/call_settings.svg';
import { ReactComponent as ConversationCenterIcon } from '@assets/icons/sidenav/conversation_center.svg';
import { ReactComponent as IntegrationsIcon } from '@assets/icons/sidenav/integrations.svg';
import { ReactComponent as LiveMonitoringIcon } from '@assets/icons/sidenav/live_monitoring.svg';
import { ReactComponent as NumbersIcon } from '@assets/icons/sidenav/numbers.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/sidenav/users.svg';
import { SIDENAV_ITEMS_CONFIG, SidenavItemConfig } from '@components/Sidenav/Sidenav.config';
import { StyledHomeItem } from '@components/Sidenav/Sidenav.styles';
import { formatToSidebarItem, sortByPosition } from '@components/Sidenav/Sidenav.utils';
import {
  INTEGRATIONS_CHILD_ROUTE,
  TEAMS_ROUTE,
  HOME_ROUTE,
  USERS_AND_TEAMS_GROUP,
  USERS_ROUTE,
} from '@constants/routes.constants';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import { getActiveAddonTrialByName } from '@hooks/useCompanyAddons/utils';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import { useAdminFeatures } from '@hooks/useFeatures/useAdminFeatures';
import { AdminFeatures } from '@hooks/useFeatures/useAdminFeatures.decl';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { FeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags.decl';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';
import { useModuleFederationContext } from '@hooks/useModuleFederationContext/useModuleFederationContext';
import { usePermissions } from '@hooks/usePermissions';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

/**
 * Needed as we have not added these icons yet to `@aircall/icons` package,
 * nor to their respective extensions.
 */
/* istanbul ignore next */
function getRouteIcon({ to, icon: Icon }: SidenavItemConfig) {
  switch (to) {
    case '/numbers':
      return <NumbersIcon />;
    case '/conversations':
      return <ConversationCenterIcon />;
    case '/stats':
      return <AnalyticsIcon />;
    case '/live_monitoring/calls':
    case '/live_monitoring_plus/calls':
      return <LiveMonitoringIcon />;
    case '/activity_feed':
      return <ActivityFeedIcon />;
    case '/users':
      return <UsersIcon />;
    case '/integrations/connected-integrations':
      return <IntegrationsIcon />;
    case '/calls':
      return <CallSettingsIcon />;
    default:
      return <Icon />;
  }
}

/**
 * The `end: true` prop indicates that the link will only be
 * "active" if its route is strictly equal to the current path.
 */
const NavLinkStrictActive = styled(NavLink).attrs({ end: true })``;

const StyledSidenavDropdownContainer = styled(SidenavDropdownContainer)`
  max-height: 380px;
`;

function MainRoutes({ sidenavItems, expanded }: Readonly<MainRoutesProps>) {
  const featuresFlags = useFeaturesFlags();
  const adminFeatures = useAdminFeatures();
  const features = useMemo<FeaturesFlags & AdminFeatures>(
    () => ({ ...featuresFlags, ...adminFeatures }),
    [adminFeatures, featuresFlags]
  );
  const { t } = useTranslation();
  const { checkAccess } = usePermissions();
  const { currentCompany } = useDashboardExtensionContext();
  const { currentUser } = useCurrentUser();

  const location = useLocation();
  const currentPath = location.pathname;

  const { addons } = useCompanyAddons();
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();

  return (
    <>
      {sidenavItems.map((item) => {
        if (!item.enabled(features, currentCompany, currentUser) || item.hidden?.(features)) {
          return null;
        }

        const addonConfig = SIDENAV_ITEMS_ADDONS_CONFIG[item.to];

        const isAddonActive = Boolean(
          addonConfig && getActiveAddonTrialByName(addonConfig.addonName, addons, isAddonEnabled)
        );
        const itemRestricted = isRouteRestricted(item, checkAccess);

        const commonSidenavItemProps: SidenavItemProps = {
          indicator: (
            <SidenavItemIndicator color='icon-interactive-secondary'>
              {getRouteIcon(item)}
            </SidenavItemIndicator>
          ),
          title: <SidenavItemTitle>{t(item.title)}</SidenavItemTitle>,
          tooltip: t(item.title),
          expanded,
          restricted: itemRestricted,
          badge: isAddonActive ? addonConfig.badge : null,
          'data-test': item.dataTest,
          component: NavLink,
          to: item.to,
          active: isParentRouteActive(item, currentPath),
        };

        if (!item.children?.length) {
          return (
            <SidenavItemLi key={item.key}>
              {item.key === HOME_ROUTE ? (
                <StyledHomeItem {...commonSidenavItemProps} />
              ) : (
                <SidenavItem
                  {...commonSidenavItemProps}
                  {...(item.to === INTEGRATIONS_CHILD_ROUTE.CONNECTED_INTEGRATIONS && {
                    badge: <IntegrationSidenavBadge />,
                  })}
                />
              )}
            </SidenavItemLi>
          );
        }

        return (
          <SidenavItemLi key={item.key}>
            <StyledSidenavDropdownContainer {...commonSidenavItemProps} component='button'>
              {item.children.map((child) => {
                const hasSeparator = Boolean(child.hasSeparator?.(features));

                if (
                  !child.enabled(features, currentCompany, currentUser) ||
                  (child.display && !child.display?.(features)) ||
                  child.hidden?.(features)
                ) {
                  if (hasSeparator) {
                    return <SidenavDropdownSeparator key={child.key} />;
                  }

                  return null;
                }

                if (child.key === 'statsAnalyticsPlusAd') {
                  return <StatsAnalyticsPlusAdItem key={child.key} dataTest={child.dataTest} />;
                }

                const isChildPartOfAddon = Boolean(
                  isAddonActive && addonConfig.includedChildren?.includes(child.to)
                );

                const isChildRestricted =
                  itemRestricted ||
                  isRouteRestricted(child, checkAccess) ||
                  isAddonRouteRestricted(child, featuresFlags);

                const isChildParentRoute = item.to === child.to;

                return (
                  <Fragment key={child.key}>
                    <SidenavDropdownItem
                      to={child.to}
                      active='current-page'
                      component={isChildParentRoute ? NavLinkStrictActive : NavLink}
                      data-test={child.dataTest}
                    >
                      <Flex justifyContent='space-between' alignItems='center' flex={1} gap='xxs'>
                        <SidenavDropdownItemLabel>{t(child.title)}</SidenavDropdownItemLabel>
                        {isChildPartOfAddon && !isChildRestricted ? (
                          <Flex
                            alignItems='center'
                            justifyContent='center'
                            data-test={`${child.dataTest}-add-on-badge`}
                          >
                            {addonConfig.badge}
                          </Flex>
                        ) : null}
                        {isChildRestricted ? (
                          <Flex
                            alignItems='center'
                            justifyContent='center'
                            data-test={`${child.dataTest}-lock-badge`}
                          >
                            <LockCircledIcon />
                          </Flex>
                        ) : null}
                      </Flex>
                    </SidenavDropdownItem>
                    {hasSeparator && <SidenavDropdownSeparator />}
                  </Fragment>
                );
              })}
            </StyledSidenavDropdownContainer>
          </SidenavItemLi>
        );
      })}
    </>
  );
}

export function SidenavMain({ expanded }: Readonly<SidenavMainProps>) {
  const { menuItems: extensionsMenuItems } = useModuleFederationContext();

  const sidenavItems = useMemo(() => {
    const extensionMenuItemsParsed = extensionsMenuItems.map<SidenavItemConfig>((item) =>
      formatToSidebarItem({ ...item, ...getItemLabelAndPosition(item.to) })
    );

    const sortedItems = sortByPosition([
      ...extensionMenuItemsParsed,
      ...Object.values(SIDENAV_ITEMS_CONFIG),
    ]);

    return sortedItems.reduce<SidenavItemConfig[]>((items, item) => {
      /**
       * We group users and teams routes
       */
      if (item.key === USERS_AND_TEAMS_GROUP) {
        const usersAndTeams = sortedItems.filter((sortedItem) =>
          item.groupedRoutes?.includes(sortedItem.to)
        );

        return [...items, { ...item, children: usersAndTeams }];
      }
      if (item.to === USERS_ROUTE || item.to === TEAMS_ROUTE) {
        return [...items];
      }

      return [...items, item];
    }, []);
  }, [extensionsMenuItems]);

  return <MainRoutes expanded={expanded} sidenavItems={sidenavItems} />;
}
