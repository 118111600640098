import { useState, useEffect } from 'react';

import { computeIsOpen } from './utils';

import { Spacer, Box, Typography, Skeleton, Flex } from '@aircall/tractor-v2';
import { useGraphQuery } from '@dashboard/library';
import { LineState } from '@generated/globalTypes';
import {
  LineBusinessHoursQuery,
  LineBusinessHoursQueryVariables,
} from '@generated/LineBusinessHoursQuery';
import { LINE_BUSINESS_HOURS_QUERY } from '@graphql/queries/LineBusinessHoursQuery';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';

export const getStateText = (state: LineState, isOpen: boolean): string => {
  const text = `business_hours.lines.${state}`;
  switch (state) {
    case LineState.ALWAYS_OPENED:
    case LineState.ALWAYS_CLOSED:
      return text;
    case LineState.AUTO:
    default:
      return `${text}.${isOpen ? 'open' : 'closed'}`;
  }
};

const RECOMPUTE_INTERVAL = 5000;

type RealtimeBusinessHourStateProps = {
  state: LineState | null;
  lineID: string;
  flowEditorEnabled: boolean | null;
};

type LineStateLabelProps = { isOpen: boolean; state: LineState };

function LineStateLabel({ isOpen, state }: LineStateLabelProps) {
  const { t } = useTranslation();

  return (
    <Spacer space='xxs' alignItems='center' direction='horizontal' data-test='number-state-label'>
      <Box
        h={8}
        w={8}
        borderRadius='50%'
        backgroundColor={isOpen ? 'success-300' : 'critical-500'}
      />
      <Typography variant='bodyMediumS' color='neutral-400'>
        {t(getStateText(state, isOpen))}
      </Typography>
    </Spacer>
  );
}

function CustomSkeleton() {
  return (
    <Flex alignItems='center' data-test='custom-skeleton'>
      <Skeleton w={8} h={8} shape='circle' mr='xxs' />
      <Skeleton w={40} h={15} />
    </Flex>
  );
}

/**
 * Fetch businessHours by lineID and compute isOpen every 5 sec
 */
export function CustomBusinessHourState({ lineID }: { lineID: string }) {
  const [isOpen, setIsOpen] = useState<undefined | boolean>();
  const { data, loading } = useGraphQuery<LineBusinessHoursQuery, LineBusinessHoursQueryVariables>(
    LINE_BUSINESS_HOURS_QUERY,
    {
      variables: { lineID },
      throwError: false,
    }
  );

  useEffect(() => {
    // update isOpen when businessHours is fetched or updated
    if (data?.getLineBusinessHours) {
      setIsOpen(computeIsOpen(data.getLineBusinessHours));
    }
  }, [data]);

  // update isOpen every 5 seconds
  useInterval(
    () => {
      setIsOpen(computeIsOpen(data!.getLineBusinessHours!));
    },
    data?.getLineBusinessHours ? RECOMPUTE_INTERVAL : null
  );

  if (isOpen !== undefined) {
    return <LineStateLabel state={LineState.AUTO} isOpen={isOpen} />;
  }
  if (loading) {
    return <CustomSkeleton />;
  }
  // return null in case of error
  return null;
}

export function RealtimeBusinessHourState({
  state,
  lineID,
  flowEditorEnabled,
}: RealtimeBusinessHourStateProps) {
  const hasCustomAvailability = state === 'AUTO' && !flowEditorEnabled;

  if (hasCustomAvailability) {
    return <CustomBusinessHourState lineID={lineID} />;
  }
  return (
    <LineStateLabel
      state={state as LineState}
      isOpen={state === 'ALWAYS_OPENED' || !!flowEditorEnabled}
    />
  );
}
