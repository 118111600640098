import React, { ComponentType, useMemo, useEffect, useState } from 'react';

import { applyPlaceholderErrorBoundary } from './FailSafePlaceholder';
import {
  ModuleFederationContext,
  ModuleFederationContextType,
} from './ModuleFederationContext.decl';

import { logger } from '@config/logger.config';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import {
  Route,
  PlaceholderComponent,
  DashboardExtension,
  Wrapper,
  DashboardExtensionContextInterface,
  MenuItem,
  useDashboardExtensionContext,
  PlaceholderContextProvider,
  PlaceholderComponentProps,
  LayoutType,
  WrapperProps,
} from '@dashboard/extension';
import { usePermissions } from '@hooks/usePermissions';
import camelCase from 'camelcase';

function encapsulateWrappers(wrappers: ComponentType<WrapperProps>[]): ComponentType {
  return wrappers.reduce(
    (Component: ComponentType, NextComponent: ComponentType) =>
      /* istanbul ignore next  */
      function aggFct({ children }) {
        return (
          <Component>
            <NextComponent>{children}</NextComponent>
          </Component>
        );
      },
    React.Fragment
  );
}

/**
 * This function tries to fetch resources from module federation extensions.
 *
 * @param context - module federation context. this will be used in the module's function
 * @param extensionName - the name of the module we're trying to fetch resources from
 * @param defaultValue  - a default value (usually empty) related to the type of resource
 * @param getResourceFunc - a function that takes the context and returns the resource
 * @returns the resource from the module or the default value
 */
async function fetchResource<Type>(
  context: DashboardExtensionContextInterface,
  extensionName: string,
  defaultValue: Type,
  getResourceFunc?: (context: DashboardExtensionContextInterface) => Promise<Type>
) {
  try {
    if (getResourceFunc) {
      const resource = await getResourceFunc(context);
      return resource;
    }
  } catch (error) {
    logger.error((error as Error).message, { error, extensionName });
  }
  return defaultValue;
}

async function fetchModules(
  context: DashboardExtensionContextInterface,
  federatedModules: Record<string, { module: Promise<{ default: DashboardExtension }> }>,
  checkAccess: (action: PERMISSIONS_ACTIONS, _resource?: RESOURCE) => boolean
) {
  const routes: Route[] = [];
  const menuItems: MenuItem[] = [];
  let placeholderComponents: Record<string, React.ComponentType<PlaceholderComponentProps>> = {};
  const wrappers: ComponentType<WrapperProps>[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const extensionName of Object.keys(federatedModules)) {
    try {
      const featureFlagName = camelCase(`dashboard_extension_enable_${extensionName}`);
      const isFFEnabled = !!context.featureFlags[featureFlagName];

      /* istanbul ignore else */
      if (isFFEnabled) {
        const result = (await federatedModules[extensionName].module).default;
        const { getRoutes, getMenuItems, getPlaceholders, getExtensionWrapper } = result;
        const moduleRoutes = await fetchResource<Route[]>(context, extensionName, [], getRoutes);
        const moduleMenuItems = await fetchResource<MenuItem[]>(
          context,
          extensionName,
          [],
          getMenuItems
        );

        // routes permission handled in PrivateRoutes
        routes.push(...moduleRoutes);

        // menuItems permission handled in sidebar items (add lock icon)
        menuItems.push(...moduleMenuItems);

        const modulePlaceholders = await fetchResource<PlaceholderComponent[]>(
          context,
          extensionName,
          [],
          getPlaceholders
        );

        const moduleWrapper = await fetchResource<Wrapper | null>(
          context,
          extensionName,
          null,
          getExtensionWrapper
        ).then((wrapper) => {
          if (wrapper?.permissionResource?.length) {
            // check for permission
            const permissionOk = !wrapper?.permissionResource.some(
              (resource) => !checkAccess(PERMISSIONS_ACTIONS.READ, resource as RESOURCE)
            );
            /* istanbul ignore next */
            // hard to test right now. reworking the tests to make it easier to achieve
            if (permissionOk) {
              return wrapper;
            }
            return null;
          }
          return wrapper;
        });

        const filterPlaceholders = ({ permissionResource }: PlaceholderComponent) => {
          if (permissionResource?.length) {
            return !permissionResource.some(
              (resource) => !checkAccess(PERMISSIONS_ACTIONS.READ, resource as RESOURCE)
            );
          }
          return true;
        };
        placeholderComponents = {
          ...placeholderComponents,
          // filter by permission
          ...modulePlaceholders.filter(filterPlaceholders).reduce(
            (acc, placeholder) => ({
              ...acc,
              [placeholder.componentName]: applyPlaceholderErrorBoundary(
                extensionName,
                placeholder.component
              ),
            }),
            {}
          ),
        };
        const wrapperComponent = moduleWrapper?.component;
        if (wrapperComponent) {
          wrappers.push(wrapperComponent);
        }
      }
    } catch (error) {
      logger.error((error as Error).message, { error, extensionName });
    }
  }

  return {
    adminRoutesDefault: routes.filter(({ layout }) => layout !== LayoutType.EMPTY_LAYOUT),
    adminRoutesEmpty: routes.filter(({ layout }) => layout === LayoutType.EMPTY_LAYOUT),
    agentRoutesDefault: routes.filter(({ layout }) => layout !== LayoutType.EMPTY_LAYOUT),
    agentRoutesEmpty: routes.filter(({ layout }) => layout === LayoutType.EMPTY_LAYOUT),
    menuItems,
    placeholderComponents,
    wrappers,
  };
}

export interface ModuleFederationProviderProps {
  children: React.ReactNode;
}

export function ModuleFederationProvider({ children }: ModuleFederationProviderProps) {
  const [modulesState, setModulesState] = useState<ModuleFederationContextType>({
    adminRoutesDefault: [],
    adminRoutesEmpty: [],
    agentRoutesDefault: [],
    agentRoutesEmpty: [],
    menuItems: [],
    wrappers: [],
    placeholderComponents: {},
  });
  const [loading, setLoading] = useState(true);
  const context = useDashboardExtensionContext();
  const { checkAccess } = usePermissions();

  useEffect(() => {
    // eslint-disable-next-line import/no-unresolved
    import('@generated/federatedModules').then(({ federatedModules }) => {
      fetchModules(context, federatedModules, checkAccess).then((data) => {
        setModulesState(data);
        setLoading(false);
      });
    });
  }, [
    checkAccess,
    context.currentUser.language,
    context.featureFlags,
    context.hasDashboardAccess,
    context.permissions,
  ]);

  const EncapsulatedWrappers = useMemo(
    () => encapsulateWrappers(modulesState.wrappers),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modulesState.wrappers.length]
  );
  const value = useMemo(() => ({ ...modulesState, loading }), [loading, modulesState]);

  return (
    <ModuleFederationContext.Provider value={value}>
      <PlaceholderContextProvider value={modulesState.placeholderComponents}>
        <EncapsulatedWrappers>{children}</EncapsulatedWrappers>
      </PlaceholderContextProvider>
    </ModuleFederationContext.Provider>
  );
}
