import { MouseEventHandler, useCallback } from 'react';

import { ChevronRightOutlined, TickCircleOutlined } from '@aircall/icons';
import { Button, Icon } from '@aircall/tractor-v2';
import { NUMBER_DETAIL_ROUTE } from '@constants/routes.constants';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export function ColumnFieldCallDistribution({
  flowEditorEnabled,
  activationState,
  ID,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const isActive = isNumberActive(activationState);
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const handleUpgradeNumber: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      navigate(NUMBER_DETAIL_ROUTE, { numberId: ID });
    },
    [ID, navigate]
  );

  return flowEditorEnabled ? (
    <TickCircleOutlined
      data-test='flow-editor-enabled-icon'
      color={isActive ? 'default' : 'neutral-700'}
    />
  ) : (
    <Button
      mode='link'
      size='xSmall'
      onClick={handleUpgradeNumber}
      data-test='number-upgrade-link-button'
      variant='critical'
    >
      {t('numbers.table.call_distribution.update_action')}
      <Icon component={ChevronRightOutlined} size={16} />
    </Button>
  );
}
