import styled from 'styled-components';

import { UpgradeButtonProps } from './UpgradeButton.decl';

import { Button, getColor } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  color: ${getColor('informative-800')};
  padding: 0;
  font-weight: 700;

  &:hover,
  &:focus {
    color: ${getColor('informative-800')};
  }
`;

export function UpgradeButton({ children }: UpgradeButtonProps) {
  const { t } = useTranslation();

  const handleUpgrade = () => {
    window.open(t('filters.date.plan_upgrade_url'));
  };

  return (
    <StyledButton
      data-test='date-filter-footer-upgrade'
      mode='link'
      onClick={handleUpgrade}
      size='xSmall'
      type='button'
    >
      {children}
    </StyledButton>
  );
}
