import { useCallback, useEffect, useMemo, useRef } from 'react';

import { SEARCH_LINES_QUERY } from '../../graphql/queries/SearchLinesQuery';
import { useInfiniteLoader } from '../useInfiniteLoader';

import { UseNumbersListParams, UseNumbersType } from './useNumbersList.decl';

import { DEFAULT_LINES_SORT, SEARCH_ITEMS_LIMIT } from '@constants/generic.constants';
import { useGraphQuery } from '@dashboard/library';
import { SearchLinesPaginatedQuery } from '@generated/SearchLinesPaginatedQuery';
import { SearchLinesQuery, SearchLinesQueryVariables } from '@generated/SearchLinesQuery';
import { SEARCH_LINES_PAGINATED_QUERY } from '@graphql/queries/SearchLinesPaginatedQuery';
import { getSearchLinesFilter } from '@helpers/search.helpers';

/**
 * Custom hook to query a company's numbers list based on different variables.
 * Also, handle the infinte scroll behaviour base on the containerRef.
 * @param searchTerm - Word/s or Number/s to search for.
 * @param numbersActivationState - Numbers state to search for.
 * @param scrollableContainerRef - Control the cache behaviour, ref of the element wrapping the infinite scrollable list.
 * @param from - Offset of the paginated query, default to 0.
 * @param limit - Limit of the paginated query, default to 50.
 * @returns  Object containing the numbers list and the loading state of the query.
 *
 *
 * ## Example
 * ```typescript
 * const { loading, lines } = useNumbersList({ scrollableContainerRef: undefined }); // no infinite scroll.
 * const { loading, lines } = useNumbersList({ scrollableContainerRef: elementRef }); //  infinite scroll.
 * ```
 */
export function useNumbersList({
  searchTerm = '',
  numbersActivationState,
  scrollableContainerRef,
  from = 0,
  limit = SEARCH_ITEMS_LIMIT,
  shouldMergeOnFetchMore = false,
}: UseNumbersListParams): UseNumbersType {
  const startIndex = useRef(0);
  const filter = useMemo(
    () =>
      getSearchLinesFilter({ searchQuery: searchTerm, activationState: numbersActivationState }),
    [searchTerm, numbersActivationState]
  );

  const { loading, data, fetchMore } = useGraphQuery<
    SearchLinesQuery & SearchLinesPaginatedQuery,
    SearchLinesQueryVariables
  >(shouldMergeOnFetchMore ? SEARCH_LINES_QUERY : SEARCH_LINES_PAGINATED_QUERY, {
    throwError: false,
    variables: {
      from,
      limit,
      filter,
      sort: DEFAULT_LINES_SORT,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const handleLoad = useCallback(() => {
    if (!fetchMore || !data?.searchAuthorizedLines?.items?.length) {
      return;
    }

    const endIndex = SEARCH_ITEMS_LIMIT + startIndex.current;
    const _hasMorePages = endIndex < data.searchAuthorizedLines.total;

    if (!_hasMorePages) {
      return;
    }

    startIndex.current = endIndex;

    fetchMore({
      variables: { from: endIndex, limit: SEARCH_ITEMS_LIMIT, filter },
    });
  }, [data, fetchMore, filter, startIndex]);

  useEffect(() => {
    startIndex.current = 0;
  }, [searchTerm]);

  /**
   * Handle the infinite scroll behaviour.
   * Won't do anything if containerRef is undefined
   */
  useInfiniteLoader({
    containerRef: scrollableContainerRef,
    threshold: 1000,
    skip: loading,
    onLoad: handleLoad,
  });

  const hasMorePages =
    (data?.searchAuthorizedLines?.items?.length || 0) < (data?.searchAuthorizedLines?.total || 0);

  return {
    loading,
    lines: shouldMergeOnFetchMore ? data?.searchAuthorizedLines : data?.searchLinesPaginated,
    fetchMore: handleLoad,
    hasMorePages,
  };
}
