import { Avatar, Spacer, Typography, AvatarImg } from '@aircall/tractor-v2';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { ExternalLink } from '@components/Link';
import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { Loading, NotFoundError } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { useIntegrations } from '@hooks/useIntegrations';
import { Trans, useTranslation } from 'react-i18next';

interface IntegrationFlowCompleteProps {
  applicationName?: APPLICATIONS_NAMES;
  learnMoreLink?: string;
  onSubmit: () => void;
  submitButtonText?: string;
  onCancel?: () => void;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
}

export function IntegrationFlowComplete({
  applicationName,
  learnMoreLink,
  onSubmit,
  submitButtonText,
  onCancel,
  secondaryActionText,
  onSecondaryAction,
}: IntegrationFlowCompleteProps) {
  const { t } = useTranslation();
  const { isLoading, getApplicationByName } = useIntegrations();

  if (isLoading) {
    return <Loading data-test='loading' />;
  }

  const application = applicationName ? getApplicationByName(applicationName) : null;

  if (applicationName && !application) {
    throw new NotFoundError('Application not found', { applicationName });
  }

  const logo = application?.logo || null;

  const displayedApplicationName =
    (application as ApplicationsListQuery_getApplications)?.name ||
    application?.shortName ||
    applicationName ||
    null;

  return (
    <IntegrationFlowPage
      title={displayedApplicationName}
      paperTitle={t('integration_flow.complete.sub_title')}
      avatarSrc={logo}
      paperChildren={
        <Spacer direction='vertical' alignItems='center' space='xl'>
          <Spacer space={80}>
            <Avatar size='xLarge'>
              <AvatarImg src='https://cdn.aircall.io/aircall-logo-squared.png' />
            </Avatar>
            {logo && (
              <Avatar size='xLarge'>
                <AvatarImg src={logo} />
              </Avatar>
            )}
          </Spacer>

          <Spacer direction='vertical' alignItems='center' space='xxs'>
            <Typography variant='bodySemiboldM'>
              {applicationName
                ? t('integration_flow.complete.text', {
                    applicationName: displayedApplicationName,
                  })
                : t('integration_flow.complete.text_no_app')}
            </Typography>
            {learnMoreLink && (
              <Typography variant='bodyMediumS'>
                <Trans i18nKey='integration_flow.complete.sub_text'>
                  <ExternalLink href={learnMoreLink} target='_blank'>
                    learn more
                  </ExternalLink>
                </Trans>
              </Typography>
            )}
          </Spacer>
        </Spacer>
      }
      hasFooter
      submitButtonText={submitButtonText}
      onSubmit={onSubmit}
      cancelButtonText={onCancel && t('integration_flow.complete.cancel')}
      onCancel={onCancel}
      secondaryActionText={secondaryActionText}
      onSecondaryAction={onSecondaryAction}
    />
  );
}
