/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DEFAULT_FILTER_VALUES, getSafeURLSearchObject } from './Filters/Filters.helpers';
import { FILTER_KEY_MAPPER, LOOKER_PAGES_FILTERS } from './Looker.constants';
import {
  LOOKER_ACTIONS,
  LOOKER_EVENTS,
  LOOKER_FILTER_KEY,
  LOOKER_STATUS,
  LookerEventData,
  LookerPageContextProps,
  LookerPageProviderProps,
} from './Looker.decl';
import {
  checkDataToLoad,
  checkTileEventError,
  formatFilters,
  getLookerStatusError,
  postIframeMessage,
  safeMergeWithCustomFilters,
} from './Looker.helpers';

import {
  URLSearchObject,
  usePersistSearchParams,
} from '@components/SearchProvider/usePersistSearchParams';
import { formatSearchParamsToObject } from '@components/SearchProvider/utils';
import {
  LOGGING_FEATURE_NAMES,
  LOGGING_LOOKER_EVENTS,
  LOGGING_STATUS,
} from '@constants/logging.constants';
import { createLog } from '@helpers/logging.helpers';
import { safeJsonParse } from '@helpers/object.helpers';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

export const LookerPageContext = createContext<LookerPageContextProps | undefined>(undefined);

export function LookerPageProvider({
  maxDateRangeInDays,
  minDate,
  onTruncated,
  ...props
}: LookerPageProviderProps) {
  const { searchParams, setSearchParams } = usePersistSearchParams();
  const { pathname } = useLocation();
  const { track } = useTracker();
  const [height, setHeight] = useState<string>('100%');
  const [iFrameLoading, setIFrameLoading] = useState(true);
  const [url, setUrl] = useState<string | undefined>();
  const [lookerStatus, setLookerStatus] = useState(LOOKER_STATUS.STORED_DATA);
  const [lookerFilters, setLookerFilters] = useLocalStorage<Record<string, URLSearchObject>>(
    LOOKER_PAGES_FILTERS,
    {}
  );
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const defaultFilterValues = useRef<URLSearchObject>({});

  // Ref used to track the number of time looker's filter were changed.
  const lookerReloadCounter = useRef(0);
  // Ref used to track if filter were changed before a reload actions.
  const lookerFiltersUpdated = useRef(false);
  const lookerRunOnce = useRef(false);

  const handleTracking = useCallback(
    (filterConfig: Record<string, string>, isUpdated: boolean) => {
      const formattedFilters = formatFilters(filterConfig, 'toDashboard');

      track({
        event: 'looker_analytics_reloaded',
        payload: {
          filters_config: formattedFilters,
          is_updated: isUpdated,
        },
      });
    },
    [track]
  );

  const dataError = useMemo(() => lookerStatus === LOOKER_STATUS.DATA_ERROR, [lookerStatus]);

  // update looker filter based on a URLSearchObject and run the filter change
  const updateLookerFilter = useCallback(
    (filters: URLSearchObject, forceRefresh: boolean = false) => {
      const formattedFilters = formatFilters(filters, 'toLooker');

      if (url) {
        postIframeMessage(
          iFrameRef.current!.contentWindow!,
          JSON.stringify({
            type: LOOKER_ACTIONS.DASHBOARD_FILTERS_UPDATE,
            filters: formattedFilters,
          }),
          url
        );

        if (forceRefresh) {
          // forces Looker to refresh: https://developers.looker.com/embed/advanced-embedding/making-changes-to-the-iframe
          postIframeMessage(
            iFrameRef.current!.contentWindow!,
            JSON.stringify({ type: LOOKER_ACTIONS.DASHBOARD_RUN }),
            url
          );
        }
      }
    },
    [url]
  );

  const handleCustomFilterChange = useCallback(
    (filterValue) => {
      const formattedSearchParams = formatSearchParamsToObject(searchParams);
      const filterValues = { ...formattedSearchParams, ...filterValue };

      setSearchParams(filterValues);
      setLookerFilters((prev) => ({ ...prev, [pathname]: filterValues }));
      updateLookerFilter(filterValues);
    },
    [pathname, searchParams, setSearchParams, setLookerFilters, updateLookerFilter]
  );

  const handleFiltersReset = useCallback(() => {
    setSearchParams(defaultFilterValues.current);
    setLookerFilters((prev) => ({ ...prev, [pathname]: defaultFilterValues.current }));
    updateLookerFilter(defaultFilterValues.current, true);
  }, [pathname, setSearchParams, setLookerFilters, updateLookerFilter]);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const eventData = safeJsonParse<LookerEventData>(event.data);

      if (!eventData) {
        return;
      }

      const dataToUse = checkDataToLoad(
        searchParams.toString(),
        lookerFilters[pathname],
        lookerStatus
      );

      switch (eventData.type) {
        // On mount, load the existing filter. Only time where we read localStorage
        case LOOKER_EVENTS.DASHBOARD_LOADED: {
          // read localstorage if no searchparams is found store one or set params
          if (dataToUse === 'searched-data') {
            const formattedSearchParams = formatSearchParamsToObject(searchParams);
            const safeURLSearchObject = getSafeURLSearchObject(formattedSearchParams, {
              maxDateRangeInDays,
              minDate,
            });
            setSearchParams(safeURLSearchObject);
            setLookerFilters((prev) => ({ ...prev, [pathname]: safeURLSearchObject }));

            updateLookerFilter(safeURLSearchObject, true);
          } else if (dataToUse === 'stored-data') {
            const safeURLSearchObject = getSafeURLSearchObject(lookerFilters[pathname], {
              maxDateRangeInDays,
              minDate,
            });
            setLookerFilters((prev) => ({ ...prev, [pathname]: safeURLSearchObject }));

            updateLookerFilter(safeURLSearchObject, true);
          } else {
            const formattedOnLoad = formatFilters(
              eventData.dashboard.dashboard_filters,
              'toDashboard'
            );
            setSearchParams(formattedOnLoad);
            setLookerFilters((prev) => ({ ...prev, [pathname]: formattedOnLoad }));
            // when the iframe load without data set looker status to fresh start
            setLookerStatus(LOOKER_STATUS.FRESH_DATA);
          }

          // Save the default filter values to be used in case a reset is triggered
          // but override the values for the filters under our control
          defaultFilterValues.current = {
            ...formatFilters(eventData.dashboard.dashboard_filters, 'toDashboard'),
            [FILTER_KEY_MAPPER[LOOKER_FILTER_KEY.DATE]]: DEFAULT_FILTER_VALUES.rangeValue!,
            [FILTER_KEY_MAPPER[LOOKER_FILTER_KEY.TIMEZONE]]: DEFAULT_FILTER_VALUES.timezone,
          };

          break;
        }
        // On tile complete, used to render a banner for call_history page and track if an error happened
        case LOOKER_EVENTS.DASHBOARD_TILE_COMPLETE: {
          if (checkTileEventError(eventData.status, lookerStatus)) {
            // the error value is determined by the data value. stored data -> data error / fresh data -> other error
            setLookerStatus(getLookerStatusError);
          }

          if (eventData.errors) {
            createLog({
              featureName: LOGGING_FEATURE_NAMES.LOOKER,
              event: LOGGING_LOOKER_EVENTS.DASHBOARD_TILE_COMPLETE,
              status: LOGGING_STATUS.FAILED,
              properties: {
                dashboard: eventData.dashboard?.id,
                tile: eventData.tile?.id,
                filters: eventData.dashboard?.dashboard_filters,
                errors: eventData.errors,
              },
            });
          }

          onTruncated?.(eventData.truncated);
          break;
        }
        // On change, get the filter from looker
        case LOOKER_EVENTS.DASHBOARD_FILTERS_CHANGED: {
          if (lookerRunOnce.current) {
            const formattedOnChange = formatFilters(
              eventData.dashboard.dashboard_filters,
              'toDashboard'
            );
            const customFilters = searchParams.toString()
              ? formatSearchParamsToObject(searchParams)
              : lookerFilters[pathname];
            const filters = safeMergeWithCustomFilters(customFilters, formattedOnChange);

            setSearchParams(filters);
            setLookerFilters((prev) => ({ ...prev, [pathname]: filters }));
            lookerFiltersUpdated.current = true;
          }
          break;
        }
        /**
         * On looker update :
         * increment the filter update counter by one.
         * - if no data are to be load (lookerStatus === FRESH_DATA) then we can start to track update at counter === 2
         * - if data are to be load (lookerStatus === STORED_DATA) then we can start to track update at counter === 3
         */
        case LOOKER_EVENTS.DASHBOARD_RUN_START: {
          lookerReloadCounter.current += 1;
          if (
            (lookerStatus === LOOKER_STATUS.FRESH_DATA && lookerReloadCounter.current > 1) ||
            lookerReloadCounter.current > 2
          ) {
            handleTracking(eventData.dashboard.dashboard_filters, lookerFiltersUpdated.current);
          }
          lookerFiltersUpdated.current = false;
          break;
        }
        case LOOKER_EVENTS.DASHBOARD_RUN_COMPLETE: {
          const formattedOnChange = formatFilters(
            eventData.dashboard.dashboard_filters,
            'toDashboard'
          );
          const customFilters = searchParams.toString()
            ? formatSearchParamsToObject(searchParams)
            : lookerFilters[pathname];
          const filters = safeMergeWithCustomFilters(customFilters, formattedOnChange);

          setSearchParams(filters);
          setLookerFilters((prev) => ({ ...prev, [pathname]: filters }));
          lookerRunOnce.current = true;
          break;
        }
        // On change, adapt embed height after looker dashboard loaded
        case LOOKER_EVENTS.PAGE_PROPERTIES_CHANGED: {
          setHeight(`${eventData.height + 2}px`);
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      handleTracking,
      lookerFilters,
      lookerStatus,
      maxDateRangeInDays,
      minDate,
      onTruncated,
      pathname,
      searchParams,
      setLookerFilters,
      setSearchParams,
      updateLookerFilter,
    ]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const memoizedContextValue = useMemo(
    () => ({
      height,
      iFrameRef,
      iFrameLoading,
      handleCustomFilterChange,
      handleFiltersReset,
      updateLookerFilter,
      url,
      setIFrameLoading,
      setUrl,
      error: dataError,
    }),
    [
      height,
      iFrameLoading,
      handleCustomFilterChange,
      handleFiltersReset,
      updateLookerFilter,
      url,
      dataError,
    ]
  );

  return <LookerPageContext.Provider value={memoizedContextValue} {...props} />;
}
