import { Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { osType } from '@dashboard/library';
import { Trans, useTranslation } from 'react-i18next';

export function DownloadOtherAppLinks({
  os,
  appType,
}: Readonly<{
  os: osType;
  appType: 'application' | 'application_aw';
}>) {
  const { t } = useTranslation();
  const windowSize =
    appType === 'application_aw' ? 'width=1000,height=659,popup' : 'width=376,height=659,popup';

  const openWebApp = () => {
    window.open(
      t(`resellers.${ResellerConfig.identifier}.onboarding.${appType}.download_link_web_app`),
      'AircallPhoneWebApp',
      windowSize
    );
  };

  return (
    <Typography variant='supportingRegularS' color='neutral-800' m='xxs'>
      <Trans i18nKey={`onboarding.application.looking_for_${os}`}>
        <ExternalLink
          href={t(
            `resellers.${ResellerConfig.identifier}.onboarding.${appType}.download_link_${os}`.toLowerCase()
          )}
          target='_blank'
          variant='supportingRegularS'
          data-test='download-other-app-link'
        />
        <ExternalLink
          onClick={openWebApp}
          target='_blank'
          variant='supportingRegularS'
          data-test='download-web-app-link'
        />
      </Trans>
    </Typography>
  );
}
