import { Component, ComponentType, ErrorInfo } from 'react';

import { PlaceholderComponentProps } from '@dashboard/extension';
import * as datadogRumHelpers from '@helpers/datadogRum.helpers';
/**
 * Wraps an extension's placeholder that will bypass it if it
 * raises an exception
 * @param extensionName - name of the extension the placeholder comes from
 * @param placeholder - placeholder component
 */
export function applyPlaceholderErrorBoundary(
  extensionName: string,
  placeholder: ComponentType<PlaceholderComponentProps>
) {
  const PlaceholderComponent = placeholder;
  return class WrapperErrorBoundary extends Component<
    PlaceholderComponentProps,
    { hasError: boolean }
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        hasError: false,
      };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
      datadogRumHelpers.addReactError('PlaceholderErrorBoundary', error, info);
    }

    render() {
      const { hasError } = this.state;
      if (hasError) {
        return null;
      }

      return <PlaceholderComponent {...this.props} />;
    }
  };
}
