import { DataLayoutProps } from './DataLayout.decl';

import { OperationVariables } from '@apollo/client';
import { Loading, typedMemo, useGraphQuery } from '@dashboard/library';
import { usePolling } from '@hooks/useGraphPolling/usePolling';

export const DataLayout = typedMemo(
  <TQuery, TVariables = OperationVariables | undefined>({
    children,
    query,
    queryOptions,
    isParentLoading = false,
    pollingInterval,
    pollingLimit,
    'data-test': dataTest = 'data-layout',
  }: DataLayoutProps<TQuery, TVariables>) => {
    const { data, loading, ...rest } = useGraphQuery<TQuery, TVariables>(query, {
      ...queryOptions,
    });

    usePolling({
      callback: rest.refetch,
      interval: pollingInterval,
      limit: pollingLimit,
    });

    if ((loading && !data) || isParentLoading) {
      return <Loading data-test={`${dataTest}-loading`} />;
    }

    return children({ data: data!, loading, ...rest });
  }
);
