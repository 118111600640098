import { gql } from '@apollo/client';

export const CREATE_KYC_VERIFICATION_SESSION_MUTATION = gql`
  mutation CreateKycVerificationSessionMutation($input: KycVerificationSessionInput!) {
    createKycVerificationSession(input: $input) {
      data {
        clientSecret
      }
      error {
        message
      }
    }
  }
`;
