import { LineCapabilitiesIconProps } from './LineCapabilitiesIcon.decl';

import { SmsInboundFilled } from '@aircall/icons';
import { Icon, Tag } from '@aircall/tractor-v2';
import { ReactComponent as MmsIcon } from '@assets/icons/mms_icon.svg';
import { ReactComponent as WhatsappIcon } from '@assets/icons/whatsapp_icon.svg';
import { LINE_CAPABILITY } from '@constants/numbers.constants';

const CAPABILITY_ICONS = [
  {
    capability: LINE_CAPABILITY.SMS,
    icon: SmsInboundFilled,
    testId: 'sms-tag',
    iconColor: 'primary-500',
    size: 22,
  },
  {
    capability: LINE_CAPABILITY.MMS,
    icon: MmsIcon,
    testId: 'mms-tag',
    size: 20,
  },
  {
    capability: LINE_CAPABILITY.WA_MSG,
    icon: WhatsappIcon,
    testId: 'whatsapp-tag',
    iconColor: 'primary-500',
    size: 20,
  },
];

export function LineCapabilitiesIcon({ capabilities }: Readonly<LineCapabilitiesIconProps>) {
  const activeCapabilities = CAPABILITY_ICONS.filter(({ capability }) =>
    capabilities?.includes(capability)
  );

  if (activeCapabilities.length === 0) {
    return null;
  }

  return (
    <>
      {activeCapabilities.map(({ icon, testId, iconColor, size }) => (
        <Tag key={testId} data-test={testId} size='small'>
          <Icon component={icon} size={size} color={iconColor} />
        </Tag>
      ))}
    </>
  );
}
