import { withLookerFilterStyle } from '../withLookerFilterStyle';

import {
  LEGACY_TIMEZONES,
  TimezoneSelect,
  TimezoneSelectProps,
  capitalizeFirstLetter,
} from '@dashboard/library';
import { LookerFilterStyleProps } from '@pages/looker-v2/Filters';
import { FilterWithTooltip } from '@pages/looker-v2/Filters/FilterWithTooltip';
import { useTimezoneFilter } from '@pages/looker-v2/Filters/TimezoneFilter/hooks';
import { useTranslation } from 'react-i18next';

const StyledTimezoneSelect = withLookerFilterStyle<TimezoneSelectProps & LookerFilterStyleProps>(
  TimezoneSelect
);

export function TimezoneFilter() {
  const { t } = useTranslation();
  const { onChange, value } = useTimezoneFilter();

  return (
    <FilterWithTooltip
      label={t('analytics.filter.timezone.title')}
      tooltip={t('analytics.filter.timezone.tooltip')}
    >
      <StyledTimezoneSelect
        allowRemove={false}
        hasValue={!!value}
        maxW='170px'
        onChange={onChange}
        options={LEGACY_TIMEZONES}
        texts={{
          placeholder: capitalizeFirstLetter(t('generic.timezone')),
          result: t('generic.result'),
          results: t('generic.results'),
        }}
        value={value}
        w='170px'
      />
    </FilterWithTooltip>
  );
}
