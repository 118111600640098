import { gql } from '@apollo/client';

export const SEARCH_TOTAL_QUERY = gql`
  query SearchTotalQuery {
    searchAuthorizedUsers {
      total
    }
    searchAuthorizedTeams {
      total
    }
    searchAuthorizedLines {
      total
    }
  }
`;
