import { UserCurrentUserReturn } from './useCurrentUser.decl';

import { useGraphQuery } from '@dashboard/library';
import { GetCurrentUserQuery } from '@generated/GetCurrentUserQuery';
import { GET_CURRENT_USER_QUERY } from '@graphql/queries/GetCurrentUserQuery';

/**
 * Hook to get the current user data.
 * @returns an object holding the query state and user's data
 */
export function useCurrentUser(): UserCurrentUserReturn {
  const { data, error, loading } = useGraphQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY, {
    throwError: false,
  });

  return {
    loading,
    error,
    data,
    currentUser: data?.getAgentV2,
  };
}
