import { WhatsappCapabilitiesTagProps } from './WhatsappCapabilitiesTag.decl';

import { Icon, Tag, TagVariants } from '@aircall/tractor-v2';
import { ReactComponent as WhatsappIcon } from '@assets/icons/whatsapp_icon.svg';
import { LINE_CAPABILITY } from '@constants/numbers.constants';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { useTranslation } from 'react-i18next';

const STATUS_CONFIG = {
  [WhatsappRegistrationState.CREATING]: {
    variant: 'yellow' as TagVariants,
    logoColor: 'warning-800',
    labelColor: 'warning-800',
    label: 'number_details.integrations.whatsapp_integration.status.pending.capability_tag_title',
    mode: 'fill',
  },
  [WhatsappRegistrationState.ONLINE]: {
    variant: 'white' as TagVariants,
    logoColor: 'primary-500',
    labelColor: 'neutral-600',
    label: 'number_details.integrations.whatsapp_integration.status.verified.capability_tag_title',
    mode: 'outline',
  },
  [WhatsappRegistrationState.OFFLINE]: {
    variant: 'red' as TagVariants,
    logoColor: 'critical-700',
    labelColor: 'critical-700',
    label: 'number_details.integrations.whatsapp_integration.status.rejected.capability_tag_title',
    mode: 'fill',
  },
} as const;

export function WhatsappCapabilitiesTag({ capabilities, status }: WhatsappCapabilitiesTagProps) {
  const { t } = useTranslation();
  if (
    !capabilities?.includes(LINE_CAPABILITY.WA_MSG) ||
    status === WhatsappRegistrationState.NOT_REGISTERED ||
    status === undefined
  ) {
    return null;
  }

  const config = STATUS_CONFIG[status as keyof typeof STATUS_CONFIG];

  return (
    <Tag
      data-test='whatsapp-capabilities-tag'
      size='small'
      variant={config.variant}
      mode={config.mode}
    >
      <Icon component={WhatsappIcon} size={16} color={config.logoColor} />
      <span style={{ color: config.labelColor }}>{t(config.label)}</span>
    </Tag>
  );
}
