/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { CompanyFragment } from '@generated/CompanyFragment';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { CurrentUser } from '@hooks/useCurrentUser/useCurrentUser.decl';

export function useDatadogRumContext(
  currentUser?: CurrentUser,
  currentCompany?: CompanyFragment
): void {
  const {
    authState: { decodedToken },
  } = useAuthenticationState();
  const adminImpersonator = decodedToken?.['custom:admin_sso_id'];

  useEffect(() => {
    const isInitialized = datadogRum.getUser()?.id;
    if (currentUser && currentCompany && isInitialized) {
      let extraContext = {};

      if (adminImpersonator) {
        extraContext = {
          connect_as: {
            admin_sso_id: adminImpersonator,
            impersonated_user: currentUser.ID,
          },
        };
      }

      datadogRum.setGlobalContextProperty('extra', extraContext);
      datadogRum.setGlobalContextProperty('company', {
        company_id: currentCompany.id,
        is_trial: !!currentCompany.trial,
        plan: currentCompany.plan,
        provider: currentCompany.provider,
      });
    }

    return () => {
      datadogRum.removeGlobalContextProperty('extra');
      datadogRum.removeGlobalContextProperty('company');
    };
  }, [adminImpersonator, currentCompany, currentUser]);
}
