import { useGraphQuery } from '@dashboard/library';
import {
  NumberIntegrationsQuery,
  NumberIntegrationsQueryVariables,
  NumberIntegrationsQuery_numberIntegrations_data,
} from '@generated/NumberIntegrationsQuery';
import { NUMBER_INTEGRATIONS_QUERY } from '@graphql/queries/NumberIntegrationsQuery';
import { ClientError } from '@helpers/errors.helpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface UseGetNumberConnectedIntegrationsReturnType {
  connectedIntegrations: NumberIntegrationsQuery_numberIntegrations_data[];
  error?: ClientError;
  loading: boolean;
}

/**
 * Hook to get connected integration of a number
 * @returns Object holding fetched result, error and loading state
 */
export const useGetNumberConnectedIntegrations =
  (): UseGetNumberConnectedIntegrationsReturnType => {
    const { t } = useTranslation();
    const { numberId } = useParams();

    const { data, loading } = useGraphQuery<
      NumberIntegrationsQuery,
      NumberIntegrationsQueryVariables
    >(NUMBER_INTEGRATIONS_QUERY, {
      variables: { numberId: parseInt(numberId) },
      fetchPolicy: 'cache-and-network',
      showErrorToast: (key) => t(key),
      throwError: false,
    });

    return {
      connectedIntegrations: data?.numberIntegrations.data || [],
      loading: loading && !data,
    };
  };
