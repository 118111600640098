import { useCallback, useState } from 'react';

import { EndTrialModal } from '../EndTrialModal/EndTrialModal';
import { MobileWarningModal } from '../MobileWarningModal/MobileWarningModal';

import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';

type ModalConfig = {
  key: string;
  shouldShow: boolean;
  renderComponent: () => JSX.Element;
};

export function GlobalModals(): JSX.Element | null {
  const { showOnboardingTrial, dashboardShowMobileWarning } = useFeaturesFlags();
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const handleModalComplete = useCallback(() => {
    setCurrentModalIndex((prev) => prev + 1);
  }, []);

  const modalConfigs: ModalConfig[] = [
    {
      key: 'mobileWarning',
      shouldShow: Boolean(dashboardShowMobileWarning),
      renderComponent: () => <MobileWarningModal onModalComplete={handleModalComplete} />,
    },
    {
      key: 'endTrial',
      shouldShow: Boolean(showOnboardingTrial),
      renderComponent: () => <EndTrialModal onModalComplete={handleModalComplete} />,
    },
  ];

  const visibleModals = modalConfigs.filter((modal) => modal.shouldShow);
  const currentModal = visibleModals[currentModalIndex];

  if (!currentModal) {
    return null;
  }

  return currentModal.renderComponent();
}
