import { WarningFilled } from '@aircall/icons';
import { Box, Tag, Tooltip } from '@aircall/tractor-v2';
import { RealtimeBusinessHourState } from '@components/RealtimeBusinessHoursState/RealtimeBusinessHoursState';
import { LINE_ACTIVATION_STATE } from '@constants/numbers.constants';
import { LineVerificationState } from '@generated/globalTypes';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { useTranslation } from 'react-i18next';

function ColumnFieldStatusInner({
  children,
  verificationState,
}: React.PropsWithChildren<Partial<SearchLinesQuery_searchAuthorizedLines_items>>) {
  const { t } = useTranslation();
  const shouldDisplayTooltip =
    verificationState === LineVerificationState.REJECTED ||
    verificationState === LineVerificationState.MISSING_INFORMATION;

  return shouldDisplayTooltip ? (
    <Tooltip title={t(`numbers.table.status.inactive.tooltip.${verificationState.toLowerCase()}`)}>
      {children}
    </Tooltip>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

export function ColumnFieldStatus({
  activationState,
  verificationState,
  state,
  ID,
  flowEditorEnabled,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const { t } = useTranslation();

  if (
    activationState === LINE_ACTIVATION_STATE.INACTIVE &&
    verificationState === LineVerificationState.PENDING
  ) {
    return (
      <Tooltip title={t('numbers.table.status.pending.tooltip')}>
        <Tag size='small' variant='yellow'>
          <WarningFilled size='16px' />
          {t('numbers.table.status.pending.tag')}
        </Tag>
      </Tooltip>
    );
  }

  if (activationState === LINE_ACTIVATION_STATE.INACTIVE) {
    return (
      <ColumnFieldStatusInner verificationState={verificationState}>
        <Tag size='small' variant='red'>
          <WarningFilled size='16px' />
          {t('numbers.table.status.inactive.tag')}
        </Tag>
      </ColumnFieldStatusInner>
    );
  }

  return (
    <Box w='120px'>
      <RealtimeBusinessHourState state={state} lineID={ID} flowEditorEnabled={flowEditorEnabled} />
    </Box>
  );
}
