import { useCallback } from 'react';

import { Avatar, AvatarImg, Spacer, Typography } from '@aircall/tractor-v2';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { APPLICATIONS_SCOPES } from '@constants/generic.constants';
import { NotFoundError } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { GetAuthorizedApplicationQuery_getAuthorizedApplication } from '@generated/GetAuthorizedApplicationQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface Payload {
  scope: APPLICATIONS_SCOPES;
  redirectUri: string;
}

interface IntegrationFlowAuthorizationProps {
  application:
    | ApplicationsListQuery_getApplications
    | GetAuthorizedApplicationQuery_getAuthorizedApplication;
  onSubmit: (payload: Payload) => void;
  onCancel: () => void;
}

export function IntegrationFlowAuthorization({
  application,
  onSubmit,
  onCancel,
}: IntegrationFlowAuthorizationProps) {
  const { t } = useTranslation();
  const { searchParams: queryParams } = useQueryParams();
  const redirectUri = queryParams.get('redirect_uri');
  const scope = queryParams.get('scope') as APPLICATIONS_SCOPES | undefined;

  const handleSubmit = useCallback(() => {
    onSubmit({ redirectUri: redirectUri!, scope: scope! });
  }, [onSubmit, redirectUri, scope]);

  if (!redirectUri || !scope) {
    throw new NotFoundError('Missing redirect_uri or scope', { redirectUri, scope });
  }

  const { logo, name } = application;

  return (
    <IntegrationFlowPage
      title={name}
      paperTitle={t('integration_flow.authorization.paper_title')}
      avatarSrc={logo}
      paperChildren={
        <Spacer direction='vertical' alignItems='center' space='xl'>
          <Spacer space={80}>
            <Avatar size='xLarge'>
              <AvatarImg src='https://cdn.aircall.io/aircall-logo-squared.png' />
            </Avatar>
            <Avatar size='xLarge'>
              <AvatarImg src={logo!} />
            </Avatar>
          </Spacer>

          <Spacer direction='vertical' alignItems='center' space='xxs'>
            <Typography variant='bodySemiboldM'>
              {t('integration_flow.authorization.paper_text', { applicationName: name })}
            </Typography>
            {scope === APPLICATIONS_SCOPES.PUBLIC_API && (
              <Typography variant='bodyMediumS'>
                {t('integration_flow.authorization.public_api_permission')}
              </Typography>
            )}
          </Spacer>
        </Spacer>
      }
      hasFooter
      submitButtonText={t('integration_flow.authorization.submit')}
      onSubmit={handleSubmit}
      cancelButtonText={t('integration_flow.authorization.cancel')}
      onCancel={onCancel}
    />
  );
}
