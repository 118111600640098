import { useCallback } from 'react';

import styled from 'styled-components';

import { RegistrationProcessSectionProps } from './RegistrationProcess.section.decl';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Button,
  Flex,
  getColor,
  Typography,
} from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { Gap } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTracker } from '@hooks/useTracker';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Step = styled(Flex)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${getColor('neutral-400')};
  border-radius: 8px;
  color: ${getColor('neutral-400')};
`;

export function RegistrationProcessSection({ translationPrefix }: RegistrationProcessSectionProps) {
  const { t } = useTranslation();
  const { numberId } = useParams();
  const { dashboardEnableTandemAi } = useFeaturesFlags();
  const { track } = useTracker();

  const buttonLinkUrl = t(`${translationPrefix}.button_link`);
  const fullUrl = `${buttonLinkUrl}${dashboardEnableTandemAi ? '&tandem=true' : ''}`;

  const handleClick = useCallback(() => {
    track({
      event: 'registration_process_section_a2p_form_button_clicked',
      payload: {
        url: fullUrl,
        numberId,
      },
    });

    window.open(fullUrl, '_blank');
  }, [t, translationPrefix]);

  return (
    <Gap flexDirection='column' gap='s'>
      <Typography variant='bodySemiboldM'>{t(`${translationPrefix}.title`)}</Typography>
      <Typography variant='bodyRegularS'>{t(`${translationPrefix}.paragraph`)}</Typography>
      <Gap flexDirection='row' gap='xxs'>
        <Step>1</Step>
        <Typography variant='bodyRegularS'>
          <Trans i18nKey={`${translationPrefix}.first_step`}>
            <ExternalLink
              href={fullUrl}
              target='_blank'
              data-test='registration-process-first-step-link'
              onClick={() =>
                track({
                  event: 'registration_process_section_a2p_form_link_clicked',
                  payload: {
                    url: fullUrl,
                    numberId,
                  },
                })
              }
            />
          </Trans>
        </Typography>
      </Gap>
      <Gap flexDirection='row' gap='xxs'>
        <Step>2</Step>
        <Typography variant='bodyRegularS'>{t(`${translationPrefix}.second_step`)}</Typography>
      </Gap>
      <Gap flexDirection='row' gap='xxs'>
        <Step>3</Step>
        <Typography variant='bodyRegularS'>{t(`${translationPrefix}.third_step`)}</Typography>
      </Gap>
      <Banner inline variant='info'>
        <BannerIcon />
        <BannerHeading>{t(`${translationPrefix}.banner`)}</BannerHeading>
      </Banner>
      <Flex justifyContent='flex-end'>
        <Button
          mode='outline'
          variant='secondary'
          size='xSmall'
          onClick={handleClick}
          data-test='registration-process-section-button'
        >
          {t(`${translationPrefix}.button_text`)}
        </Button>
      </Flex>
    </Gap>
  );
}
