import { gql } from '@apollo/client';

export const DEACTIVATE_WHATSAPP_NUMBER = gql`
  mutation DeactivateWhatsAppNumber($input: DeactivateWhatsAppNumberInput!) {
    deactivateWhatsAppNumber(input: $input) {
      success
      message
    }
  }
`;
