/* istanbul ignore file */
import { InMemoryCache } from '@apollo/client';
import { BlacklistedNumbersQuery_blacklistedNumbers } from '@generated/BlacklistedNumbersQuery';
import {
  mergeCallConversations,
  mergeSearchItemsCollection,
  mergeTokenBasedList,
  mergeUserAssociatedLines,
  getInvoicesPagination,
  mergeEvaluations,
} from '@helpers/cache.helpers';

export const createCache = (): InMemoryCache =>
  new InMemoryCache({
    possibleTypes: {
      CalendarOutputV2: [
        'CalendarOutput',
        'CalendarTitleAlreadyExistsException',
        'ConflictException',
        'GenericException',
        'ResourceNotFoundException',
      ],
      FlowConfigurationOutputV2: [
        'FlowConfigurationOutput',
        'MaxFlowSizeReachedException',
        'GenericException',
      ],
    },
    typePolicies: {
      Agent: {
        keyFields: ['ID'],
      },
      AgentEmailNotification: {
        keyFields: ['ID'],
      },
      AgentPhoneNumber: {
        keyFields: ['ID'],
      },
      AgentV2: {
        keyFields: ['ID'],
        fields: {
          associatedLines: {
            keyArgs: false,
            merge: mergeUserAssociatedLines,
          },
        },
      },
      Application: {
        keyFields: ['id', 'name', 'source'],
      },
      CallConversation: {
        keyFields: ['callId'],
      },
      CalendarOutput: {
        keyFields: ['calendarID'],
      },
      BasicCall: {
        keyFields: ['callId'],
      },
      Beta: {
        keyFields: ['ID'],
      },
      Company: {
        keyFields: ['ID'],
      },
      ContactsSearchResult: {
        keyFields: ['contact', ['phoneNumber']],
      },
      ConversationContact: {
        keyFields: ['phoneNumber'],
      },
      FlowConfigurationOutput: {
        keyFields: ['lineID'],
      },
      Integration: {
        keyFields: ['id', 'name'],
      },
      IntegrationConnectedLines: {
        keyFields: ['id', 'integrationType', 'pagination', ['page', 'limit']],
      },
      Line: {
        keyFields: ['ID'],
      },
      LineConfiguration: {
        keyFields: ['lineId'],
      },
      LineSettingsOutput: {
        keyFields: ['lineID'],
      },
      LineTranscriptionSettings: {
        keyFields: ['lineId'],
      },
      PlaybookConfiguration: {
        keyFields: ['line', ['lineId']],
      },
      NotificationsConfiguration: {
        keyFields: [],
      },
      NumberAttributes: {
        keyFields: ['number'],
      },
      Team: {
        keyFields: ['ID'],
      },
      TeamAttributes: {
        keyFields: ['name'],
      },
      Tracker: {
        keyFields: ['trackerId'],
      },
      NextAction: {
        keyFields: ['ID'],
      },
      UpdatedTracker: {
        keyFields: ['tracker', ['trackerId']],
      },
      UserCriticalAction: {
        keyFields: ['agentID', 'action'],
      },
      CompanyBillingDetailsOutput: {
        keyFields: ['ID'],
      },
      SubscriptionInfo: {
        keyFields: [],
        merge: true,
      },
      CompanyAddon: {
        keyFields: ['name'],
        merge: true,
      },
      PhoneContact: {
        keyFields: ['mainPhoneNumber'],
      },
      Step: {
        keyFields: ['stepType'],
      },
      ScoreCard: {
        keyFields: ['ID'],
      },
      ScoreCardCategory: {
        keyFields: ['ID'],
      },
      Evaluation: {
        keyFields: ['ID'],
      },
      EvaluationCategory: {
        keyFields: ['ID'],
      },
      VirtualAgent: {
        keyFields: ['ID'],
      },
      Query: {
        fields: {
          subscription: {
            keyArgs: false,
            merge: true,
          },
          blacklistedNumbers: {
            keyArgs: ['order'],
            merge: (
              existing: BlacklistedNumbersQuery_blacklistedNumbers | undefined,
              incoming: BlacklistedNumbersQuery_blacklistedNumbers
            ) => {
              if (!existing) {
                return incoming;
              }

              if (incoming.meta.currentPage === existing.meta.currentPage) {
                return incoming;
              }

              return {
                ...incoming,
                blacklistedPhoneNumbers: [
                  ...existing.blacklistedPhoneNumbers,
                  ...incoming.blacklistedPhoneNumbers,
                ],
              };
            },
          },
          getCallConversations: {
            keyArgs: ['fromTs', 'toTs', 'trackerIds'],
            merge: mergeCallConversations,
          },
          getEvaluations: {
            keyArgs: [
              'filters',
              [
                'fromCallStartedAt',
                'toCallStartedAt',
                'minNormalizedScore',
                'maxNormalizedScore',
                'revieweeUserIds',
                'scoreCardId',
              ],
            ],
            merge: mergeEvaluations,
          },
          // TODO: Remove this once the searchAgents query is fully migrated to the new authorized query
          searchAgents: {
            keyArgs: () => ['filter', 'sort'],
            merge: mergeSearchItemsCollection(),
          },
          searchAuthorizedUsers: {
            keyArgs: () => ['filter', 'sort'],
            merge: mergeSearchItemsCollection(),
          },
          liveMonitoringCalls: {
            keyArgs: ['input', ['filter', 'sort', 'pages']],
            merge: mergeTokenBasedList,
          },
          liveMonitoringUsers: {
            keyArgs: ['input', ['filter', 'sort', 'pages']],
            merge: mergeTokenBasedList,
          },
          liveMonitoringLines: {
            keyArgs: ['input', ['filter', 'sort', 'pages']],
            merge: mergeTokenBasedList,
          },
          // TODO: Remove this once the searchTeams query is fully migrated to the new authorized query
          searchTeams: {
            keyArgs: (_args, context) => {
              /* istanbul ignore next */
              if (context.field?.alias) {
                return ['filter', 'from', 'limit'];
              }
              return ['filter', 'sort'];
            },
            merge: mergeSearchItemsCollection(),
          },
          searchAuthorizedTeams: {
            keyArgs: (_args, context) => {
              /* istanbul ignore next */
              if (context.field?.alias) {
                return ['filter', 'from', 'limit'];
              }
              return ['filter', 'sort'];
            },
            merge: mergeSearchItemsCollection(),
          },
          // TODO: Remove this once the searchLines query is fully migrated to the new authorized query
          searchLines: {
            keyArgs: (_args, context) => {
              if (context.field?.alias) {
                return ['filter', 'from', 'limit'];
              }
              return ['filter', 'sort'];
            },
            merge: mergeSearchItemsCollection(),
          },
          searchAuthorizedLines: {
            keyArgs: (_args, context) => {
              if (context.field?.alias) {
                return ['filter', 'from', 'limit'];
              }
              return ['filter', 'sort'];
            },
            merge: mergeSearchItemsCollection(),
          },
          listCalendars: {
            keyArgs: ['filter', 'sort'],
            merge: mergeSearchItemsCollection((args) => !args.token),
          },
          listCalendarsV2: {
            keyArgs: ['filter', 'sort'],
            merge: mergeSearchItemsCollection((args) => !args.token),
          },
          listVirtualAgents: {
            keyArgs: ['filter', 'sort'],
            merge: mergeSearchItemsCollection((args) => !args.token),
          },
          getInvoices: {
            ...getInvoicesPagination(),
          },
        },
      },
    },
  });
