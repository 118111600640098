import { gql } from '@apollo/client';

export const REGISTER_WHATSAPP_NUMBER = gql`
  mutation RegisterWhatsAppNumber($input: RegisterWhatsAppNumberInput!) {
    registerWhatsAppNumber(input: $input) {
      numberUUID
      status
    }
  }
`;
