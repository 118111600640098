import { useCallback } from 'react';

import { UseDeleteNumberProps, UseDeleteNumberReturnType } from './useDeleteNumber.decl';

import { NUMBERS_ROUTE } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  DeleteNumberMutation,
  DeleteNumberMutationVariables,
} from '@generated/DeleteNumberMutation';
import { DELETE_NUMBER_MUTATION } from '@graphql/mutations/DeleteNumberMutation';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { getExternalErrorTranslationStrings } from '@helpers/errors.helpers';
import { useGraphMutation } from '@hooks/useMutation';
import { useToast } from '@hooks/useToast';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

/**
 * Custom hook to delete a number
 * @param UseDeleteNumberProps - destructured parameter
 * @returns the method to delete a number, its loading state and its error state
 */
export function useDeleteNumber({
  id,
  name,
  onDelete,
}: UseDeleteNumberProps): UseDeleteNumberReturnType {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const [deleteNumber, { loading, error }] = useGraphMutation<
    DeleteNumberMutation,
    DeleteNumberMutationVariables
  >(DELETE_NUMBER_MUTATION, {
    onError: noop,
    onCompleted: () => {
      navigate(NUMBERS_ROUTE, { replace: true });
      toast.showToast({
        message: t(SUCCESS_FEEDBACK_MESSAGES.DELETION_SUCCESS, { name }),
      });
    },
  });

  const handleDeleteNumber = useCallback(() => {
    const promise = deleteNumber({
      variables: {
        numberId: id,
      },
      update: (cache) => {
        // remove line from linesList
        cache.modify({
          id: cache.identify({ __typename: 'Line', ID: id }),
          fields: (_, { DELETE }) => DELETE,
        });

        cache.updateQuery(
          {
            query: SEARCH_TOTAL_QUERY,
          },
          (cachedData) => ({
            ...cachedData,
            searchAuthorizedLines: {
              ...cachedData.searchAuthorizedLines,
              total: cachedData.searchAuthorizedLines.total - 1,
            },
          })
        );
      },
    });
    onDelete && onDelete();
    return promise;
  }, [deleteNumber, id, onDelete]);

  return {
    isDeleteLoading: loading,
    deleteError: error ? getExternalErrorTranslationStrings(error) : undefined,
    handleDeleteNumber,
  };
}
