import { UseKycStatusReturn } from './useKycStatus.decl';

import { useGraphQuery } from '@dashboard/library';
import { GetKycStatus, GetKycStatusVariables } from '@generated/GetKycStatus';
import { GET_KYC_STATUS } from '@graphql/queries/GetKycStatus';

export enum SDR_VERIFICATION_STATES {
  PENDING = 'pending',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
}

export enum STRIPE_KYC_STATES {
  PROCESSING = 'processing',
  REQUIRES_INPUT = 'requires_input',
  VERIFIED = 'verified',
}

export const useKycStatus = (_companyId: number, _userId: number): UseKycStatusReturn => {
  const { data, loading, error } = useGraphQuery<GetKycStatus, GetKycStatusVariables>(
    GET_KYC_STATUS,
    {
      variables: { companyId: _companyId.toString(), userId: _userId.toString() },
      throwError: false,
    }
  );

  return {
    data: data?.getKycStatus?.data,
    loading,
    error,
  };
};
