import styled from 'styled-components';

import { MacFilled, WindowsFilled } from '@aircall/icons';
import { Button, Icon } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { osType } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: left;
`;

const Icons = {
  mac: MacFilled,
  windows: WindowsFilled,
};

export function DownloadAppButton({
  os,
  appType,
}: Readonly<{
  os: osType | 'macIntel' | 'macPkg' | 'windowsExe';
  appType: 'application' | 'application_aw';
}>) {
  const { t } = useTranslation();

  let osIcon: osType = 'windows';
  if (!['windows', 'windowsExe'].includes(os)) {
    osIcon = 'mac';
  }

  return (
    <StyledLink
      href={t(
        `resellers.${ResellerConfig.identifier}.onboarding.${appType}.download_link_${os}`.toLowerCase()
      )}
      target='_blank'
      rel='noreferrer noopener'
      data-test='download-app-button-link'
    >
      <StyledButton variant='secondary' mode='outline'>
        <Icon component={Icons[osIcon]} />
        {t(`onboarding.${appType}.download_for_${os}`.toLowerCase())}
      </StyledButton>
    </StyledLink>
  );
}
