import { ChevronRightOutlined } from '@aircall/icons';
import { Button, Icon } from '@aircall/tractor-v2';
import { LineVerificationState } from '@generated/globalTypes';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';
import { getProofOfIDLink } from '@pages/number-detail/ProofOfIdBanner/ProofOfId.helpers';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export function ColumnFieldActions({
  activationState,
  verificationState,
  numberUUID,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const navigate = useNavigate();

  function handleClick() {
    const proofOfIDLink = getProofOfIDLink(numberUUID);

    if (!proofOfIDLink) {
      return;
    }

    navigate(proofOfIDLink);
  }

  if (
    isNumberActive(activationState) ||
    !verificationState ||
    ![LineVerificationState.MISSING_INFORMATION, LineVerificationState.REJECTED].includes(
      verificationState
    )
  ) {
    return null;
  }

  return (
    <Button
      data-test={`submit-file-link-${verificationState.toLocaleLowerCase()}`}
      variant='critical'
      mode='link'
      size='xSmall'
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      {t(`numbers.table.actions.${verificationState.toLocaleLowerCase()}`)}
      <Icon component={ChevronRightOutlined} size={16} />
    </Button>
  );
}
