import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { MOBILE_WARNING_MODAL_TRACK_EVENTS } from './MobileWarningModal.decl';

import {
  Button,
  Checkbox,
  Flex,
  ModalBody,
  ModalDialog,
  Typography,
  getSpace,
} from '@aircall/tractor-v2';
import mobileWarningImage from '@assets/images/mobile_warning.svg';
import { GlobalModalChildProps } from '@components/GlobalModals';
import { getDevice } from '@helpers/tracker.helpers';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

const ContinueButton = styled(Button)`
  margin-bottom: ${getSpace('xs')};
`;

export function MobileWarningModal({ onModalComplete }: Readonly<GlobalModalChildProps>) {
  const { t } = useTranslation();

  // Local storage to track if user opts out of seeing modal again
  const [hideMobileWarningModal, setHideMobileWarningModal] = useLocalStorage<boolean>(
    'hideMobileWarningModal',
    false
  );

  const device = getDevice();
  const isOnMobile = device === 'Mobile' || device === 'Tablet';
  const [show, setShow] = useState(!hideMobileWarningModal && isOnMobile);
  const timeShownRef = useRef(new Date());
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const { track } = useTracker();

  useEffect(() => {
    if (show) {
      track({
        event: MOBILE_WARNING_MODAL_TRACK_EVENTS.MODAL_SHOWN,
      });
    } else {
      onModalComplete();
    }
  }, [show, onModalComplete, track]);

  const handleClose = () => {
    setShow(false);

    if (doNotShowAgain) {
      setHideMobileWarningModal(true);
    }

    const duration = (new Date().getTime() - timeShownRef.current.getTime()) / 1000;
    track({
      event: MOBILE_WARNING_MODAL_TRACK_EVENTS.MODAL_DISMISSED,
      payload: {
        duration,
        doNotShowAgain,
      },
    });
  };

  const handleCheckboxChange = (isChecked: boolean) => {
    setDoNotShowAgain(isChecked);
  };

  return (
    <ModalDialog
      show={show}
      rootClose={false}
      onHide={handleClose}
      data-test='mobile-warning-modal'
      size='xSmall'
    >
      <ModalBody>
        <Flex flexDirection='column' alignItems='center'>
          <img style={{ maxWidth: '100%' }} src={mobileWarningImage} alt='Desktop graphic' />
          <Typography variant='headingBoldXS' textAlign='center' mt='xs' mb='xxs'>
            {t('mobile_device_warning.title')}
          </Typography>

          <Typography variant='bodyMediumS' textAlign='center' mb='xs'>
            {t('mobile_device_warning.description')}
          </Typography>

          <ContinueButton
            onClick={handleClose}
            data-test='mobile-warning-modal-button'
            size='small'
          >
            {t('mobile_device_warning.button_label')}
          </ContinueButton>

          <Flex alignItems='center'>
            <Checkbox
              size='small'
              id='doNotShowAgain'
              checked={doNotShowAgain}
              onChange={handleCheckboxChange}
              data-test='do-not-show-again-checkbox'
            />
            <Typography
              variant='supportingRegularS'
              as='label'
              htmlFor='doNotShowAgain'
              ml='xxs'
              data-test='do-not-show-again-label'
            >
              {t('mobile_device_warning.checkbox_label')}
            </Typography>
          </Flex>
        </Flex>
      </ModalBody>
    </ModalDialog>
  );
}
