import { useHandleButtonLink } from '../KycVerificationPage/useHandleButtonLink';

import { Banner, BannerHeading, BannerIcon, BannerSuffix } from '@aircall/tractor-v2';
import { LoadingButton } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function KycBanner() {
  const { t } = useTranslation();

  const { handleButtonLink, loading, stripe } = useHandleButtonLink();

  return (
    <Banner variant='warning' data-test='kyc-banner'>
      <BannerIcon /> <BannerHeading>{t('kyc_banner.title')}</BannerHeading>
      <BannerSuffix>
        <LoadingButton
          mode='outline'
          onClick={handleButtonLink}
          variant='secondary'
          data-test='kyc-banner-initiate-kyc-button'
          disabled={!stripe || loading}
          loading={loading}
        >
          {t('kyc_banner.initiate_kyc')}
        </LoadingButton>
      </BannerSuffix>
    </Banner>
  );
}
