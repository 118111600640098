import { useCallback } from 'react';

import { useUpdateNumberForm } from '../hooks/useUpdateNumberForm';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Box,
  FormItem,
  RadioGroup,
  Typography,
} from '@aircall/tractor-v2';
import { NUMBER_TYPE } from '@constants/numbers.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  Loading,
  FormField,
  GridItem,
  GridLayout,
  List,
  ListItem,
  PaperForm,
  RadioRow,
  TimezoneSelect,
  useGraphQuery,
} from '@dashboard/library';
import { getNumberByUUID as getNumberByUUIDQuery } from '@generated/getNumberByUUID';
import { GET_NUMBER_BY_UUID } from '@graphql/queries/GetNumberByUUID';
import { validateNumberName } from '@helpers/validators.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const TRANS_PREFIX = 'number_details.settings.general_settings_section';

export function NumberDetailSettingsGeneral() {
  // RIO (Relevé d'Identité Opérateur) code is a unique identifier for French phone numbers
  // that is required when porting numbers between telecom operators in France
  const { updateNumberForm, number } = useUpdateNumberForm();
  const { t } = useTranslation();
  const { smartflowsEnabled, displayRioCodeInDashboard } = useFeaturesFlags();
  const initialValues = {
    name: number!.name,
    numberType: number!.ivr ? NUMBER_TYPE.IVR : NUMBER_TYPE.CLASSIC,
    timezone: number?.timeZone,
  };

  const onSubmit = useCallback(
    ({ name, numberType, timezone }) =>
      updateNumberForm(
        {
          name,
          ivr: numberType === NUMBER_TYPE.IVR,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          time_zone: timezone,
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: number!.name,
          }),
        }
      ),
    [number, t, updateNumberForm]
  );

  const isSmartflowsNumber = smartflowsEnabled && number!.flowEditorEnabled;
  const { data, loading: isLoadingRioCode } = useGraphQuery<getNumberByUUIDQuery>(
    GET_NUMBER_BY_UUID,
    {
      throwError: false,
      variables: {
        input: {
          numberUUID: number?.numberUuid,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: number?.iso3166Alpha2 !== 'FR' || !number?.numberUuid || !displayRioCodeInDashboard,
    }
  );

  const numberRioCode = data?.getNumberByUUID?.portaDonorRio || undefined;

  return (
    <PaperForm
      data-test='number-detail-settings-general-form'
      shouldBlockNavigation
      formProps={{ onSubmit, initialValues }}
      title={t(`${TRANS_PREFIX}.title`)}
    >
      {({ dirty, values }) => {
        const shouldShowIVRBanner = dirty && !number!.ivr && values.numberType === NUMBER_TYPE.IVR;

        return (
          <>
            <GridLayout alignItems='center' rowGap='24px'>
              <GridItem xs={12} md={10} xl={6} gridRow={1}>
                <Typography variant='bodyMediumS' mb='xxs'>
                  {t(`${TRANS_PREFIX}.number_name_label`)}
                </Typography>
                <FormField
                  name='name'
                  validate={validateNumberName}
                  getErrorMessage={t}
                  data-test='text-field-number-name'
                />
              </GridItem>
              {isSmartflowsNumber ? (
                <GridItem xs={12} md={10} xl={6} gridRow={2}>
                  <FormItem label={t(`${TRANS_PREFIX}.number_timezone_label`)}>
                    <Field name='timezone'>
                      {({ input: { value, onChange } }) => (
                        <TimezoneSelect
                          onChange={onChange}
                          shouldGuessTimezone
                          triggerStyle='select'
                          value={value}
                          data-test='number-timezone'
                        />
                      )}
                    </Field>
                  </FormItem>
                </GridItem>
              ) : null}
              {number?.iso3166Alpha2 === 'FR' && displayRioCodeInDashboard && (
                <GridItem xs={12} md={10} xl={6} gridRow={3}>
                  <Typography variant='bodyMediumS' mb='xxs'>
                    {t(`${TRANS_PREFIX}.rio_code`)}
                  </Typography>
                  {isLoadingRioCode ? (
                    <Loading size={24} data-test='saving-icon' color='interaction-primary' />
                  ) : (
                    <FormField
                      name='rioCode'
                      getErrorMessage={t}
                      data-test='text-field-rioCode'
                      value={numberRioCode}
                      readOnly
                    />
                  )}
                </GridItem>
              )}
            </GridLayout>
            {!isSmartflowsNumber && (
              <Box mt='m'>
                <Field name='numberType'>
                  {({ input: { value, onChange } }) => (
                    <RadioGroup
                      value={value}
                      onChange={onChange}
                      w='100%'
                      itemsSized='evenly-sized'
                      data-test='classic-ivr-radio-row'
                    >
                      <List hasScroll={false} w='100%'>
                        <ListItem h='auto'>
                          <RadioRow
                            title={t(`${TRANS_PREFIX}.options.classic.title`)}
                            value={NUMBER_TYPE.CLASSIC}
                            data-test={`radio-${NUMBER_TYPE.CLASSIC}`}
                            description={t(`${TRANS_PREFIX}.options.classic.description`)}
                          />
                        </ListItem>
                        <ListItem h='auto'>
                          <RadioRow
                            title={t(`${TRANS_PREFIX}.options.ivr.title`)}
                            value={NUMBER_TYPE.IVR}
                            data-test={`radio-${NUMBER_TYPE.IVR}`}
                            description={t(`${TRANS_PREFIX}.options.ivr.description`)}
                            content={
                              shouldShowIVRBanner && (
                                <Banner variant='info' inline data-test='ivr-transition-banner'>
                                  <BannerIcon />
                                  <BannerHeading>
                                    {t(`${TRANS_PREFIX}.options.ivr.transition_warning`)}
                                  </BannerHeading>
                                </Banner>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </RadioGroup>
                  )}
                </Field>
              </Box>
            )}
          </>
        );
      }}
    </PaperForm>
  );
}
