import { useCallback, useEffect, useRef, useState } from 'react';

import { TrialChecklistItemActionMenu } from '../TrialChecklistItemActionMenu/TrialChecklistItemActionMenu';
import { TrialChecklistProgress } from '../TrialChecklistProgress/TrialChecklistProgress';

import { STEP_TYPE_REDIRECT_MAP } from './TrialChecklist.data';
import { TrialChecklistItemProps, TrialChecklistProps } from './TrialChecklist.decl';
import {
  TrialChecklistConfetti,
  TrialChecklistContainer,
  TrialChecklistFullPageButton,
  TrialChecklistItemContainer,
  TrialChecklistItemExternalLink,
  TrialChecklistItemIconWrapper,
  TrialChecklistItemTitle,
  TrialChecklistItems,
  TrialUpgradeLinkButton,
  TrialWorkspaceLinkButton,
} from './TrialChecklist.styles';

import { MinusOutlined, PlusOutlined, StarFilled } from '@aircall/icons';
import { Box, Flex, Icon, Typography } from '@aircall/tractor-v2';
import { ACCOUNT_ROUTE, ACCOUNT_TABS } from '@constants/routes.constants';
import { StepStatus, StepType } from '@generated/globalTypes';
import { useOnboardingData } from '@hooks/useOnboardingData';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

export const CONFETTI_ANIMATION_DURATION = 10000;

export function TrialChecklistItem({
  icon,
  stepType,
  titleKey,
}: Readonly<TrialChecklistItemProps>) {
  const { t } = useTranslation();
  const { onboardingData } = useOnboardingData();
  const onboardingStepData = onboardingData?.steps.find((step) => step.stepType === stepType);
  const url = onboardingStepData?.redirectUrl || STEP_TYPE_REDIRECT_MAP[stepType];
  const isComplete = onboardingStepData?.stepStatus === StepStatus.COMPLETED;
  const title = onboardingStepData?.stepTitle || t(titleKey);
  const target =
    new URL(url, window.location.origin).host === window.location.host ? '_self' : '_blank';

  // Local storage to track if the confetti was shown for the step before
  const [trialChecklistConfettisShown, setTrialChecklistConfettisShown] = useLocalStorage<
    StepType[]
  >('trialChecklistConfettisShown', []);
  const showConfetti = !trialChecklistConfettisShown.includes(stepType);

  useEffect(() => {
    if (!isComplete || !showConfetti) {
      return noop;
    }

    const timerId = window.setTimeout(() => {
      setTrialChecklistConfettisShown((prev) => prev.concat(stepType));
    }, CONFETTI_ANIMATION_DURATION);

    return () => {
      clearTimeout(timerId);
    };
  }, [isComplete, showConfetti, setTrialChecklistConfettisShown, stepType]);

  return (
    <TrialChecklistItemContainer data-test='checklist-item-wrapper' disabled={isComplete}>
      {isComplete && showConfetti ? (
        <TrialChecklistConfetti
          autorun={{ speed: 0.35, duration: CONFETTI_ANIMATION_DURATION }}
          globalOptions={{
            disableForReducedMotion: true,
          }}
          decorateOptions={(opts) => ({
            ...opts,
            startVelocity: 4,
            scalar: 1.2,
            particleCount: 40,
          })}
        />
      ) : undefined}

      <TrialChecklistItemExternalLink href={url} target={target}>
        <TrialChecklistItemIconWrapper>
          <Icon
            component={icon}
            color='call-callback'
            size={16}
            data-test='checklist-item-left-icon'
          />
        </TrialChecklistItemIconWrapper>
        <TrialChecklistItemTitle
          variant='bodyMediumS'
          color='text-highlight'
          data-test='checklist-item-title'
        >
          {title}
        </TrialChecklistItemTitle>
      </TrialChecklistItemExternalLink>
      <TrialChecklistItemActionMenu stepType={stepType} isComplete={isComplete} />
    </TrialChecklistItemContainer>
  );
}

export function TrialChecklist({
  completedTask,
  totalTask,
  onboardingData,
  popupMode,
  setIsWidgetOpen,
  phoneUrl,
}: Readonly<TrialChecklistProps>) {
  const widgetPopupRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [showItems, setShowItems] = useState(true);

  const VisibilityIcon = showItems ? MinusOutlined : PlusOutlined;
  const trialsTaskText = showItems
    ? 'user_profile.task_checklist.hideTrialTasks'
    : 'user_profile.task_checklist.showTrialTasks';

  const handleOnOutsideClick = useCallback(
    (event: MouseEvent): void => {
      if (!popupMode) {
        return;
      }
      if (
        event.target &&
        widgetPopupRef.current &&
        !widgetPopupRef.current.contains(event.target as Node)
      ) {
        setIsWidgetOpen(false);
      }
    },
    [popupMode, setIsWidgetOpen]
  );

  useEffect(() => {
    window.addEventListener('click', handleOnOutsideClick, true);
    return () => {
      window.removeEventListener('click', handleOnOutsideClick, true);
    };
  }, [handleOnOutsideClick]);

  return (
    <TrialChecklistContainer
      popupMode={popupMode}
      data-test='trial-checklist-container'
      spaceY='xs'
      ref={widgetPopupRef}
    >
      <Flex data-test='trial-checklist-popup-header' justifyContent='space-between'>
        <TrialChecklistFullPageButton
          size='xSmall'
          variant='primary'
          data-test='toggle-trial-tasks-button'
          visible={!popupMode}
          onClick={() => setShowItems((prevState) => !prevState)}
          mode='outline'
        >
          <Typography variant='bodySemiboldM'>
            <VisibilityIcon /> {t(trialsTaskText)}
          </Typography>
        </TrialChecklistFullPageButton>
        <Box>
          <TrialChecklistProgress total={totalTask} completed={completedTask} />
        </Box>
      </Flex>
      <TrialChecklistItems data-test='trial-checklist-items' showItems={showItems}>
        {onboardingData?.steps.map((item) => (
          <TrialChecklistItem key={item.stepType} {...item} />
        ))}
      </TrialChecklistItems>
      <TrialUpgradeLinkButton
        variant='primary'
        mode='link'
        data-test='trial-checklist-plan-link'
        block
        as='a'
        href={onboardingData?.ctaButtonUrl || `${ACCOUNT_ROUTE}/${ACCOUNT_TABS.YOUR_PLAN}`}
      >
        <Icon component={StarFilled} color='warning-500' mr='2' />
        <Typography color='text-contrast-light' variant='bodySemiboldM'>
          {onboardingData?.ctaButtonTitle || t('user_profile.task_checklist.upgrade')}
        </Typography>
      </TrialUpgradeLinkButton>
      <TrialWorkspaceLinkButton
        variant='secondary'
        mode='link'
        data-test='trial-workspace-link'
        block
        as='a'
        href={phoneUrl}
      >
        <Typography color='text-interactive-secondary' variant='bodySemiboldM'>
          {t('user_profile.task_checklist.launch_agent_workspace')}
        </Typography>
      </TrialWorkspaceLinkButton>
    </TrialChecklistContainer>
  );
}
