import React from 'react';

/**
 * Lock Icon component
 */
export function LockIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 20V12C4 10.8954 4.89543 10 6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10C19.1046 10 20 10.8954 20 12V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20ZM14 7.5C14 6.39543 13.1046 5.5 12 5.5C10.8954 5.5 10 6.39543 10 7.5V10H14V7.5ZM10 15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15C14 15.7403 13.5978 16.3866 13 16.7324V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V16.7324C10.4022 16.3866 10 15.7403 10 15Z'
        fill='#FFC61A'
      />
    </svg>
  );
}

/**
 * In Progress Icon component
 */
export function InProgressIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5L6 6.4C6 9.15627 8.00001 12 11 12C8.49 12 6.68005 13.8375 6.15573 17C6.05336 17.6174 6 18.2854 6 19C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19C18 18.3284 17.924 17.6527 17.7794 17C17.1823 14.304 15.4152 12 13 12C16 12 18 9.9 18 6.4V5C18.5523 5 19 4.55228 19 4C19 3.44772 18.5523 3 18 3H6ZM8.1898 17C8.36958 16.1404 8.66088 15.4916 8.99246 15.0357C9.50062 14.337 10.1559 14 11 14L13 14C13.623 14 14.3229 14.3554 14.9582 15.3134C15.2762 15.7928 15.5362 16.3709 15.7169 17L8.1898 17ZM8 5H16V7H8V5Z'
        fill='#FFC61A'
      />
    </svg>
  );
}

/**
 * Close Icon component
 */
export function CloseIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.03935 3.97869C4.87353 3.81287 4.67223 3.73945 4.47224 3.75122C4.27839 3.76262 4.10474 3.85263 3.97869 3.97869C3.85263 4.10474 3.76262 4.27839 3.75122 4.47224C3.73945 4.67223 3.81287 4.87353 3.97869 5.03935L10.9484 12.009L3.97869 18.9787C3.81287 19.1445 3.73945 19.3458 3.75122 19.5458C3.76262 19.7396 3.85263 19.9133 3.97869 20.0393C4.10474 20.1654 4.27839 20.2554 4.47224 20.2668C4.67223 20.2786 4.87353 20.2052 5.03935 20.0393L12.009 13.0697L18.9787 20.0393C19.1445 20.2052 19.3458 20.2786 19.5458 20.2668C19.7396 20.2554 19.9133 20.1654 20.0393 20.0393C20.1654 19.9133 20.2554 19.7396 20.2668 19.5458C20.2786 19.3458 20.2052 19.1445 20.0393 18.9787L13.0697 12.009L20.0393 5.03935C20.2052 4.87353 20.2786 4.67223 20.2668 4.47224C20.2554 4.27839 20.1654 4.10474 20.0393 3.97869C19.9133 3.85263 19.7396 3.76262 19.5458 3.75122C19.3458 3.73945 19.1445 3.81287 18.9787 3.97869L12.009 10.9484L5.03935 3.97869Z'
        fill='white'
      />
    </svg>
  );
}

/**
 * Warning Icon component
 */
export function WarningIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning'>
        <path
          id='Icon'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.46979 20.0005H19.532C21.0727 20.0005 22.0348 18.3317 21.2628 16.9984L13.7317 3.99012C12.9614 2.65955 11.0404 2.65954 10.27 3.99011L2.73894 16.9984C1.96701 18.3317 2.92912 20.0005 4.46979 20.0005ZM13.0428 7.20046H10.9428L11.0128 13.9625H12.9868L13.0428 7.20046ZM10.7188 15.8805C10.7188 16.5805 11.2788 17.1405 11.9928 17.1405C12.7208 17.1405 13.2808 16.5805 13.2808 15.8805C13.2808 15.1805 12.7208 14.6345 11.9928 14.6345C11.2788 14.6345 10.7188 15.1805 10.7188 15.8805Z'
          fill='#FFC61A'
        />
      </g>
    </svg>
  );
}

// Add other icons similarly...
