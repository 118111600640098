import { UseNumberSupervisionReturnType } from './useNumberSupervision.decl';

import { useGraphQuery } from '@dashboard/library';
import {
  NumberSupervisionQuery,
  NumberSupervisionQueryVariables,
} from '@generated/NumberSupervisionQuery';
import { NUMBER_SUPERVISION_QUERY } from '@graphql/queries/NumberSupervisionQuery';
import { useParams } from 'react-router';

/**
 * Hook to query the supervisors list of a specific number.
 * @param id - Number ID.
 * @returns  Object containing the number's supervisors, the loading state and the error state.
 */
export function useNumberSupervision(id?: string): UseNumberSupervisionReturnType {
  const { numberId } = useParams();

  const { data, loading, error } = useGraphQuery<
    NumberSupervisionQuery,
    NumberSupervisionQueryVariables
  >(NUMBER_SUPERVISION_QUERY, { variables: { id: id || numberId } });

  return { supervisors: data?.numberSupervision.supervisions || [], loading, error };
}
