import { IntegrationFlowCreateBridge } from './Bridge/IntegrationFlowCreateBridge';
import { IntegrationFlowCreateTray } from './Tray/IntegrationFlowCreateTray';
import { IntegrationFlowCreateWorkato } from './Workato/IntegrationFlowCreateWorkato';

import { ACPusherProvider } from '@components/PusherProvider/PusherProvider';
import { APPLICATIONS_SOURCES, APPLICATIONS_TYPES } from '@constants/integrations.constants';
import { Loading, NotFoundError, UnknownError } from '@dashboard/library';
import { ApplicationsListQuery_getApplications as HubApplication } from '@generated/ApplicationsListQuery';
import { useIntegrations } from '@hooks/useIntegrations';
import { useQueryParams } from '@hooks/useQueryParams';
import camelcaseKeys from 'camelcase-keys';
import { useParams } from 'react-router-dom';

interface UrlParams {
  accessToken?: string;
  apiDomain?: string;
  clientId?: string;
  dashboardUrl?: string;
  expiresIn?: string;
  integration?: string;
  numberIds?: string;
  refreshToken?: string;
  scope?: string;
  site?: string;
  state?: string;
  tokenType?: string;
  userToken?: string;
  solutionInstanceId?: string;
}

export function IntegrationFlowCreatePage() {
  const { applicationName, applicationType } = useParams();
  const { searchParams } = useQueryParams();
  const params: UrlParams = camelcaseKeys(Object.fromEntries(searchParams));
  const { isLoading: applicationsLoading, getApplicationByName } = useIntegrations();

  if (applicationsLoading) {
    return <Loading data-test='loading' />;
  }

  const application = getApplicationByName(applicationName);

  if ((application as HubApplication)?.source === APPLICATIONS_SOURCES.TRAY) {
    return (
      <ACPusherProvider>
        <IntegrationFlowCreateTray {...params} />
      </ACPusherProvider>
    );
  }

  if ((application as HubApplication)?.source === APPLICATIONS_SOURCES.WORKATO) {
    return <IntegrationFlowCreateWorkato {...params} />;
  }

  if (!params.accessToken) {
    throw new UnknownError('An acesstoken was not provided', params as Record<string, string>);
  }

  if (applicationType === APPLICATIONS_TYPES.BRIDGE) {
    return <IntegrationFlowCreateBridge {...params} />;
  }

  throw new NotFoundError('Integration type not found', applicationType);
}
