import { useGraphQuery } from '@dashboard/library';
import { CheckNumberLimitQuery } from '@generated/CheckNumberLimitQuery';
import { CHECK_NUMBER_LIMIT_QUERY } from '@graphql/queries/CheckNumberLimitQuery';

/**
 * Custom hook to query data from number limitation
 * @returns  Object containing isNumbersLimitReached and the loading state.
 */
export const useNumberLimitation = () => {
  const { data: checkNumberLimitData, loading } = useGraphQuery<CheckNumberLimitQuery>(
    CHECK_NUMBER_LIMIT_QUERY,
    {
      throwError: false,
    }
  );

  const isNumbersLimitReached = checkNumberLimitData?.checkNumberLimit?.isNumbersLimitReached;

  return { isNumbersLimitReached, loading };
};
