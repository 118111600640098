import { NumberDetailConnectedIntegrationsSection } from './NumberDetailConnectedIntegrationsSection/NumberDetailConnectedIntegrationsSection';
import { NumberDetailWhatsAppIntegrationSection } from './NumberDetailWhatsAppIntegrationSection/NumberDetailWhatsAppIntegrationSection';

import { Spacer } from '@aircall/tractor-v2';
import { getWhatsappRegistrationStatus_getWhatsappRegistrationStatus } from '@generated/getWhatsappRegistrationStatus';

interface NumberDetailIntegrationsPageProps {
  readonly whatsappStatusInfo:
    | getWhatsappRegistrationStatus_getWhatsappRegistrationStatus
    | undefined;
  readonly setWhatsappStatusInfo: React.Dispatch<
    React.SetStateAction<getWhatsappRegistrationStatus_getWhatsappRegistrationStatus | undefined>
  >;
  readonly refreshWhatsappStatus: () => Promise<void>;
  readonly numberUUID: string | null | undefined;
  readonly provider: string | null | undefined;
  readonly capabilities: (string | null)[] | null | undefined;
}

export function NumberDetailIntegrationsPage({
  whatsappStatusInfo,
  setWhatsappStatusInfo,
  refreshWhatsappStatus,
  numberUUID,
  provider,
  capabilities,
}: Readonly<NumberDetailIntegrationsPageProps>) {
  return (
    <Spacer fluid direction='vertical' space='m'>
      <NumberDetailWhatsAppIntegrationSection
        whatsappStatusInfo={whatsappStatusInfo}
        setWhatsappStatusInfo={setWhatsappStatusInfo}
        refreshWhatsappStatus={refreshWhatsappStatus}
        numberUUID={numberUUID}
        provider={provider}
        capabilities={capabilities}
      />
      <NumberDetailConnectedIntegrationsSection />
    </Spacer>
  );
}
