import { useContext, useRef, useState } from 'react';

import { IntegrationFlowSelectNumbers } from '../../../components/IntegrationFlow/IntegrationFlowSelectNumbers/IntegrationFlowSelectNumbers';

import {
  EnvironmentFormValues,
  IntegrationFlowSelectEnvironment,
} from './IntegrationFlowSelectEnvironment';

import {
  AIRCALL_BRIDGE_API_URL,
  AIRCALL_DASHBOARD_URL,
  PIPEDRIVE_CLIENT_ID,
} from '@constants/environment.constants';
import {
  APPLICATIONS_NAMES,
  SALESFORCE_ENVS,
  SALESFORCE_ENV_URLS,
  SELECT_ALL_NUMBERS_PARAM_VALUE,
} from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { SearchLinesQuery_searchAuthorizedLines_items as Line } from '@generated/SearchLinesQuery';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { AuthenticationStateContext } from '@state/app/authentication/AuthenticationProvider';
import { useParams } from 'react-router-dom';

export function getIntegrationAuthLink(
  applicationName: APPLICATIONS_NAMES,
  linesIdsParam: string,
  token: string,
  environment?: string
): string | null {
  let url = '';
  switch (applicationName) {
    case APPLICATIONS_NAMES.INTERCOM: {
      const redirectUri = `${AIRCALL_BRIDGE_API_URL}/v1/intercom/oauth2/callback`;
      url = `${AIRCALL_BRIDGE_API_URL}/v1/intercom/oauth2/request_access?number_ids=${linesIdsParam}&redirect_uri=${redirectUri}`;
      break;
    }
    case APPLICATIONS_NAMES.PIPEDRIVE: {
      url = `${AIRCALL_BRIDGE_API_URL}/v1/pipedrive/oauth2/request_access?number_ids=${linesIdsParam}&user_token=${token}&client_id=${PIPEDRIVE_CLIENT_ID}`;
      break;
    }
    case APPLICATIONS_NAMES.SALESFORCE: {
      const site = SALESFORCE_ENV_URLS[environment! as SALESFORCE_ENVS];
      url = `${AIRCALL_BRIDGE_API_URL}/v1/salesforce/oauth2/request_access?site=${site}&number_ids=${linesIdsParam}`;
      break;
    }
    default:
      return null;
  }

  return `${url}&dashboard_url=${AIRCALL_DASHBOARD_URL}`;
}

interface IntegrationFlowInstallBridgeProps {
  application: ApplicationsListQuery_getApplications;
}

export function IntegrationFlowInstallBridge({ application }: IntegrationFlowInstallBridgeProps) {
  const { applicationName, applicationType, applicationId } = useParams();
  const {
    authState: { token },
  } = useContext(AuthenticationStateContext);
  const navigate = useNavigateWithParamsReplace();
  const [step, setStep] = useState<number>(1);
  const linesIdsParamRef = useRef<string>('');

  function redirectToAuth(environment?: string) {
    if (applicationName === APPLICATIONS_NAMES.ZENDESK) {
      return navigate(
        `${INTEGRATION_FULL_ROUTES.FLOW_CREATE}?number_ids=${linesIdsParamRef.current}`,
        {
          applicationType,
          applicationName,
          applicationId,
        }
      );
    }

    const authUrl = getIntegrationAuthLink(
      applicationName as APPLICATIONS_NAMES,
      linesIdsParamRef.current,
      token!,
      environment
    )!;

    if (!authUrl) {
      return undefined;
    }

    window.location.href = authUrl;
    return undefined;
  }

  function handleSubmitNumbers(lines: Line[] | typeof SELECT_ALL_NUMBERS_PARAM_VALUE) {
    linesIdsParamRef.current =
      lines === SELECT_ALL_NUMBERS_PARAM_VALUE
        ? SELECT_ALL_NUMBERS_PARAM_VALUE
        : lines.map((line) => line.ID).join();

    if (applicationName === APPLICATIONS_NAMES.SALESFORCE) {
      return setStep(2);
    }

    return redirectToAuth();
  }

  function handleSubmitEnvironment(values: EnvironmentFormValues) {
    const { environment } = values;
    return redirectToAuth(environment);
  }

  if (step === 1) {
    return (
      <IntegrationFlowSelectNumbers
        applicationName={applicationName as APPLICATIONS_NAMES}
        onSubmit={handleSubmitNumbers}
        onSkip={() => handleSubmitNumbers([])}
      />
    );
  }

  return (
    <IntegrationFlowSelectEnvironment
      application={application}
      onSubmit={handleSubmitEnvironment}
      onCancel={() => setStep(1)}
    />
  );
}
