import { Typography } from '@aircall/tractor-v2';
import { trimWithEllipsis } from '@dashboard/library';
import { SearchLinesQuery_searchAuthorizedLines_items } from '@generated/SearchLinesQuery';
import { isNumberActive } from '@helpers/numbers.helpers';

export function ColumnFieldName({
  activationState,
  name,
}: SearchLinesQuery_searchAuthorizedLines_items) {
  const isActive = isNumberActive(activationState);

  return (
    <Typography
      data-test='number-table-name-column'
      variant='bodyRegularS'
      color={isActive ? 'text-base' : 'text-disabled'}
    >
      {trimWithEllipsis(name, 50)}
    </Typography>
  );
}
