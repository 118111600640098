/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-redeclare */

import {
  AUTHORIZATION_ERROR_CODES,
  GRAPHQL_ERROR_NON_EXISTENT_RESOURCE,
  NOT_FOUND_ERROR_CODES,
  VALIDATION_ERROR_CODES,
  BACKEND_VALIDATION_ERRORS_TRANSLATION_KEYS,
  BACKEND_ERRORS_TRANSLATION_KEYS,
  GRAPHQL_ERROR_INTERNAL_API_NOT_FOUND,
  LIMITATION_ERROR_CODES,
  GATEWAY_TIMEOUT_ERROR_CODES,
  FRONTEND_VALIDATION_ERRORS_MAPPER_OF_DESCRIPTIVE_ERROR_TO_KEY_NAME,
  GRAPHQL_ERROR_INVALID_DATA,
} from '../constants/errors.constants';

import { ApolloError, ServerError } from '@apollo/client';
import { isObject } from '@dashboard/library';
import camelcase from 'camelcase';
import { FORM_ERROR } from 'final-form';
import { GraphQLError } from 'graphql';

export interface GenericFormError {
  [FORM_ERROR]: string;
}

export interface GetValidationErrorsTranslationsOptions {
  keyMapper?: Record<string, string>;
  valueMapper?: Record<string, string>;
}
export interface HandleFormErrorsOptions extends GetValidationErrorsTranslationsOptions {
  validationErrorAsFormError?: boolean;
  /* Handles edge cases where validation errors should never happen */
  skipValidationError?: boolean;
}

export enum ERROR_TYPES {
  INVALID_DATA = 'INVALID_DATA',
  VALIDATION = 'VALIDATION',
  NETWORK = 'NETWORK',
  UNKNOWN = 'UNKNOWN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
}

export interface ClientError {
  type: ERROR_TYPES;
  message: string;
}

export interface UnknownError extends ClientError {
  type: ERROR_TYPES.UNKNOWN;
  errors: GraphQLError[];
}

export interface ValidationError extends ClientError {
  type: ERROR_TYPES.VALIDATION;
  errors: Record<string, string>;
}

export interface INetworkError extends ClientError {
  type: ERROR_TYPES.NETWORK;
}

export interface InvalidDataError extends ClientError {
  type: ERROR_TYPES.INVALID_DATA;
}

export interface UnauthorizedError extends ClientError {
  type: ERROR_TYPES.UNAUTHORIZED;
}

export interface GatewayTimeoutError extends ClientError {
  type: ERROR_TYPES.GATEWAY_TIMEOUT;
}

export interface INotFoundError extends ClientError {
  type: ERROR_TYPES.NOT_FOUND;
}

export interface TooManyRequestError extends ClientError {
  type: ERROR_TYPES.TOO_MANY_REQUESTS;
}

export class HttpError<T = Record<string, unknown>> extends Error {
  constructor(public response: Response, public body: T) {
    /**
     * @see https://github.com/gotwarlost/istanbul/issues/690
     */
    super(response.statusText) /* istanbul ignore next */;
    /**
     * @see https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
     */
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

export class NetworkError extends Error {
  constructor(message?: string) {
    /**
     * @see https://github.com/gotwarlost/istanbul/issues/690
     */
    super(message) /* istanbul ignore next */;
    /**
     * @see https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
     */
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

/**
 * Check if the error is a network error.
 * @param error - apollo error
 * @returns a predicate
 */
export const isNetworkError = (error: ApolloError): boolean => {
  if (!(error instanceof ApolloError && error.networkError)) {
    return false;
  }
  return !(error.networkError as ServerError).statusCode;
};

/**
 * Checks if given error has an error code.
 * @param codes - error codes
 * @returns a function that receives an apollo error and check if it has an error code
 */
export const hasErrorCode =
  (codes: number[]) =>
  (error: ApolloError): boolean => {
    if (!(error instanceof ApolloError && error.networkError)) {
      return false;
    }

    return codes.includes((error.networkError as ServerError).statusCode);
  };

/**
 * Checks if error is a GraphQL Error.
 * @param error - apollo error
 * @returns a predicate
 */
export const isGraphQLError = (error: ApolloError): boolean => {
  if (!(error instanceof ApolloError && error.graphQLErrors?.length)) {
    return false;
  }

  return true;
};

/**
 * Checks if error holds a given message in its own message.
 * @param message - message to check
 * @returns a function that receives an apollo error and check if it holds a given message
 */
export const graphqlErrorHasErrorMessage =
  (message: string) =>
  (error: ApolloError): boolean => {
    if (!isGraphQLError(error)) {
      return false;
    }

    if (error.graphQLErrors[0]?.message) {
      return error.graphQLErrors[0].message.includes(message);
    }

    return (error.graphQLErrors as unknown as string[]).includes(message);
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatValidationValue = (value: any): string => {
  if (Array.isArray(value) && value.length) {
    return value[0];
  }

  if (typeof value === 'string') {
    return value;
  }

  if (isObject(value)) {
    return Object.values(value)[0] as string;
  }

  return '';
};

/**
 * Formats validation errors in a given format, with keys in camelCase and with extracted values.
 * @param validationErrors - validation errors
 * @returns formatted validation errors
 */
export const formatValidationErrors = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationErrors: Record<string, any>
): Record<string, string> => {
  let objectToBeFormatted: Record<string, unknown> = validationErrors;

  if (validationErrors.errors) {
    objectToBeFormatted = validationErrors.errors as Record<string, unknown>;
  } else if (typeof validationErrors.error === 'string') {
    objectToBeFormatted = validationErrors;
  } else if (validationErrors.error) {
    objectToBeFormatted = validationErrors.error as Record<string, unknown>;
  }

  return Object.keys(objectToBeFormatted).reduce((acc, key) => {
    const value = objectToBeFormatted[key];

    return { ...acc, [camelcase(key)]: formatValidationValue(value) };
  }, {});
};

export const getErrorKey = (errorMessage: string): string | null => {
  const errorKey = (
    FRONTEND_VALIDATION_ERRORS_MAPPER_OF_DESCRIPTIVE_ERROR_TO_KEY_NAME as Record<string, string>
  )[errorMessage];
  if (errorKey) {
    return errorKey;
  }

  return null;
};

export class ClientException extends Error {
  trace?: Error;

  constructor(public error: ClientError, innerError?: Error) {
    super(error.message);
    this.trace = innerError;
    Object.setPrototypeOf(this, ClientException.prototype);
  }
}

/**
 * Returns the apprioprate client error based on the error type.
 * @param error - error to be handled
 * @returns dedicated client error
 */
export const handleApolloError = (error: ApolloError): ClientError => {
  // Very dodgy edge case since the api returns 422 for not found resources so apollo
  // does not recognize it as a network error and makes the networkError object null
  if (graphqlErrorHasErrorMessage(GRAPHQL_ERROR_NON_EXISTENT_RESOURCE)(error)) {
    return {
      type: ERROR_TYPES.NOT_FOUND,
      message: GRAPHQL_ERROR_NON_EXISTENT_RESOURCE,
    } as INotFoundError;
  }

  // The internal API always returns the same message when the resource is not found
  // We need to handle it so we can flag it as NOT_FOUND rather than UNKNOWN
  if (graphqlErrorHasErrorMessage(GRAPHQL_ERROR_INTERNAL_API_NOT_FOUND)(error)) {
    return {
      type: ERROR_TYPES.NOT_FOUND,
      message: GRAPHQL_ERROR_INTERNAL_API_NOT_FOUND,
      errors: error.graphQLErrors,
    } as INotFoundError;
  }

  if (graphqlErrorHasErrorMessage(GRAPHQL_ERROR_INVALID_DATA)(error)) {
    return {
      type: ERROR_TYPES.INVALID_DATA,
      message: GRAPHQL_ERROR_INVALID_DATA,
      errors: error.graphQLErrors,
    } as InvalidDataError;
  }

  if (isGraphQLError(error)) {
    const errors = error.graphQLErrors;
    // errors can not be empty - already tested in isGraphQLError
    const newErr = errors[0] as unknown as { status: number };
    // check for 404 status code on the error
    if (newErr.status === 404) {
      return {
        type: ERROR_TYPES.NOT_FOUND,
        message: GRAPHQL_ERROR_INTERNAL_API_NOT_FOUND,
        errors,
      } as INotFoundError;
    }

    if (errors.length && errors[0].message && !!getErrorKey(errors[0].message)) {
      return {
        type: ERROR_TYPES.VALIDATION,
        errors: formatValidationErrors(
          errors.reduce((acc, item) => ({ ...acc, [getErrorKey(item.message)!]: item.message }), {})
        ),
        message: errors[0].message,
      } as ValidationError;
    }
    // otherwise it is an unknown error
    return {
      type: ERROR_TYPES.UNKNOWN,
      message: error.message,
      errors,
    } as unknown as UnknownError;
  }

  if (hasErrorCode(GATEWAY_TIMEOUT_ERROR_CODES)(error)) {
    return {
      type: ERROR_TYPES.GATEWAY_TIMEOUT,
      message: error.message,
    } as GatewayTimeoutError;
  }

  if (isNetworkError(error)) {
    return {
      type: ERROR_TYPES.NETWORK,
      message: error.message,
    } as INetworkError;
  }

  if (hasErrorCode(AUTHORIZATION_ERROR_CODES)(error)) {
    return {
      type: ERROR_TYPES.UNAUTHORIZED,
      message: error.message,
    } as UnauthorizedError;
  }

  if (hasErrorCode(NOT_FOUND_ERROR_CODES)(error)) {
    return {
      type: ERROR_TYPES.NOT_FOUND,
      message: error.message,
    } as INotFoundError;
  }

  if (hasErrorCode(LIMITATION_ERROR_CODES)(error)) {
    return {
      type: ERROR_TYPES.TOO_MANY_REQUESTS,
      message: error.message,
    } as TooManyRequestError;
  }

  if (hasErrorCode(VALIDATION_ERROR_CODES)(error)) {
    const serverError = error.networkError as ServerError;
    return {
      type: ERROR_TYPES.VALIDATION,
      message: error.message,
      errors: formatValidationErrors(serverError.result),
    } as ValidationError;
  }

  return {
    type: ERROR_TYPES.UNKNOWN,
    message: error.message,
  } as UnknownError;
};

/**
 * Handles the translation strings of `NETWORK_ERROR` and `UNKNOWN_ERROR`
 * @param err - The error object of type `ClientException` that is thrown by the useGraphMutation, useGraphQuery
 * @returns The translation strings for network error or unknown error
 */
export const getExternalErrorTranslationStrings = (err: ClientError): string => {
  if (err.type === ERROR_TYPES.GATEWAY_TIMEOUT) {
    return BACKEND_ERRORS_TRANSLATION_KEYS.GATEWAY_TIMEOUT_ERROR;
  }

  if (err.type === ERROR_TYPES.NETWORK) {
    return BACKEND_ERRORS_TRANSLATION_KEYS.NETWORK_ERROR;
  }

  if (err.type === ERROR_TYPES.TOO_MANY_REQUESTS) {
    return BACKEND_ERRORS_TRANSLATION_KEYS.TOO_MANY_REQUESTS;
  }

  if (err.type === ERROR_TYPES.INVALID_DATA) {
    return BACKEND_ERRORS_TRANSLATION_KEYS.INVALID_DATA_ERROR;
  }

  return BACKEND_ERRORS_TRANSLATION_KEYS.UNKNOWN_ERROR;
};

/**
 * Returns a map of validation errors with the translation strings
 * @param err - The error object of type `ClientException` that is thrown by the useGraphMutation, useGraphQuery
 * @param options - The options object containing a keyMapper that can be used to map through objects that are not formatted as expected.
 * @returns A map such as `{name: "generic_errors.backend_validation.is_invalid"}` or null
 */
export const getValidationErrorsTranslations = (
  err: ClientError,
  options?: GetValidationErrorsTranslationsOptions
): Record<string, string> | null => {
  const validationErrors = (err as ValidationError).errors;

  if (!validationErrors || !Object.keys(validationErrors).length) {
    return null;
  }

  return Object.keys(validationErrors).reduce((acc, item) => {
    if (options?.keyMapper?.[item] === '') {
      return acc;
    }

    const key = options?.keyMapper?.[item] || item;

    const value =
      BACKEND_VALIDATION_ERRORS_TRANSLATION_KEYS[validationErrors[item]] ||
      options?.valueMapper?.[key] ||
      validationErrors[item];

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

/**
 * Handles form errors providing the correct translation strings to react-final-form
 * @param err - The error object of type `ClientException` that is thrown by the useGraphMutation, useGraphQuery
 * @param options - The options object containing a keyMapper that can be used to map through objects that are not formatted as expected.
 * @returns The translation strings for `NetworkError`, `UnknownError` and `ValidationError`
 */
export const handleFormErrors = (
  err: ClientException,
  options: HandleFormErrorsOptions = {}
): Record<string, string> | GenericFormError => {
  const error = err;
  const { skipValidationError, validationErrorAsFormError } = options;
  if (error.error.type === ERROR_TYPES.VALIDATION) {
    const validationErrors = getValidationErrorsTranslations(err.error, options);

    if (!validationErrors || skipValidationError) {
      return { [FORM_ERROR]: getExternalErrorTranslationStrings(err.error) };
    }

    if (validationErrorAsFormError) {
      return { [FORM_ERROR]: Object.values(validationErrors)[0] };
    }

    return validationErrors;
  }

  return { [FORM_ERROR]: getExternalErrorTranslationStrings(err.error) };
};
