import { ErrorInfo } from 'react';

import { datadogRum } from '@datadog/browser-rum';

// Errors that we don't care about and don't want to send to Datadog.
const NON_RELEVANT_ERROR_MESSAGES = ['signal is aborted without reason'] as const;

export function addReactError(name: string, error: Error, info: ErrorInfo, context?: object): void {
  const datadogRumError = new Error(error.message);
  datadogRumError.name = name;
  datadogRumError.stack = info.componentStack;
  datadogRumError.cause = error;
  datadogRum.addError(datadogRumError, context);
}

export function isNonRelevantErrorMessage(errorMessage: string): boolean {
  return NON_RELEVANT_ERROR_MESSAGES.some((message) => errorMessage.includes(message));
}
