import styled from 'styled-components';

import { FeebackButtonProps } from './FeedbackButton.decl';

import { Button, getSpace } from '@aircall/tractor-v2';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  position: fixed;
  right: ${getSpace('xxs')};
  bottom: ${getSpace('xxs')};
  z-index: 90;
`;

export function FeedbackButton({
  event,
  text,
  'data-test': dataTest = 'feedback-button',
}: FeebackButtonProps) {
  const { t } = useTranslation();
  const { track } = useTracker();

  const handleCLick = () => {
    track({ event });
  };

  return (
    <StyledButton size='xSmall' variant='secondary' onClick={handleCLick} data-test={dataTest}>
      {text ?? t('generic.feedback_button')}
    </StyledButton>
  );
}
