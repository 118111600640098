import { NUMBER_DETAIL_QUERY } from '../../graphql/queries/NumberDetailQuery';

import { useGraphQuery } from '@dashboard/library';
import { NumberDetailQuery, NumberDetailQueryVariables } from '@generated/NumberDetailQuery';
import { useParams } from 'react-router';

import { UseNumberDetailReturnType } from '.';

/**
 * Hook to query the data of a specific number.
 * @param id - Number ID.
 * @returns  Object containing the number data, the loading state and the error state.
 */
export function useNumberDetail(id?: string): UseNumberDetailReturnType {
  const { numberId } = useParams();

  const { data, loading } = useGraphQuery<NumberDetailQuery, NumberDetailQueryVariables>(
    NUMBER_DETAIL_QUERY,
    { variables: { id: id || numberId } }
  );

  return { number: data?.numberDetail, loading };
}
