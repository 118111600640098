import { Flex, IconButton } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const TrialChecklistItemActionMenuBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 0;
`;

export const TrialChecklistItemMenuOverlayBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  background-color: inherit;
`;

export const ButtonIcon = styled(IconButton)`
  :hover {
    transform: scale(1.2);
  }

  :focus-visible {
    outline: auto;
  }
`;
