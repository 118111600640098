import { useCallback, useState } from 'react';

import { PopupSelectNumbers } from './PopupSelectNumbers';

import { Avatar, AvatarImg, Spacer, Typography } from '@aircall/tractor-v2';
import { Popup } from '@components/Popup';
import { APPLICATIONS_SCOPES } from '@constants/generic.constants';
import { SELECT_ALL_NUMBERS_PARAM_VALUE } from '@constants/integrations.constants';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { GetAuthorizedApplicationQuery_getAuthorizedApplication } from '@generated/GetAuthorizedApplicationQuery';
import { SearchLinesQuery_searchAuthorizedLines_items as Line } from '@generated/SearchLinesQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

enum POPUP_AUTHORIZE_STEPS {
  AUTHORIZE = 'AUTHORIZE',
  SELECT_NUMBERS = 'SELECT_NUMBERS',
}

interface PopupAuthorizationProps {
  displayNumbersStep: boolean;
  application:
    | ApplicationsListQuery_getApplications
    | GetAuthorizedApplicationQuery_getAuthorizedApplication;
  onSubmit: (lines: Line[] | typeof SELECT_ALL_NUMBERS_PARAM_VALUE) => Promise<void>;
  onCancel: () => void;
}

export function PopupAuthorization({
  displayNumbersStep,
  application,
  onSubmit,
  onCancel,
}: PopupAuthorizationProps) {
  const { t } = useTranslation();
  const { searchParams: queryParams } = useQueryParams();
  const scope = queryParams.get('scope') as APPLICATIONS_SCOPES | undefined;
  const dataTest = 'popup-authorization';
  const { logo, name } = application;
  const [step, setStep] = useState(POPUP_AUTHORIZE_STEPS.AUTHORIZE);

  const handleAuthorize = useCallback(() => {
    if (displayNumbersStep) {
      return setStep(POPUP_AUTHORIZE_STEPS.SELECT_NUMBERS);
    }
    return onSubmit([]);
  }, [displayNumbersStep, onSubmit]);

  if (step === POPUP_AUTHORIZE_STEPS.SELECT_NUMBERS) {
    return (
      <PopupSelectNumbers
        allowAll={false}
        application={application}
        onSubmit={onSubmit}
        onSkip={() => onSubmit([])}
      />
    );
  }

  return (
    <Popup
      submitText={t('integration_flow.authorization.submit')}
      onSubmit={handleAuthorize}
      cancelText={t('integration_flow.authorization.cancel')}
      onCancel={onCancel}
      data-test={dataTest}
    >
      <Spacer direction='vertical' alignItems='center' space='m'>
        <Spacer space='xl'>
          <Avatar size='xLarge'>
            <AvatarImg src='https://cdn.aircall.io/aircall-logo-squared.png' />
          </Avatar>

          {logo && (
            <Avatar data-test={`${dataTest}-app-logo`} size='xLarge'>
              <AvatarImg src={logo} />
            </Avatar>
          )}
        </Spacer>
        <Spacer direction='vertical' alignItems='center' space='xxs'>
          <Typography variant='bodySemiboldM'>
            {t('integration_flow.authorization.paper_text', { applicationName: name })}
          </Typography>
          {scope === APPLICATIONS_SCOPES.PUBLIC_API && (
            <Typography variant='bodyMediumS'>
              {t('integration_flow.authorization.public_api_permission')}
            </Typography>
          )}
        </Spacer>
      </Spacer>
    </Popup>
  );
}
