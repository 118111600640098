import React from 'react';

import { ProofOfIdRedirectedState } from './NumberDetail.decl';
import { NumberDetailTabContainer } from './NumberDetailTabContainer/NumberDetailTab.container';

import { DataLayout } from '@components/DataLayout/DataLayout';
import { Placeholder } from '@dashboard/extension';
import { useGraphQuery } from '@dashboard/library';
import { NumberDetailQuery, NumberDetailQueryVariables } from '@generated/NumberDetailQuery';
import { SearchLineByIDQuery, SearchLineByIDQueryVariables } from '@generated/SearchLineByIDQuery';
import { NUMBER_DETAIL_QUERY } from '@graphql/queries/NumberDetailQuery';
import { SEARCH_LINE_BY_ID_QUERY } from '@graphql/queries/SearchLineByIDQuery';
import { useAudioLibrary } from '@hooks/useAudioLibrary/useAudioLibrary';
import { useParams, useLocation } from 'react-router-dom';

export const NumberDetailPage = React.memo(() => {
  const { numberId } = useParams();
  const { loading: audioLibLoading } = useAudioLibrary();
  const { data, loading } = useGraphQuery<SearchLineByIDQuery, SearchLineByIDQueryVariables>(
    SEARCH_LINE_BY_ID_QUERY,
    { variables: { ID: parseInt(numberId) } }
  );
  const { state } = useLocation() as ProofOfIdRedirectedState;
  const shouldPollQuery = !!state?.proofOfIdSubmitted;

  return (
    <DataLayout<NumberDetailQuery, NumberDetailQueryVariables>
      query={NUMBER_DETAIL_QUERY}
      queryOptions={{
        variables: { id: numberId },
        fetchPolicy: 'cache-and-network',
        throwError: true,
      }}
      isParentLoading={loading || audioLibLoading}
      {...(shouldPollQuery && {
        pollingInterval: 2000,
        pollingLimit: 5,
      })}
    >
      {({ data: { numberDetail } }) => (
        <>
          <NumberDetailTabContainer
            line={data?.searchAuthorizedLines?.items?.[0]}
            numberDetail={numberDetail}
          />
          <Placeholder
            name='proof_of_id_completion_feedback'
            parameters={{
              number: numberDetail.numberFormatted,
              numberName: numberDetail.name,
            }}
          />
        </>
      )}
    </DataLayout>
  );
});
