import type { SidenavItemConfig } from '@components/Sidenav/Sidenav.config';
import { PERMISSIONS_ACTIONS, ROLE_NAME } from '@constants/permissions.constants';
import {
  CONVERSATIONS_SCORECARDS_ROUTE,
  CONVERSATIONS_TRACKERS_ROUTE,
  TEAMS_ROUTE,
  USERS_ROUTE,
} from '@constants/routes.constants';
import {
  UserFullFragment_authorizedEntities,
  UserFullFragment_roles,
} from '@generated/UserFullFragment';
import { hasRole } from '@helpers/permissions.helpers';
import { FeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags.decl';
import type { usePermissions } from '@hooks/usePermissions';
import { matchPath } from 'react-router-dom';

export function checkIsLimitedSupervisor(
  userRoles: Partial<UserFullFragment_roles>[],
  authorizedEntities: Partial<UserFullFragment_authorizedEntities>[]
): boolean {
  return (
    // Empty authorizedEntities means that the supervisor has full visibility
    hasRole(userRoles, ROLE_NAME.SUPERVISOR) && authorizedEntities.length > 0
  );
}

export function isRouteRestricted(
  item: Pick<SidenavItemConfig, 'resource' | 'resources'>,
  checkAccess: ReturnType<typeof usePermissions>['checkAccess']
): boolean {
  const resources: SidenavItemConfig['resources'] = (item.resources ?? []).concat(
    item.resource ? [item.resource] : []
  );

  return resources.some((r) => !checkAccess(PERMISSIONS_ACTIONS.READ, r));
}

export function isAddonRouteRestricted(
  item: Pick<SidenavItemConfig, 'to'>,
  featuresFlags: FeaturesFlags
): boolean {
  if (
    (item.to === CONVERSATIONS_SCORECARDS_ROUTE || item.to === CONVERSATIONS_TRACKERS_ROUTE) &&
    !featuresFlags.advancedAiPackage
  ) {
    return true;
  }

  return false;
}

export function isParentRouteActive(item: SidenavItemConfig, currentPath: string): boolean {
  if (item.children) {
    return item.children.some((child) =>
      Boolean(
        matchPath(
          {
            path: child.to,
            end: false,
          },
          currentPath
        )
      )
    );
  }

  return Boolean(
    matchPath(
      {
        path: item.to?.split('/')?.[1],
        end: false,
      },
      currentPath
    )
  );
}

export function getItemLabelAndPosition(to: string): {
  label?: string;
  position?: number;
} {
  switch (to) {
    case USERS_ROUTE:
      return {
        label: 'sidenav.all_users_link',
        position: 2,
      };
    case TEAMS_ROUTE:
      return {
        label: 'sidenav.all_teams_link',
        position: 3,
      };
    default:
      return {};
  }
}
