import { useState, useMemo } from 'react';

import { UserSearchLinesReturn } from './useSearchLines.decl';

import { DEFAULT_LINES_SORT } from '@constants/generic.constants';
import { useGraphQuery } from '@dashboard/library';
import { SearchLinesQuery, SearchLinesQueryVariables } from '@generated/SearchLinesQuery';
import { SEARCH_LINES_QUERY } from '@graphql/queries/SearchLinesQuery';
import { getSearchLinesFilter } from '@helpers/search.helpers';

/**
 * Hook to search line
 * @param idsBlacklist List of number's id that should be excluded from the search result
 * @returns Object holding search result and function responsible for searching lines
 */
export function useSearchLines(idsBlacklist: string[] = []): UserSearchLinesReturn {
  const [query, setQuery] = useState('');
  const { loading, data } = useGraphQuery<SearchLinesQuery, SearchLinesQueryVariables>(
    SEARCH_LINES_QUERY,
    {
      variables: {
        filter: getSearchLinesFilter({ searchQuery: query }),
        sort: DEFAULT_LINES_SORT,
      },
      throwError: false,
    }
  );

  const lines = useMemo(
    () =>
      data?.searchAuthorizedLines?.items?.filter((item) => !idsBlacklist.includes(item.ID)) || [],
    [data?.searchAuthorizedLines?.items, idsBlacklist]
  );

  const resultsCount = data?.searchAuthorizedLines?.total
    ? data.searchAuthorizedLines.total - idsBlacklist.length
    : 0;

  return { loading, lines, setQuery, resultsCount };
}
