import { useCallback, useState } from 'react';

import { PopupAuthorization } from './Popup/PopupAuthorization';

import { IntegrationFlowAuthorization } from '@components/IntegrationFlow/IntegrationFlowAuthorization/IntegrationFlowAuthorization';
import { IntegrationFlowSelectNumbers } from '@components/IntegrationFlow/IntegrationFlowSelectNumbers/IntegrationFlowSelectNumbers';
import {
  APPLICATIONS_NAMES,
  APPLICATIONS_SOURCES,
  SELECT_ALL_NUMBERS_PARAM_VALUE,
} from '@constants/integrations.constants';
import { INTEGRATIONS_ROUTE } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import {
  AuthorizeApplicationMutation,
  AuthorizeApplicationMutationVariables,
} from '@generated/AuthorizeApplicationMutation';
import { GetAuthorizedApplicationQuery_getAuthorizedApplication } from '@generated/GetAuthorizedApplicationQuery';
import { SearchLinesQuery_searchAuthorizedLines_items as Line } from '@generated/SearchLinesQuery';
import { AUTHORIZE_APPLICATION_MUTATION } from '@graphql/mutations/AuthorizeApplicationMutation';
import { useImperativeMutation } from '@hooks/useImperativeMutation/useImperativeMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';

interface AuthorizeApplicationProps {
  application: GetAuthorizedApplicationQuery_getAuthorizedApplication;
  displayNumbersStep: boolean;
}

enum AUTHORIZE_STEPS {
  AUTHORIZE = 'AUTHORIZE',
  SELECT_NUMBERS = 'SELECT_NUMBERS',
}

export function AuthorizeApplication({
  application,
  displayNumbersStep,
}: AuthorizeApplicationProps) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(AUTHORIZE_STEPS.AUTHORIZE);
  const { track } = useTracker();
  const navigate = useNavigateWithParamsReplace();
  const authorizeApplicationMutate = useImperativeMutation<
    AuthorizeApplicationMutation,
    AuthorizeApplicationMutationVariables
  >({ mutation: AUTHORIZE_APPLICATION_MUTATION });

  const authorizeApplication = useCallback(
    async (numberIds: string[]) => {
      setLoading(true);

      const res = await authorizeApplicationMutate({
        input: {
          clientId: application.clientId,
          redirectUri: application.redirectUri,
          responseType: application.responseType,
          scope: application.scope,
          state: application.state,
          numberIds,
          v2: true,
        },
      });

      track({
        event: 'integration_installation_funnel_completed',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        payload: { application_name: application?.name },
      });

      const url = res.data?.authorizeApplication.redirectUri;
      return window.location.replace(url!);
    },
    [
      application.clientId,
      application?.name,
      application.redirectUri,
      application.responseType,
      application.scope,
      application.state,
      authorizeApplicationMutate,
      track,
    ]
  );

  const handleLines = useCallback(
    async (lines: Line[] | typeof SELECT_ALL_NUMBERS_PARAM_VALUE) => {
      const numberIds = (lines as Line[]).map((line) => line.ID);
      authorizeApplication(numberIds);
    },
    [authorizeApplication]
  );

  const handleAcceptAuthorization = useCallback(async () => {
    if (displayNumbersStep) {
      return setStep(AUTHORIZE_STEPS.SELECT_NUMBERS);
    }
    return authorizeApplication([]);
  }, [authorizeApplication, displayNumbersStep]);

  const handleCancel = useCallback(async () => {
    if (application.source === APPLICATIONS_SOURCES.TRAY) {
      return window.close();
    }
    return navigate(INTEGRATIONS_ROUTE);
  }, [application.source, navigate]);

  if (loading) {
    return <Loading data-test='loading' />;
  }

  if (application.source === APPLICATIONS_SOURCES.TRAY) {
    return (
      <PopupAuthorization
        application={application}
        displayNumbersStep={displayNumbersStep}
        onSubmit={handleLines}
        onCancel={handleCancel}
      />
    );
  }

  if (step === AUTHORIZE_STEPS.SELECT_NUMBERS) {
    return (
      <IntegrationFlowSelectNumbers
        authApplication={application}
        applicationName={application?.name.toLocaleLowerCase() as APPLICATIONS_NAMES}
        onlySpecificNumbers
        onSubmit={handleLines}
        onSkip={() => handleLines([])}
      />
    );
  }

  return (
    <IntegrationFlowAuthorization
      application={application}
      onSubmit={handleAcceptAuthorization}
      onCancel={handleCancel}
    />
  );
}
